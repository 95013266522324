@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.sign-out-modal {
  display: flex;
  flex-direction: column;

  .header {
    padding: 1rem;
    border-bottom: 1px solid colors.$surface-500;
    background-color: colors.$surface-100;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    h4 {
      @include fonts.h4;
    }
  }

  .body {
    @include fonts.body1;
    text-align: center;
    padding: 2.625rem 0.5rem;
    background-color: colors.$surface-100;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    padding: 1rem;
    background-color: colors.$surface-500;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  @include screenSizes.mobile {
    .header {
      text-align: center;
      padding: 2rem;
      border-bottom: 1px solid colors.$black;

      h4 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .body {
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 2.625rem 2rem;
    }

    .footer {
      justify-content: space-evenly;

      button {
        &.small {
          width: 8rem;
        }
      }
    }
  }

  @include screenSizes.tablet {
    .body {
      padding: 2.625rem 1rem;
    }

    .footer {
      button {
        &.small {
          margin: 0;
        }
      }
    }
  }
}