@use '../../../scss/fonts' as fonts;
@use '../../../scss/colors' as colors;
@use '../../../scss/screenSizes' as screenSizes;

.list-storage-locations {
  padding: 1rem;

  .xs-list-storage-locations {
    display: none;
  }

  .location-row {
    display: flex;
    flex-direction: row;

    .labeled-value-info {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-right: 1px solid colors.$text-400;

      label {
        @include fonts.body1;
        text-align: center;

        span {
          margin-left: 0.25rem;
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }


  @include screenSizes.tablet {
    padding: 0.75rem;

    .location-row {
      .labeled-value-info {
        padding: 0 0.25rem;

        label {
          @include fonts.body2;
        }
      }
    }
  }

  @include screenSizes.mobile {
    padding: 0.5rem;

    .desk-list-storage-locations {
      display: none;
    }

    .xs-list-storage-locations {
      display: block;

      .card {
        padding: 0rem;

        .list-storage-location {
          display: flex;
          flex-direction: column;

          .storage-location-content {
            display: flex;
            border-bottom: 1px solid colors.$text-200;
            padding: 0.25rem;
            text-align: center;

            .location-content {
              border-right: 1px solid colors.$text-200;
              padding: 0.25rem;
              flex: 1;

              &:last-child {
                border-right: none;
              }
            }

            &:last-child {
              border-bottom: none;
            }
          }

          label {
            @include fonts.caption1;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}