@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.add-account-modal {
  display: flex;
  flex-direction: column;
  padding: 2.4375rem 4.25rem 2.8125rem 4.25rem;

  .header {
    padding-bottom: 1.125rem;
    border-bottom: 1px solid colors.$black;

    h4 {
      @include fonts.h4;
    }
  }

  .input {
    padding-bottom: 1rem;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .input {
      width: 48%;
    }
  }

  .body1 {
    @include fonts.body1;
  }

  .terms-conditions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    p {
      @include fonts.body3;
      color: colors.$text-600;
    }

    b {
      @include fonts.body3-semibold;
      text-decoration: underline;
    }

    input {
      width: 1.25rem;
      height: 1.35rem;
    }
  }

  input[type='text'],
  input[type='email'] {
    $border-width: 1px;
    $padding-left: 1rem;
    $padding-right: 0.5rem;
    width: calc(100% - $padding-left - $padding-right - 2 * $border-width);
    height: 2rem;
    padding: 0.5rem $padding-right 0.5rem $padding-left;
    border-radius: 0.5rem; //8px
    border-width: $border-width;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.75rem;
  }

  @include screenSizes.mobile {
    padding: 2rem;

    .header {
      text-align: center;
    }

    .input {
      padding-bottom: 1rem;
    }

    .input-row {
      flex-direction: column;

      .input {
        width: 100%;
      }
    }

    .body1 {
      @include fonts.caption2;
      font-size: 0.75rem;
    }

    .terms-conditions {
      p {
        @include fonts.caption2;
        font-size: 0.75rem;
      }

      b {
        @include fonts.caption2-semibold;
        font-size: 0.75rem;
      }
    }

    input[type='text'],
    input[type='phone'],
    input[type='email'] {
      $border-width: 1px;
      $padding-left: 1rem;
      $padding-right: 0.5rem;
      width: calc(100% - $padding-left - $padding-right - 2 * $border-width);
      height: 1.5rem;
      padding: 0.5rem $padding-right 0.5rem $padding-left;
      border-radius: 0.5rem; //8px
      border-width: $border-width;
    }

    .validated-input-label {
      @include fonts.caption1;
    }

    .buttons {
      justify-content: space-evenly;
    }
  }
}