@use '../../../scss/fonts' as fonts;
@use '../../../scss/colors' as colors;

.filter-inventory {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h4 {
    @include fonts.h5;
    margin-top: 2rem;
    text-align: center;
  }

  hr {
    margin: 1.5rem 0;
    width: 100%;
    border: 1px solid colors.$text-200;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .inventory-type,
  .sort-inventory-by {
    display: flex;
    flex-direction: column;

    h5 {
      @include fonts.body2;
      padding-bottom: 0.5rem;
    }

    label {
      @include fonts.body3;
    }

    input {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  .filter-option {
    display: flex;
    flex-direction: column;

    label {
      @include fonts.body2;
      padding-bottom: 0.5rem;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;

    button {
      width: 9rem;
    }
  }
}
