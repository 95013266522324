@use '../../scss/screenSizes' as screenSizes;
@use '../../scss/fonts' as fonts;

.info-warning {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &-icon {
    display: flex;
    align-items: center;
  }

  &-message {
    font-weight: 500;
  }

  @include screenSizes.mobile {
    align-items: flex-start;

    &-icon {
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    &-message {
      @include fonts.caption1;
      font-weight: 500;
    }
  }
}