@use '../../scss/elevations' as elevations;
@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.animal-management {
  .xs-animal-management {
    display: none;
  }

  @include screenSizes.mobile {
    .desk-animal-management {
      display: none;
    }

    .xs-animal-management {
      display: block;
      padding: 1rem;

      .result-item {
        text-align: center;
        @include fonts.caption1;
      }

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0rem 0rem 0rem;
        }

        .animal-management-card {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .animal-management-content {
            width: 85%;
            border-right: 1px solid colors.$text-200;

            .card-content-section {
              display: flex;
              border-bottom: 1px solid colors.$text-200;
              padding: 0.5rem;

              .animal-content {
                border-right: 1px solid colors.$text-200;
                padding: 0 0.5rem;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                &:last-child {
                  border-right: none;
                }
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }

          .view-animal-link {
            width: 15%;
            text-align: center;
            @include fonts.caption1;
            font-size: 0.75rem;
          }

          label {
            @include fonts.caption1;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.manage-animals {
  .row {
    display: flex;

    .input {
      width: calc(50% - 0.5rem);
    }
  }
}

.add-animal {
  padding: 2rem;
  min-height: 30rem;

  .add-animal-header {
    display: none;
  }

  h2 {
    margin-bottom: 1rem;
  }

  .step-counter {
    display: flex;
    align-items: center;
    @include fonts.body3-semibold;
    margin-bottom: 1rem;

    .step-connector {
      width: 2.625rem;
      height: 0.2rem;
      background-color: colors.$primary-green-500;
    }

    .step-bubble {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.75rem;
      height: 1.75rem;
      border: 2px solid colors.$primary-green-500;
      border-radius: 10rem;

      &.active {
        width: 2rem;
        height: 2rem;
        background-color: colors.$primary-green-500;
      }
    }
  }

  .animal-info-container {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 2rem;

    .animal-info-image {
      background-color: colors.$surface-400;
      width: 50%;
      max-width: 26.625rem;
      border-radius: 0.3rem;
    }
  }

  .has-owners {
    margin: 1rem 0;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;

    .button {
      margin-left: 2rem;
    }
  }

  @include screenSizes.tablet {
    padding: 1.5rem;
    min-height: 15rem;

    h2 {
      @include fonts.h5;
    }

    .animal-info-container {
      gap: 1.5rem;

      .animal-info-image {
        width: 40%;
      }

      .animal-info-column {
        width: 60%;
      }
    }

    .has-owners {
      p {
        @include fonts.caption1;
      }

      label {
        @include fonts.caption1-semibold;
      }

      input[type='radio'] {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    .button-row {
      margin-top: 2rem;
    }
  }

  @include screenSizes.mobile {
    padding: 1rem;
    max-height: 90vh;
    min-height: 18rem;

    .add-animal-header {
      display: block;
      @include fonts.h4;
      text-align: center;
      padding-bottom: 1rem;
      border-bottom: 1px solid colors.$text-200;
    }

    h2 {
      @include fonts.h6;
      font-size: 1.125rem;
    }

    .step-counter {
      font-size: 0.75rem;
      margin: 1rem 0rem;

      .step-connector {
        width: 2.625rem;
        height: 0.1rem;
      }

      .step-bubble {
        width: 1.25rem;
        height: 1.25rem;

        &.active {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .animal-info-container {
      gap: 1rem;
      flex-direction: column;

      .animal-info-image {
        width: 100%;
        max-width: 100%;
        height: 10rem;
      }
    }

    .has-owners {
      p {
        @include fonts.caption1;
      }

      label {
        @include fonts.caption1-semibold;
      }

      input[type='radio'] {
        width: 1rem;
        height: 1rem;
      }
    }

    .button-row {
      display: flex;
      justify-content: space-evenly;

      .button {
        margin-left: 0rem;
        margin-bottom: 1rem;
        width: 8rem;
      }
    }
  }
}

.animal-info-form {
  min-width: 20rem;

  .row {
    display: flex;
    justify-content: space-between;

    .input {
      width: calc(50% - 0.5rem);
    }
  }

  @include screenSizes.tablet {
    .row .input {
      margin-bottom: 0.25rem;

      label {
        @include fonts.caption1;
      }

      .validated-input {
        margin-top: 0.25rem;

        input {
          @include fonts.caption2;
          font-size: 0.75rem;
        }
      }

      .filter-dropdown,
      .date-input {
        margin-top: 0.25rem;

        @include fonts.caption2;
        font-size: 0.75rem;

        .react-datepicker__input-container {
          input {
            @include fonts.caption2;
            font-size: 0.75rem;
          }
        }
      }
    }

    .input {
      label {
        @include fonts.caption1;
      }

      textarea {
        margin-top: 0.25rem;
        @include fonts.caption2;
        font-size: 0.75rem;
      }
    }
  }

  @include screenSizes.mobile {
    .row {
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      .input {
        width: 100%;

        label {
          @include fonts.caption1-semibold;
          font-size: 0.75rem;
        }
      }
    }

    .input {
      label {
        @include fonts.caption1-semibold;
        font-size: 0.75rem;
      }
    }
  }
}

.ownership-overview {
  .account-search-row {
    margin: 1rem 0;
    display: flex;
    align-items: center;

    label {
      @include fonts.subheader2;
      padding-right: 1rem;
    }

    .account-input {
      max-width: 16.125rem;
    }

    button {
      margin-left: 0.5rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .owner-list {
    .owner-row {
      @include elevations.elevation-1;
      padding: 0.5rem;
      margin: 1rem 0;
      display: flex;
      align-items: center;

      .owner-input-row {
        display: flex;
        align-items: center;

        .owner-input {
          padding: 0.5rem;
        }
      }

      button {
        width: 4rem;
        height: 3rem;
      }
    }
  }

  .add-owner {
    margin: 1rem 0;

    button {
      @include fonts.body3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 10rem;
    }
  }

  @include screenSizes.tablet {
    h2 {
      @include fonts.h6;
      font-size: 1.125rem;
    }

    p {
      @include fonts.caption1;
    }

    .account-search-row {
      label {
        @include fonts.caption1;
      }
    }

    .owner-list {
      .owner-input {
        label {
          @include fonts.caption1;
        }
      }

      .owner-row button {
        svg {
          height: 1.25rem;
        }
      }
    }
  }

  @include screenSizes.mobile {
    h2 {
      @include fonts.h6;
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
    }

    p {
      @include fonts.caption1;
      font-size: 0.75rem;
    }

    .account-search-row {
      label {
        @include fonts.caption1;
        font-size: 0.75rem;
      }
    }

    .owner-list {
      .owner-row {
        .owner-input-row {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          .owner-input {
            padding: 0.5rem;
            width: 100%;

            label {
              font-size: 0.75rem;
            }
          }
        }

        button {
          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }

    .add-owner {
      button {
        font-size: 0.75rem;
        justify-content: flex-start;
        gap: 0.5rem;

        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
  }
}

.specimen-view-edit {
  .edit-specimen {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .specimen-owners-container {
    align-items: start;

    .specimen-owners {
      display: flex;
      flex-direction: column;

      .specimen-owner {
        display: flex;
        align-items: center;

        .account-name {
          width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .specimen-quantity {
          width: 40%;
        }
      }
    }
  }

  @include screenSizes.mobile {
    pre {
      font-size: 0.75rem;
      font-weight: 400;
    }

    .submit-button {
      justify-content: space-evenly;
      margin-top: 1rem;
    }
  }
}