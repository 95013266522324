@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.manage-billing-information {
  padding: 2rem 1.5rem;

  .payment-information {
    margin: 0rem;
  }

  .top-header {
    @include fonts.h5;
  }

  .manage-billing-info__header {
    .manage-billing-info__tabs {
      display: flex;
      list-style: none;
      margin: 0;
      margin-top: 1rem;
      padding: 0;

      li {
        cursor: pointer;
        margin-right: 1rem;
        padding: 0.5rem;
        position: relative;
        line-height: 28px;
        @include fonts.body2;

        &.active {
          font-weight: 500;

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 0.1rem;
            background-color: colors.$primary-green-500;
          }
        }
      }
    }
  }

  .manage-billing-info__body {
    margin-top: 2rem;

    .storage-rates-loader {
      margin-top: 5rem;
    }

    .payment-history .payment-history-container .sorted-table {
      table tr td {
        padding: 1rem 0;
      }
    }


    .payment-method {
      display: flex;

      .card {
        display: flex;
        width: 15rem;
        padding: 0.5rem;
        margin: 0;
        flex-direction: column;
      }

      .payment-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          margin-left: 0.5rem;
        }

        .edit-button {
          width: 2.5rem;
          height: 2.5rem;

          svg {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.3rem;
          }
        }
      }

      .payment-card-body {
        display: flex;
        flex-direction: column;
        padding: 1rem 0.5rem 0.6rem 0.5rem;
        gap: 0.5rem;

        .card-number {
          display: flex;
          align-items: center;
          @include fonts.h6;
          letter-spacing: 0.1rem;

          span {
            margin-top: -0.4rem;
            @include fonts.button2;
          }
        }

        .billing-address {
          display: flex;
          gap: 0.5rem;
          @include fonts.caption1;
        }
      }
    }
  }

  @include screenSizes.tablet {
    .payment-history .payment-history-container .sorted-table {
      table thead tr th {
        padding: 1rem 0;
      }
    }
  }

  @include screenSizes.mobile {
    padding: 2rem 1.4rem 1rem 1rem;

    .top-header {
      @include fonts.h6;
    }

    .manage-billing-info__header .manage-billing-info__tabs li {
      @include fonts.body3;
      font-size: 0.75rem;
      margin-right: 0.5rem;
    }

    .manage-billing-info__body {
      .payment-history {
        .sorted-table {
          tr {

            th,
            td {
              @include fonts.caption2;
              padding: 0.5rem 0.4rem;
            }
          }
        }
      }

      .payment-method .card {
        padding: 0.3rem;
      }

      .payment-method {
        .payment-card-header {
          img {
            width: 2.7rem;
            height: 2rem;
          }

          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        .payment-card-body {
          div {
            &:first-child {
              @include fonts.h7;
            }

            &:nth-child(2) {
              @include fonts.caption1;
              font-size: 0.75rem;
            }
          }
        }
      }

      .add-payment-method-button {
        .button {
          @include fonts.caption1;
        }
      }
    }
  }
}