@use "../../scss/" as *;
@use '../../scss/screenSizes' as screenSizes;

.modal {
    @include screenSizes.mobile {
        display: none
    }

    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content-container {
        display: flex;
        flex-direction: column;
        border-radius: .5rem;
        width: 50%;
        height: fit-content;
        max-height: 100%;
        background-color: $surface-100;
        overflow-y: auto;

        .modal-close {
            color: $black;
            display: flex;
            justify-content: flex-end;
        }

        .modal-close:hover,
        .modal-close:focus {
            text-decoration: none;
            cursor: pointer;
        }
    }

    @include screenSizes.tablet {
        .modal-content-container {
            width: 66.66%;
        }
    }
}

.xs-modal {
    display: none;

    @include screenSizes.mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: rgba(217, 217, 217, 0.50);
        transition: background-color linear, bottom 0.3s ease-in-out;

        &.hide {
            bottom: -30rem;
            height: 0;
            transition: all 0.3s;
        }

        .modal-content-container {
            display: flex;
            flex-direction: column;
            border-radius: 2.5rem 2.5rem 0 0;
            width: 100%;
            height: fit-content;
            max-height: 100%;
            background-color: $surface-100;
            overflow-y: auto;
        }
    }
}