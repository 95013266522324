@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;

.filter-dropdown {
  position: relative;

  &.disabled {
    color: colors.$text-200;
  }

  .flex-apart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .form-field {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.error {
      border: 1px solid colors.$secondary-red-300;
    }
  }

  .options {
    position: absolute;
    top: 3rem;
    left: 0;
    width: calc(100% - 1px);
    color: colors.$text-300;
    background-color: colors.$surface-100;
    border: 1px colors.$surface-500 solid;
    z-index: 100;
    max-height: 15rem;
    overflow-y: auto;

    input.filter-input {
      padding: 0.1rem 0.5rem;
      width: calc(100% - 1rem);
      border-radius: 0;
      border: none;
      border-bottom: 1px colors.$surface-500 solid;
      outline: none;
    }

    &.hidden {
      height: 0;
      overflow: hidden;
      border: none;
    }

    .option {
      padding: 0.1rem 0.5rem;

      &:hover,
      &.highlighted {
        cursor: pointer;
        background-color: colors.$surface-300;
      }
    }

    .no-results {
      padding: 0.1rem 0.5rem;
    }

    .option-disabled,
    .option-warning {
      display: flex;
      flex-wrap: wrap;
      gap: 0.3rem;
      background-color: colors.$warning-100;
      opacity: 0.7;
    }

    .option-disabled {
      pointer-events: none;
    }
  }

  .display-none {
    display: none;
  }

  @include screenSizes.mobile {
    .options {
      top: 2rem;
    }
  }

  @include screenSizes.tablet {
    .options {
      top: 2.75rem;
    }
  }
}