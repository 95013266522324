@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/elevations' as elevations;

.account-card {
  position: relative;
  $side-padding: 1.5rem;
  padding: 1rem $side-padding;
  display: flex;
  align-items: center;
  background-color: colors.$surface-100;

  .profile-image {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 0.5rem;
    background-color: colors.$surface-400;
    aspect-ratio: 1;
  }

  .full-width {
    width: calc(100% - 2.7rem);
  }

  .switch-account {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &.reverse {
      svg {
        transform: scaleY(-1);
      }
    }
    svg {
      margin: 0.5rem;
      path {
        fill: colors.$primary-blue-400;
      }
    }
  }

  .edit-profile {
    display: none;
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
  }

  .account-item {
    width: 100%;
    display: flex;
    align-items: center;

    h3 {
      @include fonts.body2;
      color: colors.$text-600;
    }

    .account-email {
      @include fonts.body3;
      color: colors.$text-300;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .account-name {
      @include fonts.caption1;
      color: colors.$primary-blue-400;
      line-height: 1.5rem;

      p {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        width: 10%;
      }
    }
  }

  .account-dropdown {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: $side-padding;
    right: $side-padding;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      top: -0.5rem;
      right: 0.35rem;
      width: 1rem;
      height: 1rem;
      margin-left: -1rem;
      background-color: colors.$surface-200;
      border: 1px solid colors.$surface-500;
      transform: matrix(0.49, 0.92, -0.49, 0.92, 0, 0);
    }
    .centered {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .account-item {
      $item-padding: 1rem;
      width: calc(100% - 2 * $item-padding);
      background-color: colors.$surface-200;
      padding: $item-padding;
      cursor: pointer;
      position: relative;
      z-index: 1;
      border: 1px solid colors.$surface-500;
      border-bottom: none;

      h3 {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        margin: 0.5rem 1rem;
        path {
          fill: colors.$icon-300;
        }
      }

      &:hover {
        @include elevations.elevation-3;
      }

      &:active {
        @include elevations.elevation-1;
      }

      &:last-child {
        border-bottom: 1px solid colors.$surface-500;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}
