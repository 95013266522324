@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.item-storage-locations {
  .desk-item-storage-locations {
    .specimen-table {
      .total-selected-quantity {
        display: inline-block;
        position: relative;
        width: fit-content;

        &::before {
          content: 'Total Quantity Selected:';
          font-weight: bolder;
          position: absolute;
          right: 100%;
          padding-right: 1rem;
          white-space: nowrap;
        }
      }

      .no-locations-available {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem;
        padding: 1rem;
      }
    }
  }

  .xs-item-storage-locations {
    display: none;
  }

  @include screenSizes.tablet {
    .number-input {
      input {
        font-size: 0.75rem;
      }
    }
  }

  @include screenSizes.mobile {
    .desk-item-storage-locations {
      display: none;
    }

    .xs-item-storage-locations {
      display: block;

      .warning {
        background-color: colors.$warning-100;
      }

      .error {
        background-color: colors.$alert-100;
      }

      .invalid-row {
        background-color: rgba(colors.$secondary-red-200, 0.33);
      }

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0rem 0rem 0rem;
        }

        .item-storage-locations-row {
          display: flex;
          width: 100%;

          .input-check {
            align-self: center;
            padding: 0.25rem;

            input {
              min-width: 1rem;
            }
          }

          .item-storage-locations-content {
            display: flex;
            flex-direction: column;
            border-left: 1px solid colors.$text-200;
            border-right: 1px solid colors.$text-200;
            padding: 0.5rem;
            align-items: flex-start;
            justify-content: center;
            gap: 0.5rem;
            width: 65%;
          }

          .number-input-section {
            display: flex;
            flex-direction: column;
            width: 25%;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0.25rem;
            gap: 0.5rem;

            input {
              max-width: 50%;
              text-align: center;
              padding: 0.25rem;
              border-radius: 0rem;
              @include fonts.caption2;
              font-size: 0.75rem;
            }
          }

          label {
            @include fonts.caption1;
            font-size: 0.75rem;
          }
        }
      }

      .disabled {
        label {
          color: colors.$text-200;
        }
      }

      .total-quantity {
        display: flex;
        justify-content: flex-end;
        margin: 1rem;
        @include fonts.body3;

        label {
          @include fonts.body3;
          font-weight: 600;
        }
      }
    }
  }
}