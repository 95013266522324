@use '../../../../scss/colors' as colors;
@use '../../../../scss/fonts' as fonts;

.email-change-confirm-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .modal-content {
    margin: 2rem;

    .modal-header {
      @include fonts.h4;
      margin: 1rem;
    }

    .modal-body {
      @include fonts.body2;
      padding: 0rem 1rem;
      p {
        margin-bottom: 1rem;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      height: 5rem;

      button {
        margin: 1rem;
      }
    }
  }
}
