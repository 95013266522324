@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
.terms-conditions {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  p {
    @include fonts.body3;
    color: colors.$text-600;
  }
  b {
    @include fonts.body3-semibold;
    text-decoration: underline;
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  input {
    width: 1.25rem;
    height: 1.35rem;
  }
}
