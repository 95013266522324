@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.transaction-list {
  padding: 1rem;

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 1rem;

    .search-stack {
      display: flex;
      flex-direction: row;
      align-items: center;

      .search {
        width: auto;
      }
    }

    .transaction-header {
      display: flex;
      align-items: center;
      @include fonts.subheader1-medium;
    }
  }

  .filter-row {
    padding: 1rem 0;
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-bottom: 0.5rem;
    justify-content: space-between;
  }

  .header-hr {
    border: 1px solid colors.$surface-400;
    border-bottom: none;
  }

  .selected-filters-summary {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .filter-summary {
      display: flex;
      align-items: center;
      padding: 0.25rem 0.75rem;
      border-radius: 0.5rem;
      @include fonts.caption1;
      font-size: 0.75rem;
      border-color: colors.$filter-turquoise;
      color: colors.$filter-turquoise;
      background: colors.$powder-blue;
      border: 1px solid;

      .remove-filter-btn {
        display: flex;
      }

      button {
        background: none;
        border: none;
        margin-left: 0.5rem;
        cursor: pointer;

        svg {
          width: 0.75rem;
          height: 0.75rem;

          path {
            fill: colors.$filter-turquoise;
          }
        }
      }
    }
  }

  .xs-filter-row {
    display: none;
  }

  @include screenSizes.tablet {
    .header {
      flex-direction: column;
      gap: 1rem;

      .transaction-header {
        @include fonts.subheader2-medium;
      }
    }

    .selected-filters-summary {
      margin-top: 0.75rem;

      .filter-summary {
        padding: 0.25rem;
        @include fonts.caption2;

        button {
          margin-left: 0.25rem;

          svg {
            width: 0.625rem;
            height: 0.625rem;
          }
        }
      }
    }
  }

  @include screenSizes.mobile {
    padding: 1.5rem;

    .header {
      flex-direction: column;
      width: 100%;

      .transaction-header {
        @include fonts.h5;
        margin-bottom: 1rem;
      }

      .search-stack {
        width: 100%;

        .search {
          width: 100%;
        }
      }
    }

    .selected-filters-summary {
      margin-top: 0.75rem;

      .filter-summary {
        padding: 0.25rem;
        @include fonts.caption2;

        button {
          margin-left: 0.25rem;

          svg {
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }
    }

    .filter-row {
      display: none;
    }

    .xs-filter-row {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: 1rem 0rem;
      gap: 0.25rem;

      .filter-icon-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.5rem 0rem;

        p {
          display: flex;
          align-items: center;
          @include fonts.caption1;
          gap: 0.25rem;
        }

        .reset-filters-button {
          gap: 0.25rem;

          label {
            @include fonts.caption1;
            color: colors.$primary-blue-400;
          }
        }

        svg {
          width: 1rem;
          height: 1rem;
          fill: colors.$primary-blue-400;
        }
      }

      .create-transaction {
        height: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.5rem;
      }

      .toggle-switch-container {
        margin-top: 0.5rem;
        justify-content: center;
      }

      .header-hr {
        width: 100%;
        margin-top: 0.8rem;
      }

      .outlined {
        @include fonts.caption1;
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}