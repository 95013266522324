@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.storage-plans-modal {
  display: flex;
  flex-direction: column;
  padding: 2.75rem 2.75rem 5rem 2.75rem;
  border-radius: 0.5rem;
  background-color: colors.$white;

  .header {
    @include fonts.h4;
    padding-bottom: 0.5rem;
  }

  .subheader {
    @include fonts.body2;
  }

  .storage-plans-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 8rem;
    border-top: 1px solid colors.$black;
    border-bottom: 1px solid colors.$black;
    padding: 0.5rem 0;
    margin-top: 0.25rem;
  }

  .xs-storage-plans-list {
    display: none;
  }

  .terms-conditions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    p {
      @include fonts.body2;
      color: colors.$text-600;
    }
  }

  .done-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  @include screenSizes.tablet {
    padding: 2.25rem;
  }

  @include screenSizes.mobile {
    width: fit-content;
    margin: auto;
    padding: 2rem;

    .header {
      @include fonts.h5;
      padding-bottom: 1rem;
      text-align: center;
    }

    .subheader {
      @include fonts.body3;
      text-align: center;
    }

    .storage-plans-list {
      display: none;
    }

    .xs-storage-plans-list {
      display: block;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
      gap: 1rem;

      .storage-plan-row {
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid colors.$black;

          .cell {
            width: 50%;
            text-align: center;
            border-right: 1px solid colors.$black;
            padding: 0.5rem;

            &:last-child {
              border-right: none;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}