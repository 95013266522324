@use '../../scss/elevations' as elevations;
@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.desk-select-specimen,
.desk-select-specimens,
.desk-pending-use-transaction,
.desk-pending-use-selected,
.desk-reconcile-origination,
.desk-reconcile-account-ownership,
.desk-reconcile-canister-specimens {

  .add-location-button,
  .add-account-ownership-button {
    background-color: colors.$primary-blue-500;
    border-radius: 0.15rem;
    color: colors.$surface-100;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: bold;
    height: 1.5rem;
    width: 3.5rem;

    &:hover {
      background-color: colors.$primary-blue-400;
    }

    &:active {
      background-color: colors.$primary-blue-300;
    }
  }

  @include screenSizes.mobile {
    display: none;
  }

  @include screenSizes.tablet {
    table {
      input {
        @include fonts.caption2;
      }
    }
  }
}

.xs-select-specimen {
  display: none;

  @include screenSizes.mobile {
    display: block;

    .no-specimen {
      @include fonts.caption2;
      display: block;
      font-size: 0.75rem;
      text-align: center;
    }

    .wide-input-container {
      max-width: 14rem;
    }

    label .account-due-payment {
      color: colors.$secondary-red-300;
    }
  }
}

.xs-pending-use-transaction {
  display: none;

  @include screenSizes.mobile {
    display: block;

    .form-row {
      max-width: 100%;

      .no-pending-use-transaction {
        @include fonts.caption2;
        display: block;
        font-size: 0.75rem;
        text-align: center;
      }

      .wide-input-container {
        max-width: 100%;
      }
    }

    .selected-pending-use-transaction {
      .card {
        padding: 0.5rem 0rem;

        &.max-width {
          margin: 1rem 0.5rem 0rem 0rem;
        }

        .header {
          @include fonts.body3;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }

        hr {
          margin-top: 0.5rem;
          border-color: colors.$text-200;
        }

        .card-header,
        .body {
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .animal-name,
          .lot-date-number,
          .owned-transaction,
          .available-transaction {
            width: 33%;
            text-align: center;
            border-right: 1px solid colors.$text-200;
          }

          .requested-transaction,
          .transaction-link-id {
            width: 33.33%;
            text-align: center;
          }

          label {
            @include fonts.caption2;
          }
        }
      }
    }
  }
}

.xs-pending-use-selected {
  display: none;

  @include screenSizes.mobile {
    display: block;

    .card {
      padding: 0rem 0rem;

      &.max-width {
        margin: 1rem 0.5rem 0rem 0rem;
      }

      .body {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .specimen-location {
          border-right: 1px solid colors.$text-200;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0.5rem;
          width: 65%;

          .specimen-location-label {
            @include fonts.body3;
            margin-bottom: 0.25rem;
            word-break: break-all;
          }
        }

        .selected-specimen-qty {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0.5rem 1rem;
          text-align: center;
          width: 35%;

          input {
            @include fonts.caption2;
            border-radius: 0rem;
            font-size: 0.75rem;
            max-width: 4.5rem;
            padding: 0.25rem;
            text-align: center;
          }
        }
      }
    }

    .invalid-row {
      background-color: rgba(colors.$secondary-red-200, 0.33);
    }

    .override-row {
      background-color: rgba(colors.$warning-300, 0.66);
    }

    .disabled {
      label {
        color: colors.$text-200;
      }
    }

    .total-quantity {
      @include fonts.caption2;
      display: flex;
      font-size: 0.75rem;
      justify-content: space-between;
      margin: 1rem;
    }

    label {
      @include fonts.caption2;
      font-size: 0.75rem;
    }
  }
}

.xs-reconcile-origination,
.xs-select-specimens,
.xs-reconcile-account-ownership,
.xs-reconcile-canister-specimens {
  display: none;

  @include screenSizes.mobile {
    display: block;

    .warning {
      background-color: colors.$warning-100;
    }

    .error {
      background-color: colors.$alert-100;
    }

    .invalid-row {
      background-color: rgba(colors.$secondary-red-200, 0.33);
    }

    .result-item {
      text-align: center;
      @include fonts.caption1;
    }

    .card {
      padding: 0rem;

      &.max-width {
        margin: 1rem 0rem 0rem;
      }

      .selected-specimens-row {
        display: flex;
        flex-direction: row;

        .input-check {
          align-self: center;
          padding: 0.25rem;

          input {
            min-width: 1rem;
            border-right: 1px solid colors.$text-200;
          }
        }

        .select-specimens {
          border-left: 1px solid colors.$text-200;
          width: 100%;
        }
      }

      .select-specimens,
      .reconcile-origination,
      .reconcile-account-ownership,
      .reconcile-canister-specimens {
        display: flex;
        flex-direction: column;

        .card-content-section {
          border-bottom: 1px solid colors.$text-200;
          display: flex;
          padding: 0.25rem;
          text-align: center;

          .left {
            border-right: 1px solid colors.$text-200;
            padding: 0.25rem;
            flex: 1;
          }

          .center {
            align-items: center;
            display: flex;
            justify-content: center;
          }

          .column {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
          }

          .right {
            padding: 0.25rem;
            flex: 1;
          }

          &:last-child {
            border-bottom: none;
          }

          input {
            @include fonts.caption2;
            border-radius: 0rem;
            font-size: 0.75rem;
            max-width: 3.5rem;
            padding: 0.25rem;
            text-align: center;
          }
        }

        label {
          @include fonts.caption1;
          font-size: 0.71rem;
        }
      }
    }

    .disabled {
      label {
        color: colors.$text-200;
      }
    }

    .account-ownership-footer,
    .location-footer {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      .add-account-ownership-button,
      .add-location-button {
        background-color: colors.$primary-blue-500;
        border-radius: 0.15rem;
        color: colors.$surface-100;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        height: 1.25rem;
        width: 2.5rem;

        &:hover {
          background-color: colors.$primary-blue-400;
        }

        &:active {
          background-color: colors.$primary-blue-300;
        }
      }
    }

    .total-quantity {
      display: flex;
      justify-content: flex-end;
      margin: 1rem;
      @include fonts.body3;

      label {
        @include fonts.body3;
        font-weight: 600;
      }
    }


  }
}

.specimen-table {
  @include elevations.elevation-1;
  overflow: auto;
  position: relative;
  max-height: 18rem;

  .account-due-payment {
    font-size: 0.875rem;
    color: colors.$secondary-red-300;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: none;

    thead,
    tr:last-child {
      position: sticky;
      top: 0;

      th,
      td {
        position: relative;
        padding: 0.5rem 0;
        background: colors.$surface-400;
      }

      th {
        &:first-child::before {
          display: none;
        }

        &::before {
          position: absolute;
          display: block;
          content: '';
          border-left: 1px solid colors.$text-600;
          background: colors.$black;
          top: 0.5rem;
          bottom: 0.5rem;
          left: -0.5px;
        }
      }
    }

    tr:last-child {
      bottom: 0;

      td {
        border: none;
      }

      td:first-child {
        text-align: right;
      }
    }

    tr:nth-child(2n) {
      background-color: colors.$surface-200;
    }

    tr:nth-child(2n - 1) {
      background-color: colors.$surface-100;
    }

    tr.warning {
      background-color: colors.$warning-100;
    }

    tr.error {
      background-color: colors.$alert-100;
    }

    td {
      color: colors.$text-600;
      text-align: center;
      border-left: 1px solid colors.$text-600;
      border-top: 1rem solid transparent;
      border-bottom: 0.5rem solid transparent;
    }

    tr:first-child td {
      border-top: 0.5rem solid transparent;
    }

    th {
      @include fonts.body2;
    }

    td {
      @include fonts.body3;
    }

    td:first-child,
    th:first-child {
      border-left: none;
    }

    input {
      border: 1px solid colors.$text-200;
      padding: 0 0.25rem;
      border-radius: 0;
      width: 4rem;
      text-align: center;
    }

    input[type='number']::-webkit-inner-spin-button {
      position: absolute;
      width: 12.5%;
      height: 100%;
      top: 0;
      right: 0;
    }

    tr.invalid-row {
      td {
        background-color: rgba(colors.$secondary-red-200, 0.33);
      }
    }

    tr.override-row {
      td {
        background-color: rgba(colors.$warning-300, 0.66);
      }
    }

    tr.disabled {
      td {
        color: colors.$text-200;
        border-left: 1px solid colors.$text-200;
      }

      td:first-child {
        border-left: none;
      }
    }

    .quantity {
      display: inline-block;
      position: relative;
      width: fit-content;

      &::before {
        content: 'Total Selected:';
        position: absolute;
        right: 100%;
        padding-right: 1rem;
        white-space: nowrap;
        font-weight: 600;
      }

      &.total-to-use {
        &::before {
          content: 'Total Quantity To Use:';
        }
      }

      &.storage-total {
        &::before {
          content: 'Selected Qty to Use:';
        }
      }
    }

    .storage-total-quantity::before {
      content: 'Actual Qty:';
    }

    .owned-quantity::before {
      content: 'Owned Qty:';
    }

    .original-quantity::before {
      content: 'Original Qty:';
    }
  }

  @include screenSizes.tablet {
    .account-due-payment {
      font-size: 0.625rem;
    }

    table {
      th {
        @include fonts.caption2-semibold;
      }

      td {
        @include fonts.caption2;
      }

      input {
        width: 2rem;
      }
    }
  }
}

.desk-select-specimen {
  .specimen-table table tbody tr {
    td:nth-child(4) {
      word-break: break-word;
    }
  }
}