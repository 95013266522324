@mixin elevation-0 {
  box-shadow: none;
}

@mixin elevation-1 {
  @include elevation-0;
  box-shadow: 0px 1px 4px rgba(24, 29, 32, 0.2);
}

@mixin elevation-2 {
  @include elevation-0;
  box-shadow: 0px 2px 8px rgba(24, 29, 32, 0.25);
}

@mixin elevation-3 {
  @include elevation-0;
  box-shadow: 0px 8px 16px rgba(24, 29, 32, 0.25);
}

@mixin elevation-4 {
  @include elevation-0;
  box-shadow: 0px 6px 24px rgba(24, 29, 32, 0.25);
}
