@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.transaction-edit {
  .edit-transaction-buttons {
    margin-top: 2.5rem;
  }

  .form-label {
    @include fonts.body3;
    color: colors.$text-400;
  }

  .specimens-table {
    margin: 1rem 0rem;

    input {
      border: 1px solid #aaacad;
      padding: 0.25rem;
      border-radius: 0;
      max-width: 4rem;
      text-align: center;
    }
  }

  @include screenSizes.tablet {
    .form-row {
      &.expanded-text-area {
        label {
          max-width: 17.5rem;
        }

        textarea {
          min-height: 4rem;
          padding: 1rem;
          width: calc(100% - 19rem);
        }
      }

      .input-container,
      .validated-input {
        max-width: 16.5rem
      }
    }
  }

  @include screenSizes.mobile {
    .form-row {
      margin-bottom: 0rem;
      max-width: none;

      &.expanded-text-area {
        max-width: none;

        textarea {
          max-width: 60%;
          line-height: 1rem;
          font-size: 0.75rem;
        }
      }

      .input-container {
        max-width: none;
        width: auto;
      }

      .filter-dropdown-mobile {
        max-width: 10.5rem;
        width: 100%;

        .filter-dropdown {
          font-size: 0.75rem;
          padding: 0.75rem 1rem;
        }
      }

      .validated-input {
        input {
          font-size: 0.75rem;
          padding: 0.75rem 1rem;
        }
      }

      .form-label {
        font-size: 0.75rem;
      }
    }
  }
}