@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.management-columns {
  .management-columns-modal {
    padding: 1rem 2rem;
  }

  .header {
    display: flex;
    align-items: center;
    border-top: 1px solid colors.$surface-500;
    border-bottom: 1px solid colors.$surface-500;
    margin: 1rem 0 1rem 0;
    padding: 0.5rem 0;
    width: 100%;
    justify-content: space-between;

    .header-section {
      display: flex;
      align-items: center;
    }

    .header-stats {
      height: 1.5rem;

      h1 {
        padding-right: 1rem;
        @include fonts.h5;
        color: colors.$text-600;
      }

      div {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        height: 100%;
        border-left: 1px solid colors.$surface-500;

        span {
          label {
            @include fonts.subheader2-medium;
            color: colors.$text-600;
          }
        }
      }
    }

    .show-status .filter-dropdown {
      height: 1rem;
      width: 8rem;
    }
  }

  .info-section {
    display: flex;
    flex-direction: column;

    .info-stat {
      @include fonts.body1;
      margin-top: 0.5rem;

      strong {
        margin-left: 4px;
        font-weight: bold;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    background-color: colors.$surface-500;
    gap: 1px;
    margin-top: 1rem;
    @include elevations.elevation-1;
    height: 30rem;

    .content-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: colors.$surface-500;
      gap: 1px;

      .content-header,
      .content-content {
        background-color: colors.$white;
      }

      .content-header {
        display: flex;
        padding: 1rem;
        justify-content: space-between;
      }

      .content-content {
        height: 100%;
        overflow-y: auto;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include fonts.body1;
          color: colors.$black;
          padding: 0.75rem 1rem;

          .management-column-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          div {
            display: flex;
            align-items: center;
            width: 100%;
          }

          svg path {
            fill: colors.$black;
          }

          span.inactive {
            color: colors.$text-300;
          }

          &.selected {
            color: colors.$white;
            background-color: colors.$primary-blue-400;

            svg path {
              fill: colors.$white;
            }

            span.inactive {
              color: colors.$text-150;
            }
          }
        }
      }
    }
  }


  @include screenSizes.tablet {
    .header {

      .header-stats {
        height: auto;

        h1 {
          padding-right: 0.5rem;
          @include fonts.h5;
          font-size: 1.125rem;
        }

        div {
          padding: 0.5rem;
          @include fonts.body2;

          span {
            text-align: center;

            label {
              @include fonts.body1;
              font-weight: 500;
            }
          }
        }
      }

      .show-label {
        @include fonts.body2;
      }
    }

    .info-section {
      .info-stat {
        @include fonts.caption1;
      }
    }

    .content {
      flex-direction: column;
      background-color: transparent;
      gap: 1rem;
      @include elevations.elevation-0;
      height: 100%;

      .content-section {
        @include elevations.elevation-1;

        .content-header {
          padding: 0.75rem 1rem;
          align-items: center;

          h2 {
            @include fonts.body1-semibold;
          }

          button {
            svg {
              width: 1.25rem;
              height: 1.25rem;
            }

            @include fonts.button3;
            width: 9rem;
          }
        }

        .content-content {
          height: 13rem;

          .item {
            @include fonts.body2;
            padding: 0.5rem 1rem;
          }
        }
      }
    }

    .management-columns-modal {
      padding: 1rem;

      h2 {
        padding-bottom: 1rem;
      }

      p {
        @include fonts.body2;
        padding-bottom: 0.5rem;
      }

      .validated-input-message-error {
        padding: 0.25rem 0;
      }

      .submit-button {
        margin-top: 1rem;
      }
    }
  }

  @include screenSizes.mobile {
    .header {
      align-items: flex-start;
      padding: 0;
      flex-direction: column;
      border-top: none;
      border-bottom: none;

      .header-section {
        border-bottom: 1px solid colors.$surface-500;
        padding: 0.5rem 0.25rem;
        width: 100%;
      }

      .header-stats {
        height: auto;
        border-top: 1px solid colors.$surface-500;
        justify-content: space-between;

        h1 {
          padding-right: 0;
          @include fonts.h6;
        }

        div {
          padding: 0.5rem;
          @include fonts.body3;

          span {
            text-align: center;

            label {
              @include fonts.body2;
              font-weight: 500;
            }
          }
        }
      }

      .show-label {
        @include fonts.body3;
      }
    }

    .info-section {
      align-items: end;

      .info-stat {
        @include fonts.caption2;
        font-size: 0.75rem;
      }
    }

    .content {
      flex-direction: column;
      background-color: transparent;
      gap: 1rem;
      @include elevations.elevation-0;
      height: 100%;

      .content-section {
        @include elevations.elevation-1;

        .content-header {
          padding: 0.5rem 1rem;
          align-items: center;

          h2 {
            @include fonts.h6;
          }

          button {
            svg {
              width: 1rem;
              height: 1rem;
            }

            font-size: 0.625rem;
            width: 7rem;
          }
        }

        .content-content {
          height: 10rem;

          .item {
            @include fonts.body3;
            padding: 0.25rem 1rem;
          }
        }
      }
    }

    .management-columns-modal {
      padding: 1rem;
      display: flex;
      flex-direction: column;

      h2 {
        @include fonts.h4;
        border-bottom: 1px solid colors.$text-200;
        padding-bottom: 1rem;
        text-align: center
      }

      p {
        @include fonts.body3;
        padding: 0.5rem 0;
      }

      .validated-input-message-error {
        padding: 0.25rem 0;
      }

      .form-row {
        padding: 0.5rem 0;
        justify-content: flex-start;
        gap: 0.5rem;

        label {
          @include fonts.body3;
        }
      }

      .submit-button {
        justify-content: space-evenly;
        margin-top: 1rem;
      }
    }
  }
}