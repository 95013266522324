@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.inventory-management {
  .submit-button {
    display: flex;
    justify-content: flex-end;
  }

  .transaction-type-header {
    h2 {
      border: none;
      margin: 0;
    }
  }

  .radio-group {
    display: flex;

    div {
      display: flex;
      align-items: center;
    }

    gap: 1rem;
    padding-right: 2rem;
  }

  .animal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .add-animal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    @include screenSizes.mobile {
      font-size: 0.75rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .select-storage-items {
    margin-bottom: 1rem;

    .select-storage-item {
      @include elevations.elevation-1;
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.5rem;
    }

    .select-storage-item-row {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.5rem;
      width: 95%;
    }

    .select-column {
      margin-right: 2rem;
      width: 22%;
    }

    .trash-can {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      color: colors.$secondary-red-300;
    }

    .trash-can:hover {
      cursor: pointer;
    }

    @include screenSizes.mobile {
      margin-bottom: 0rem;

      .select-storage-item {
        .select-storage-item-row {
          flex-direction: column;
          margin-bottom: 0rem;
          padding: 0rem;
          width: 70%;

          .select-column {
            margin-right: 0rem;
            margin-bottom: 1rem;
            width: 100%;

            label {
              @include fonts.caption2;
            }
          }
        }

        .trash-can {
          width: 30%;
        }
      }

      .xs-add-location {
        @include fonts.caption2;
        width: auto;
      }
    }

    @include screenSizes.tablet {
      .select-storage-item-row {
        .select-column {

          .form-field,
          .validated-input input {
            font-size: 0.5625rem;
            line-height: 0.78063rem;
          }
        }
      }
    }
  }
}

.inventory-management-canister-contents {
  padding: 1rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-top: 1px solid colors.$surface-500;
    border-bottom: 1px solid colors.$surface-500;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;

    h1 {
      @include fonts.h5;
      width: fit-content;
      padding-right: 1rem;
      border-right: 1px solid colors.$surface-500;
    }

    .select-storage-items {
      display: flex;
      align-items: center;

      .form-row {
        flex-wrap: nowrap;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0 0.5rem;

        .input-container {
          min-width: 12rem;
          padding-left: 0.5rem;
        }
      }
    }
  }

  .specimen-info-modal {
    padding: 0.5rem;
    min-height: 20rem;

    .card.inventory-action {
      padding: 1rem;
      padding-top: 0;
      margin: 0;
    }
  }

  .reconcile-modal {
    padding: 0.25rem 0.5rem;
  }

  .message-modal {
    .header {
      padding: 1rem;
      border-bottom: 1px solid colors.$surface-500;
      background-color: colors.$surface-100;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;

      h4 {
        @include fonts.h4;
      }
    }

    @include screenSizes.mobile {
      .header {
        text-align: center;
        padding: 1rem;
        border-bottom: 1px solid colors.$black;

        h4 {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }
  }

  .inventory-counts {
    display: flex;
    flex-direction: column;

    .tank-count,
    .canister-count {
      @include fonts.body1;
      margin-top: 0.5rem;
    }
  }

  .content {
    margin-top: 1rem;

    .sorted-table {
      border-radius: 0;

      table {
        thead tr {
          background-color: colors.$white;
        }

        tr {
          border-bottom: 1px solid colors.$surface-500;
        }

        tr th::before,
        tr td::before {
          top: 0;
          bottom: 0;
          border-color: colors.$surface-500;
        }
      }
    }

    .sorted-table table tr th,
    .sorted-table table tr td {
      text-align: left;

      &:last-child {
        text-align: center;
      }
    }

    .sorted-table table tr th {
      padding: 1rem 0.75rem;
    }

    .sorted-table table tr td {
      padding: 0.5rem 0.75rem;
    }
  }

  .xs-content {
    display: none;
  }

  @include screenSizes.tablet {
    .header {
      h1 {
        @include fonts.h7;
      }

      .select-storage-items {
        .form-row {
          .input-container {
            min-width: 8rem;

            .filter-dropdown {
              @include fonts.caption1;
              font-size: 0.625rem
            }
          }

          label {
            @include fonts.caption1;
            font-size: 0.75rem
          }
        }
      }
    }

    .inventory-counts {

      .tank-count,
      .canister-count {
        @include fonts.caption1;
      }
    }

    .content {
      .sorted-table {
        table {
          thead tr th {
            font-size: 0.75rem;
          }
        }
      }

      .sorted-table table tr td {
        font-size: 0.75rem;
      }
    }
  }

  @include screenSizes.mobile {
    .header {
      display: block;
      border-top: 1px solid colors.$surface-500;
      border-bottom: none;
      margin-bottom: 0;

      h1 {
        @include fonts.h7;
        width: auto;
        border-right: none;
        border-bottom: 1px solid colors.$surface-500;
        padding: 0.5rem 0;
        margin-bottom: 0.5rem;
      }

      .select-storage-items {
        display: block;
        border-bottom: 1px solid colors.$surface-500;

        .form-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          padding: 0.5rem 0;

          &:last-child {
            margin-bottom: 0.5rem;
          }

          .input-container {
            max-width: 10.5rem;
            min-width: auto;
          }
        }
      }
    }

    .inventory-counts {
      align-items: end;

      .tank-count,
      .canister-count {
        @include fonts.caption2;
        font-size: 0.75rem;
        margin-bottom: 1rem;
      }
    }

    .reconcile-modal {
      margin: 0rem 1rem;

      h2 {
        margin-top: 0;
        padding: 1rem;
        @include fonts.h4;
        text-align: center;
      }

      .flex-right {
        justify-content: center;
        margin: 1.5rem;
        gap: 1.5rem;
      }
    }

    .content {
      display: none;
    }

    .xs-content {
      display: block;
      margin-top: 0.5rem;

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0.5rem 0rem 0rem;
        }
      }

      .canister-inventory {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .canister-inventory-content {
          flex: 3;
          border-right: 1px solid colors.$text-200;

          .animal-code {
            border-bottom: 1px solid colors.$text-200;
            padding: 0.5rem 1rem;
          }

          .animal-info {
            border-bottom: 1px solid colors.$text-200;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.5rem 1rem;

            .animal-lot-date {
              border-right: 1px solid colors.$text-200;
              padding-right: 1rem;
              width: 50%;
            }

            .quantity {
              width: 50%;
              padding-left: 1rem;
            }
          }
        }

        .action-button {
          flex: 1;
          text-align: center;
        }

        label {
          @include fonts.caption1;
          font-size: 0.75rem;
        }
      }
    }
  }
}

.inventory-management-variances {
  padding: 2rem;

  .header {
    hr {
      border-color: colors.$surface-500;
    }

    margin-bottom: 1rem;
  }

  .xs-inventory-management-variances {
    display: none;
  }

  @include screenSizes.tablet {
    .header h1 {
      @include fonts.h5;
    }

    .desk-inventory-management-variances {
      .sorted-table {
        table {
          thead {
            tr th {
              @include fonts.caption2-semibold;
            }
          }

          tbody {
            tr td {
              @include fonts.caption2;
            }
          }
        }
      }
    }
  }

  @include screenSizes.mobile {
    .header h1 {
      @include fonts.h7;
    }

    .desk-inventory-management-variances {
      display: none;
    }

    .xs-inventory-management-variances {
      display: block;

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0.5rem 0rem 0rem;
        }

        .inventory-variances {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .inventory-variances-content {
            flex: 3;
            border-right: 1px solid colors.$text-200;

            .card-content-section {
              display: flex;
              border-bottom: 1px solid colors.$text-200;
              padding: 0.5rem;

              .left {
                border-right: 1px solid colors.$text-200;
                padding-right: 0.5rem;
                flex: 1;
              }

              .right {
                padding-left: 0.5rem;
                flex: 1;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }

          .reconcile-link {
            flex: 1;
            text-align: center;
            @include fonts.caption1;
            font-size: 0.75rem;
          }

          label {
            @include fonts.caption1;
            font-size: 0.71rem;
          }
        }
      }
    }
  }
}

.inventory-management-reconcile {
  .specimen-info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .specimen-info {
      margin-bottom: 0.5rem;

      label {
        @include fonts.h5;
      }

      span {
        @include fonts.subheader2;
      }
    }

    .variance {
      @include fonts.h5;
      text-align: center;
      width: fit-content;

      div {
        padding: 0.5rem;
        background-color: colors.$surface-500;
      }
    }
  }

  .equations {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    &>div {
      min-width: 10rem;
      max-width: 25rem;
    }

    .verify-equation {
      text-align: center;
      margin: auto;
      border-collapse: collapse;

      th,
      td {
        @include fonts.body1;
        line-height: 1.25rem;
      }

      .operation {
        position: relative;
        background-color: transparent;
        @include fonts.h5;
        line-height: 1rem;

        .slash {
          color: colors.$secondary-red-300;
          @include fonts.body1-semibold;
          position: absolute;
          bottom: -0.25rem;
          left: 0.15rem;
        }
      }
    }

    .validated-input-message-error {
      gap: 0.5rem;

      div {
        width: 1rem;
        height: 1rem;
        align-self: baseline;
      }
    }

    .details {
      @include fonts.body3;
    }
  }

  @include screenSizes.tablet {
    .specimen-info-header {
      .specimen-info {
        label {
          @include fonts.h5;
        }

        span {
          @include fonts.subheader2;
          font-size: 1rem;
        }
      }

      .variance {
        label {
          @include fonts.h6;
        }
      }
    }

    .equations {
      justify-content: center;
      text-align: center;

      &>div {
        max-width: 100%;
      }

      .verify-equation {
        text-align: center;
        margin: auto;
        border-collapse: collapse;

        th,
        td {
          @include fonts.body2;
        }

        .operation {
          @include fonts.h6;

          .slash {
            @include fonts.body2-semibold;
            bottom: -0.05rem;
            left: 0.10rem;
          }
        }
      }

      .details {
        @include fonts.body3;
      }
    }
  }

  @include screenSizes.mobile {
    .specimen-info-header {
      gap: 1rem;

      .specimen-info {
        label {
          @include fonts.h7;
        }

        span {
          @include fonts.caption1;
        }
      }

      .variance {
        @include fonts.h7;
      }
    }

    .flex-right {
      button {
        border-radius: 1.5rem;
        @include fonts.button3;
      }
    }

    .equations {
      justify-content: center;
      text-align: center;

      &>div {
        max-width: 100%;
      }

      .verify-equation {
        text-align: center;
        margin: auto;
        border-collapse: collapse;

        th,
        td {
          @include fonts.body3;
          font-size: 0.75rem;
        }

        .operation {
          @include fonts.h6;

          .slash {
            @include fonts.body3-semibold;
            bottom: 0.10rem;
            left: 0.10rem;
          }
        }
      }

      .validated-input-message-error {
        gap: 0.25rem;
      }

      .details {
        @include fonts.caption2;
      }
    }
  }
}

.modal-p-side-margin {
  margin: 0 1rem;
}

.inventory-management-clinic-use {
  .clinic-use-select-item {
    .desk-select-item {
      display: block;

      @include screenSizes.mobile {
        display: none;
      }
    }

    .xs-select-item {
      display: none;

      @include screenSizes.mobile {
        display: block;

        .form-row {
          max-width: 100%;

          .no-select-specimen {
            @include fonts.caption2;
            font-size: 0.75rem;
            display: block;
            text-align: center;
          }

          .wide-input-container {
            max-width: 100%;
          }
        }

        .selected-specimen {
          .card {
            padding: 0.5rem 0rem;

            &.max-width {
              margin: 1rem 0.5rem 0rem 0rem;
            }

            .body {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              padding: 0rem 0.5rem;

              .selected-account,
              .available-quantity,
              .owned-quantity {
                width: 25%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                border-right: 1px solid colors.$text-200;
                padding: 0.25rem;
              }

              .actual-quantity {
                width: 25%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding: 0.25rem;

                input {
                  max-width: 4.5rem;
                  text-align: center;
                  padding: 0.25rem;
                  border-radius: 0rem;
                  @include fonts.caption2;
                  font-size: 0.75rem
                }

              }

              label {
                @include fonts.caption2;
              }
            }
          }
        }

        .total-quantity {
          display: flex;
          justify-content: flex-end;
          margin: 1rem;
          @include fonts.body3;
          padding-right: 2rem;

          label {
            @include fonts.body3;
            font-weight: 600;
          }
        }
      }
    }
  }

  .clinic-use-storage-locations {
    .desk-storage-locations-table {
      display: block;

      @include screenSizes.mobile {
        display: none;
      }
    }

    .xs-storage-locations-table {
      display: none;

      @include screenSizes.mobile {
        display: block;

        .selected-storage-location {
          .card {
            padding: 0.5rem 0rem;

            &.max-width {
              margin: 1rem 0.5rem 0rem 0rem;
            }

            .body {
              .body-content {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                padding: 0rem 0.5rem;

                .selected-location,
                .canister-qty {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  border-right: 1px solid colors.$text-200;
                  padding: 0.5rem;
                }

                .location-item {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding-left: 1rem;
                }

                .actual-quantity {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding-left: 1rem;

                  input {
                    max-width: 4.5rem;
                    text-align: center;
                    padding: 0.25rem;
                    border-radius: 0rem;
                    @include fonts.caption2;
                    font-size: 0.75rem
                  }
                }
              }

              hr {
                border: none;
                border-bottom: 1px solid colors.$text-200;
              }

              label {
                @include fonts.caption2;
              }
            }
          }

          .disabled {
            label {
              color: colors.$text-200;
            }
          }
        }

        .storage-total {
          display: flex;
          justify-content: flex-end;
          margin: 1rem;
          @include fonts.body3;
          padding-right: 2rem;

          label {
            @include fonts.body3;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.inventory-management-threshold {
  .search-bar {
    max-width: calc(50% - 1rem);

    .search-input-wrapper {
      width: 70%;
    }
  }

  .sorted-table {
    overflow: hidden;
    border-radius: 0.5rem;
    @include elevations.elevation-1;

    table {
      width: 100%;
      border-collapse: collapse;
      border: none;
      table-layout: fixed;
      background-color: colors.$surface-300;

      thead,
      tbody tr,
      tfoot tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      thead {
        z-index: 1;
        width: calc(100% - 0.5rem);

        tr {
          background-color: colors.$surface-300;

          th {
            @include fonts.body2-semibold;
            color: colors.$text-600;
            position: sticky;
            top: 0;

            &.sortable {
              cursor: pointer;
            }

            .sort-arrow {
              padding: 0.25rem;

              svg path {
                fill: colors.$icon-200;
              }

              &.sorting {
                svg path {
                  fill: colors.$icon-300;
                }
              }

              &.reverse {
                svg {
                  transform: scaleY(-1);
                }
              }
            }
          }
        }
      }

      tbody {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-gutter: stable;
        max-height: 22.5rem;

        &::-webkit-scrollbar {
          width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
          background: colors.$surface-300;
        }

        &::-webkit-scrollbar-thumb {
          background: colors.$text-300;
          border-radius: 0.25rem;
        }
      }

      tfoot {
        tr {
          td {
            position: sticky;
            bottom: 0;
            border: none;

            &::before {
              border-left: none;
            }
          }
        }
      }

      tr {
        background-color: colors.$surface-200;

        &:nth-child(2n) {
          background-color: colors.$surface-100;
        }

        th,
        td {
          position: relative;
          padding: 1rem 0;
          text-align: center;
          overflow-wrap: break-word;

          &:first-child::before {
            display: none;
          }

          &::before {
            position: absolute;
            display: block;
            content: '';
            border-left: 1px solid colors.$text-600;
            background: black;
            top: 0.5rem;
            bottom: 0.5rem;
            left: -0.5px;
          }
        }

        thead {
          width: calc(100% - 0.5rem);
          padding: 0 0.5rem;
        }
      }
    }

    @include screenSizes.mobile {
      table {
        thead {
          tr th {
            @include fonts.caption1-semibold;
            font-size: 0.75rem;
            padding: 0.5rem;
          }
        }

        tbody {
          max-height: 14rem;

          tr td {
            @include fonts.caption2;
            padding: 0.5rem;
          }
        }
      }
    }

    @include screenSizes.tablet {
      table {
        thead {
          tr th {
            @include fonts.caption1-semibold;
            font-size: 0.75rem;
            padding: 1rem 0.5rem;
          }
        }

        tbody {
          max-height: 21rem;

          tr td {
            @include fonts.caption1;
            padding: 1rem 0.5rem;
            font-size: 0.75rem;

            svg {
              width: 0.75rem;
              height: 0.75rem;
            }
          }
        }
      }
    }
  }

  @include screenSizes.tablet {
    .search-bar {
      max-width: calc(70% - 1rem);
    }
  }

  @include screenSizes.mobile {
    .search-bar {
      max-width: calc(100% - 1rem);
    }
  }
}