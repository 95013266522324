$white: #ffffff;
$black: #000000;
$gray: #cccccc;

$text-100: #ffffff;
$text-150: #dddddd;
$text-200: #aaacad;
$text-300: #7f8182;
$text-400: #545556;
$text-500: #2e3133;
$text-600: #272829;
$text-display: #0f9b00;

$icon-100: #ffffff;
$icon-200: #adabaa;
$icon-300: #545556;
$icon-400: #1379c1;

$primary-green-100: #e2eedd;
$primary-green-200: #cee1c4;
$primary-green-300: #b9d5ac;
$primary-green-400: #a5c893;
$primary-green-500: #91bc7b;

$primary-blue-100: #8dbee1;
$primary-blue-200: #6ca1c7;
$primary-blue-300: #4a85ae;
$primary-blue-400: #1379c1;
$primary-blue-500: #074b7b;

$secondary-red-100: #f0bcbf;
$secondary-red-200: #df878c;
$secondary-red-300: #b0131d;
$secondary-red-400: #860009;
$secondary-red-500: #780008;

$surface-100: #ffffff;
$surface-200: #f6f7f8;
$surface-300: #ebeced;
$surface-400: #e0e1e2;
$surface-500: #dcddde;
$surface-600: #bfbfbf;

$info-100: #eaf1fa;
$info-200: #c8ddf4;
$info-300: #a7c9ef;
$info-400: #94bdeb;

$warning-100: #faf5ea;
$warning-200: #fae7c8;
$warning-300: #efd9a7;
$warning-400: #ebd194;

$alert-100: #faebea;
$alert-200: #f4cbc8;
$alert-300: #efaca7;
$alert-400: #eb9a94;

$success-100: #effaea;
$success-200: #d5f4c8;
$success-300: #bee7ad;
$success-400: #ade096;

$filter-turquoise: #23b5b5;
$powder-blue: #F2FBFB;