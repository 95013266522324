@use '../../scss/elevations' as elevations;
@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.animal-info {
  display: flex;
  flex-direction: column;

  .animal-header-info {
    display: flex;
    flex-direction: column;
    margin-left: 29%;
    padding: 0 1rem;

    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .animal-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-right: 0;
    $image-width: 12rem;
    gap: 1rem;

    .specimen-image {
      min-width: 28%;
      max-width: 35%;
      height: 11rem;
      padding-top: 13rem;
      background-color: colors.$surface-500;
      background-image: url('../../../public/images/rsg-horizontal-full-color.png');
      background-repeat: no-repeat;
      background-position: 50% 45%;
      text-align: center;
      border-radius: 0.5rem;
    }

    .animal-photos {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 28%;
      max-width: 35%;
      gap: 1rem;

      img {
        height: 22.5rem;
        object-fit: cover;
        border-radius: 0.5rem;
        width: 100%;
      }

      .animal-photos-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
        gap: 1rem;

        img {
          width: 5rem;
          height: 5rem;
          aspect-ratio: 1;
          border-radius: 0.5rem;
          object-fit: cover;
          background-color: colors.$surface-500;
        }
      }
    }

    .specimen-info {
      display: flex;
      flex-direction: column;
      padding: 0 1rem;
      min-height: 24rem;
      min-width: 65%;
      justify-content: space-between;

      .name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          margin: 0;
        }
      }

      hr {
        margin: 0;
      }

      .stats {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 1rem;

        h3 {
          margin-bottom: 0;
        }
      }

      .description {
        padding: 1rem 0;

        h3 {
          margin-bottom: 0;
        }
      }

      .quantity {
        h3 {
          display: inline-block;
        }
      }

      .action-buttons {
        padding-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
      }
    }
  }

  @include screenSizes.tablet {
    .animal-header-info {
      margin: 0;
      padding: 0;

      .name {
        h2 {
          @include fonts.h6;
        }

        div {
          @include fonts.body3;
        }
      }
    }

    .animal-banner {
      display: flex;
      padding: 0.5rem;
      gap: 0.5rem;

      .animal-photos,
      .specimen-image {
        min-width: 35%;
        max-width: 100%;
      }

      .specimen-info {
        display: flex;
        flex-direction: column;
        min-width: 60%;
        width: 65%;

        .name {
          display: flex;
          flex-wrap: wrap;

          h2 {
            @include fonts.h6;
          }

          div {
            @include fonts.body3;
          }
        }

        .stats {
          gap: 0.3rem;
          display: flex;
          flex-wrap: wrap;

          h3 {
            @include fonts.body3-semibold;
          }

          div {
            @include fonts.body3;
          }
        }

        .description {
          display: flex;
          flex-wrap: wrap;

          h3 {
            @include fonts.body3-semibold;
          }

          div {
            @include fonts.body3;
          }
        }

        .action-buttons {
          gap: 1rem;
        }
      }
    }
  }

  @include screenSizes.mobile {
    .animal-header-info {
      margin: 0;
      padding: 0 1rem;

      .name {
        padding-bottom: 0.3rem;

        h2 {
          @include fonts.h7;
        }

        div {
          @include fonts.caption1;
          font-size: 0.75rem;
        }
      }
    }

    .animal-banner {
      flex-direction: column;
      padding: 1rem;

      .animal-photos,
      .specimen-image {
        max-width: 100%;
      }

      .specimen-info {
        padding: 0;
        width: 100%;
        min-width: 30%;
        justify-content: space-around;

        .name {
          display: flex;
          flex-wrap: wrap;

          h2 {
            @include fonts.h7;
          }

          div {
            @include fonts.caption1;
            font-size: 0.75rem;
          }
        }

        .stats {
          gap: 0.5rem;

          h3 {
            @include fonts.body3-semibold;
          }

          div {
            @include fonts.caption1;
            font-size: 0.75rem;
          }
        }

        .description {
          @include fonts.caption1;
          font-size: 0.75rem;

          h3 {
            @include fonts.body3-semibold;
          }
        }

        .quantity {
          h3 {
            @include fonts.body3-semibold;
          }
        }

        .action-buttons {
          button {
            width: 100%;
            padding: 0.5rem;
          }
        }
      }
    }
  }
}