@use './scss/colors' as colors;
@use './scss/fonts' as fonts;
@use './scss/elevations' as elevations;
@use './scss/screenSizes' as screenSizes;

.app {
  min-height: 100vh;
  background-color: colors.$surface-200;
  $title-height: 5.5rem;

  .app-header {
    height: $title-height;
    display: flex;
  }

  .show-hide-menu-button {
    display: none;
  }

  .app-body {
    min-height: calc(100vh - $title-height);
    display: flex;

    .app-content {
      position: relative;
      width: 100%;
      background-color: colors.$surface-200;
    }
  }

  @include screenSizes.mobile {
    .app-header {
      height: 4.5rem;

      .show-hide-menu-button {
        display: block;
        z-index: inherit;
        position: relative;
        top: 0;
        background-color: colors.$white;
        padding: 1.8rem 1.4rem;
        width: 2.8rem;
        align-self: center;
      }
    }
  }
}

input,
textarea,
select,
.search-dropdown input,
.filter-dropdown {
  @include fonts.body3;
  padding: 1rem;
  width: calc(100% - 2rem - 2px);
  color: colors.$text-400;
  background-color: colors.$surface-100;
  border: 1px solid colors.$surface-500;
  border-radius: 0.5rem; //8px

  &[type='checkbox'] {
    padding: 0;
    width: 1rem; //16px
    height: 1rem; //16px
  }

  &[type='radio'] {
    margin: 0.25rem;
    margin-left: 0;
    padding: 0;
    width: 1.375rem; //22px
    height: 1.375rem; //22px
  }

  @include screenSizes.tablet {
    @include fonts.body3;
    padding: 0.8rem;

    &[type='radio'] {
      width: 1rem;
      height: 1rem;
    }
  }

  @include screenSizes.mobile {
    @include fonts.caption2;
    padding: 0.75rem;
  }
}

.account-search {
  .results {
    min-height: 0rem;
    max-height: 14.65rem;
    overflow-y: auto;
  }

  @include screenSizes.mobile {
    .results {
      max-height: 12.65rem;
    }
  }
}

.search-dropdown {
  position: relative;
  width: 100%;

  .search-input-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    input {
      padding-right: 2rem;
      max-width: calc(100% - 3rem - 2px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input.selected::placeholder {
      color: colors.$text-600;
    }

    @include screenSizes.mobile {
      input {
        padding-right: 1.75rem;
      }
    }
  }

  .search-input-wrapper .delete-icon {
    position: absolute;
    right: 0.5rem;
    top: 35%;
    width: 1rem;
    height: 1rem;
    background-image: url('../public/images/cancel.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .search-input-wrapper .show-icon {
    position: absolute;
    right: 0.5rem;
    top: 35%;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url('../public/images/search.svg');
    background-size: contain;
    pointer-events: none;
  }

  .results {
    @include fonts.body3;
    @include elevations.elevation-1;
    min-width: 100%;
    position: absolute;
    border: solid 1px colors.$surface-500;
    background-color: colors.$surface-100;
    border-radius: 0.5rem; //8px
    z-index: 2;

    .result-item {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      border-top: solid 1px colors.$surface-500;
      cursor: pointer;

      &:first-child {
        border-top: none;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      &:hover {
        @include elevations.elevation-2;
      }

      &:active {
        @include elevations.elevation-1;
      }

      &.account-warning {
        opacity: 0.7;
        background-color: colors.$warning-100;
      }
    }

    .result-item-image {
      width: 1.875rem; //30px
      height: 1.875rem; //30px
      margin-right: 0.5rem;
      background-color: colors.$surface-300;
      border-radius: 10rem;
    }
  }

  @include screenSizes.mobile {

    .search-input-wrapper .delete-icon,
    .search-input-wrapper .show-icon {
      right: 1rem;
      width: 0.75rem;
      height: 0.75rem;
    }

    .results {
      @include fonts.caption2;
      min-width: 95%;

      .result-item {
        padding: 0.3rem 0.5rem;
        color: colors.$text-300;
      }
    }
  }

  @include screenSizes.tablet {

    .search-input-wrapper .delete-icon,
    .search-input-wrapper .show-icon {
      right: 1rem;
      width: 0.85rem;
      height: 0.85rem;
    }

    .results {
      @include fonts.caption1;
      font-size: 0.75rem;
      min-width: 96%;

      .result-item {
        color: colors.$text-300;
      }
    }
  }
}

textarea {
  min-height: 3rem;
  min-width: calc(100% - 2rem - 2px);
}

select {
  width: 100%;
}

.radio-row {
  display: flex;
  align-items: center;

  label {
    padding-right: 1rem;
  }
}

label,
pre {
  @include fonts.body2;
  color: colors.$text-600;

  @include screenSizes.mobile {
    @include fonts.caption1;
    font-weight: 500;
  }
}

button {
  background-color: transparent;
  border: none;
  margin: 0;
}

.button {
  display: block;
  @include fonts.body2-semibold;
  background: transparent;
  border-radius: 100rem;
  border: none;
  padding: 0.5rem;
  width: 13.5rem;
  cursor: pointer;

  &.outlined {
    @include fonts.body2;
    padding: 0.25rem 0.5rem;
    width: fit-content;
    border: 1px dashed colors.$text-600;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
  }

  &.icon {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
  }

  &.blue {
    background-color: colors.$primary-blue-500;
    color: colors.$surface-100;

    &.inverted {
      background-color: transparent;
      color: colors.$primary-blue-500;
      border: 1px solid colors.$primary-blue-500;
    }
  }

  &.green {
    background-color: colors.$primary-green-500;
    color: colors.$text-600;

    &.inverted {
      background-color: transparent;
      color: colors.$primary-green-400;
      border: 1px solid colors.$primary-green-400;
    }
  }

  &.red {
    background-color: colors.$secondary-red-400;
    color: colors.$white;

    &.inverted {
      background-color: transparent;
      color: colors.$secondary-red-500;
      border: 1px solid colors.$secondary-red-500;
    }
  }

  &.white {
    background-color: colors.$surface-100;
    color: colors.$black;
    border: 1px solid colors.$surface-500;

    &.inverted {
      background-color: transparent;
      color: colors.$black;
      border: 1px solid colors.$surface-100;
    }

    &.active {
      background-color: colors.$surface-400;
      color: colors.$black;
      border: 1px solid colors.$surface-500;
    }
  }

  &.small {
    width: 7rem;
  }

  &.medium {
    width: 10rem;
  }

  &:hover {
    @include elevations.elevation-3;
  }

  &:active {
    @include elevations.elevation-1;
  }

  &.disabled,
  &[disabled] {
    opacity: 0.8;

    &:hover {
      @include elevations.elevation-0;
    }
  }

  @include screenSizes.mobile {
    @include fonts.button3;

    &.small {
      width: 6rem;
    }
  }
}

.search-bar {
  position: relative;
  display: flex;
  align-items: center;
  background: colors.$surface-400;
  height: 2.5rem; //40px
  border-radius: 10rem;
  $padding: 1rem;
  padding-right: $padding;
  margin: 0.5rem 0;
  width: calc(100% - $padding);

  &::before {
    display: block;
    content: ' ';
    background-image: url('../public/images/search.svg');
    background-repeat: no-repeat;
    background-size: 1.25rem; //20px
    background-position: 50% 50%;
    height: 100%;
    width: 3rem;
    padding-left: 1rem;
    position: relative;
    left: 0;
    top: 0;
  }

  input {
    @include fonts.body2;
    border: none;
    height: calc(100% - 2px);
    padding: 0;
    width: 100%;
    background: transparent;
    color: colors.$text-600;

    &:focus {
      outline: none;
    }
  }

  @include screenSizes.tablet {
    input {
      @include fonts.body3;
    }
  }

  @include screenSizes.mobile {
    height: 2rem;
    border-radius: 0.75rem;

    &::before {
      background-size: 1rem;
    }

    input {
      @include fonts.body3;
      font-size: 0.75rem;
    }
  }
}

.card {
  display: block;
  position: relative;
  background-color: colors.$surface-100;
  $card-border-radius: 0.3125rem; //5px
  border-radius: $card-border-radius;
  @include elevations.elevation-1;
  $card-padding: 1rem;
  padding: $card-padding;
  margin: 1rem;

  &.inventory-action {
    @include elevations.elevation-0;
    $inventory-card-padding: 2.5rem;
    padding: $inventory-card-padding;

    h1 {
      display: block;
      position: relative;
      @include fonts.h2;
      background-color: colors.$info-200;
      padding: 0.75rem $inventory-card-padding;
      margin: -$inventory-card-padding;
      margin-bottom: $inventory-card-padding;
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
    }

    h2 {
      @include fonts.h4;
      color: colors.$text-600;
      line-height: 2rem;
      border-bottom: 1px solid colors.$surface-600;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  .image-header {
    $image-width: 9.375rem; //150px

    position: relative;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    margin: -$card-padding;
    margin-bottom: $image-width / 2;
    height: 10.25rem; //164px
    background-color: colors.$surface-500;

    img {
      position: relative;
      width: $image-width;
      height: $image-width;
      border-radius: $image-width;
      left: calc(50% - $image-width / 2);
      top: calc(100% - $image-width / 2);
    }

    @include screenSizes.mobile {
      $image-width: 6rem;

      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
      margin-bottom: $image-width / 2;

      img {
        width: $image-width;
        height: $image-width;
        border-radius: $image-width;
        left: calc(50% - $image-width / 2);
        top: calc(100% - $image-width / 2);
      }
    }
  }

  @include screenSizes.tablet {
    &.inventory-action {
      $inventory-card-padding: 2rem;
      padding: $inventory-card-padding;

      h1 {
        @include fonts.h5;
        padding: 0.75rem $inventory-card-padding;
        margin: -$inventory-card-padding;
        margin-bottom: $inventory-card-padding;
      }

      h2 {
        @include fonts.h5;
      }
    }
  }

  @include screenSizes.mobile {
    margin: 1rem;

    &.inventory-action {
      $inventory-card-padding: 1.5rem;
      padding: $inventory-card-padding;

      h1 {
        @include fonts.h7;
        padding: 0.75rem $inventory-card-padding;
        margin: -$inventory-card-padding;
        margin-bottom: $inventory-card-padding;
      }

      h2 {
        @include fonts.h7;
        line-height: 1.25rem;
      }
    }
  }
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 0;
  max-width: 44rem;

  label {
    @include fonts.subheader2;
    color: colors.$text-600;
  }

  .input-container,
  .validated-input,
  .radio-row,
  .date-input {
    width: 100%;
    max-width: 16.5rem;
  }

  .validated-input-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .validated-input-message-error {
    width: 100%;
  }

  &.expanded-text-area {
    max-width: 62rem;
    flex-wrap: nowrap;
    align-items: flex-start;

    label {
      width: 100%;
      max-width: 27.5rem;
    }

    textarea,
    pre {
      width: calc(100% - 20rem);
      min-width: calc(14.5rem - 2px);
      resize: vertical;
      white-space: pre-wrap;
    }

    pre {
      min-width: calc(16.5rem - 2px);
    }
  }

  .radio-row {
    label {
      @include fonts.body2;
    }
  }

  @include screenSizes.tablet {
    max-width: 34rem;

    label {
      @include fonts.body2;
    }

    .input-container,
    .validated-input,
    .radio-row,
    .date-input {
      max-width: 14.5rem;
    }

    &.expanded-text-area {
      max-width: 42rem;

      label {
        max-width: 19.5rem;
      }

      textarea,
      pre {
        width: calc(100% - 20rem);
        min-width: calc(14.5rem - 2px);
      }
    }
  }

  @include screenSizes.mobile {
    max-width: 30rem;

    label {
      @include fonts.caption2;
      font-size: 0.75rem;
    }

    .input-container,
    .validated-input,
    .radio-row,
    .date-input {
      max-width: 10.5rem;
    }

    &.expanded-text-area {
      max-width: 30rem;
      flex-wrap: wrap;

      label {
        width: 100%;
        max-width: fit-content;
      }

      textarea,
      pre {
        min-width: calc(10.5rem - 2px);
      }
    }

    .radio-row {
      label {
        @include fonts.caption2;
        font-size: 0.75rem;
      }
    }
  }
}

.loading-content {
  animation: loading-animation 2s linear infinite;
  background: linear-gradient(to right, colors.$surface-300 8%, colors.$surface-400 18%, colors.$surface-300 33%);
  background-size: 800px 104px;
  position: relative;
}

.info-search-header {
  padding: 1rem;
  color: colors.$text-600;

  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    $border: 1px solid colors.$surface-500;
    border-bottom: $border;
    border-top: $border;

    .header-section {
      h1 {
        color: colors.$text-600;
        @include fonts.h5;
      }

      display: flex;
      align-items: center;
      color: colors.$text-300;
      @include fonts.body2;

      .section {
        margin-right: 1rem;
        padding: 0.5rem 1rem 0.5rem 0;
        border-right: $border;

        .bold-count {
          @include fonts.subheader2-medium;
          color: colors.$text-600;
          padding-right: 0.5rem;
        }
      }
    }
  }

  @include screenSizes.tablet {
    .header-row {
      .header-section {
        @include fonts.body3;

        h1 {
          @include fonts.h6;
          font-size: 1.125rem;
        }

        .section {
          .bold-count {
            @include fonts.h6;
            font-size: 1.125rem;
          }
        }
      }

      button {
        @include fonts.caption1;
      }
    }
  }

  @include screenSizes.mobile {
    .header-row {
      .header-section {
        h1 {
          @include fonts.h7;
        }

        @include fonts.body3;
        font-size: 0.75rem;

        .section {
          margin-right: 0.5rem;
          padding: 0.5rem 0.5rem 0.5rem 0;

          .bold-count {
            @include fonts.h7;
          }
        }
      }

      button {
        @include fonts.caption2;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .search-bar {
      align-items: inherit;
    }
  }
}

@keyframes loading-animation {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}

a {
  color: colors.$primary-blue-400;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
pre {
  margin: 0;
}

hr {
  border: 1px solid colors.$black;
  border-bottom: none;
}

//Screen Reader Only
.sr-only {
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}