@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.animal-overview {
  .animal-overview-ownership {
    margin: 1rem;

    h2 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      line-height: 2rem;
      border-bottom: 1px solid colors.$surface-600;
    }

    .no-ownership {
      text-align: center;
    }

    .xs-animal-overview-ownership {
      display: none;
    }

    .animal-ownership-table .sorted-table {
      table {
        tbody {
          tr td {
            word-break: break-all;
          }
        }
      }
    }
  }

  @include screenSizes.mobile {
    .animal-overview-ownership {
      h2 {
        @include fonts.h6;
      }

      .no-ownership {
        @include fonts.h7;
        font-size: 0.75rem;
      }

      .desk-animal-overview-ownership {
        display: none;
      }

      .xs-animal-overview-ownership {
        display: block;

        .card {
          padding: 0rem;

          &.max-width {
            margin: 1rem 0rem 0rem 0rem;
          }

          .animal-inventory-card {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .animal-inventory-content {
              width: 85%;
              border-right: 1px solid colors.$text-200;

              .card-content-section {
                display: flex;
                border-bottom: 1px solid colors.$text-200;
                padding: 0.5rem;

                .inventory-content {
                  border-right: 1px solid colors.$text-200;
                  padding: 0 0.5rem;
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  text-align: center;

                  &:last-child {
                    border-right: none;
                  }
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }

            .action-button {
              width: 15%;
              text-align: center;
            }

            label {
              @include fonts.caption1;
              font-size: 0.75rem;
            }
          }
        }
      }
    }

    .sorted-table {
      table {
        tbody {
          tr td {
            word-break: break-all;
          }
        }
      }
    }
  }

  @include screenSizes.tablet {
    .animal-overview-ownership {
      h2 {
        @include fonts.h5;
      }
    }

    .sorted-table {
      table {
        tbody {
          tr td {
            word-break: break-all;
          }
        }
      }
    }
  }
}