@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/elevations' as elevations;

.searchable-table {
  .scrollable {
    height: 65vh;
    overflow-y: auto;
    margin-top: 1rem;
  }

  table {
    @include fonts.body3;
    @include elevations.elevation-1;
    width: 100%;
    border-collapse: collapse;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;

    .sorted-table-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .sorted-column {
      background-color: colors.$surface-500;
    }

    tr th:first-child {
      border-top-left-radius: 1.5rem;
    }

    tr th:last-child {
      border-top-right-radius: 1.5rem;
    }

    td {
      background-color: colors.$surface-100;
      border-left: 1px solid colors.$surface-400;
      border-right: 1px solid colors.$surface-400;
      border-top: 0.75rem solid colors.$surface-100;
      border-bottom: 0.75rem solid colors.$surface-100;
    }

    tr td:first-child {
      border-left: 0;
    }

    tr td:last-child {
      border-right: 0;
    }

    th {
      @include fonts.body3-semibold;
      color: colors.$text-600;
      text-align: left;
      padding: 1rem;
      display: table-cell;
      background-color: colors.$surface-100;
    }

    thead {
      border-bottom: 1px solid colors.$surface-400;
    }
  }
}
