@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.filter-by-modal {
  @include screenSizes.mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .modal-content {
      margin: 1rem 2rem;

      .modal-header {
        @include fonts.h4;
        text-align: center;
        padding-bottom: 1rem;
        border-bottom: 1px solid colors.$text-200;
        text-decoration: none;
      }

      .reset-filters-button {
        padding: 1rem;
        gap: 0.25rem;

        label {
          color: colors.$primary-blue-400;
        }

        svg {
          width: 1rem;
          height: 1rem;
          fill: colors.$primary-blue-400;
        }
      }

      .modal-body {
        margin-top: 1rem;

        .button-row {
          .filters {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .filter {
              display: flex;
              flex-direction: column;
              gap: 0.25rem;
              width: 100%;

              .date-input{
                .react-datepicker-wrapper{
                  width: 100%;
                }
              }
            }
          }
        }

        .input {
          min-height: 5rem;

          label {
            display: block;
            @include fonts.body3;
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: center;
        height: 5rem;
        margin-top: 1rem;

        button {
          line-height: 1rem;
          margin: 1.25rem;

        }
      }
    }
  }
}