@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.desk-move-select-specimen {
    @include screenSizes.mobile {
        display: none;
    }
}

.xs-move-select-specimen {
    display: none;

    @include screenSizes.mobile {
        display: block;

        .no-inventory-found {
            @include fonts.caption2;
            font-size: 0.75rem;
            display: block;
            text-align: center;
        }

        .total-quantity {
            display: flex;
            justify-content: space-between;
            margin: 1rem;
            margin-right: 1.5rem;
            @include fonts.caption2;
            font-size: 0.75rem;

            label {
                @include fonts.body3;
            }
        }

        .select-specimen-container {
            .card {
                padding: 0.5rem 0rem;

                &.max-width {
                    margin: 1rem 0.5rem 0rem 0rem;
                }

                .select-specimen-row {
                    .body {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0rem 0.5rem;

                        .input-body {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 0.5rem;
                        }

                        label {
                            @include fonts.body3;
                        }
                    }
                }

                .selected-specimen-row {
                    display: flex;
                    flex-direction: row;
                    padding: 0rem 0.5rem;
                    gap: 0.5rem;

                    .input-check {
                        align-self: center;

                        input {
                            min-width: 1rem;
                            border-right: 1px solid colors.$text-200;
                        }
                    }

                    .row-content {
                        border-left: 1px solid colors.$text-200;
                        padding: 0rem 0.5rem;
                        flex: auto;

                        .header {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            label {
                                @include fonts.body3;
                            }
                        }

                        hr {
                            margin-top: 0.5rem;
                        }

                        .body {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            .spec-info {
                                display: flex;
                                flex-direction: column;
                            }

                            .input-body {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 0.25rem;

                                input {
                                    width: 3rem;
                                    text-align: center;
                                    border-radius: 0;
                                    padding: 0.35rem;
                                }
                            }

                            label {
                                @include fonts.caption2;
                            }
                        }
                    }
                }

            }
        }
    }
}