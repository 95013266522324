@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;
@use '../../scss//elevations' as elevations;

.customer-info {
  .xs-inventory-data {
    display: none;
  }

  .dashboard .animal-ownership {
    padding: 0;

    .sorted-table {
      table {
        tbody {
          tr td {
            word-break: break-all;
          }
        }
      }
    }
  }

  .deactivate-section-content {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    .section-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .lock-unlock-button {
        cursor: pointer;
      }
    }

    .payment-section {
      display: flex;

      .paid-button {
        width: 9.5rem;
      }
    }

    .notification-banner {
      padding: 0 1rem;
      justify-content: flex-end;

      .card {
        padding: 0.5rem;
      }

      .account-due-payment {
        display: flex;
        flex-wrap: wrap;

        p {
          @include fonts.body3;
        }
      }
    }
  }

  .team-members .sorted-table {
    table tr td {
      overflow-wrap: normal;
    }
  }

  .user-card {
    .user-card-name {
      overflow: hidden;
    }

    .email-address {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sorted-table {
    table tr td {
      padding: 1.1rem 0;
    }
  }

  .customer-payment-history .sorted-table {
    thead tr th {
      @include fonts.body2-semibold;
    }

    tbody tr td {
      @include fonts.body2;
    }
  }

  .desk-inventory-table {
    .action-tooltip {
      &-text {
        width: 5.6rem;

        &.left {
          top: -1.2rem;
          left: -5.7rem;

          &::after {
            top: 1.57rem;
            left: 6rem;
          }
        }

        .action-button {
          padding: 0.3rem;
        }
      }
    }

    .account-inventory-search {
      padding: 0.5rem 0;

      .search-input-wrapper {
        width: 23rem;
        padding: 0.5rem 0;

        input {
          padding: 0.7rem;
        }
      }
    }
  }

  .specimen-info-modal {
    padding: 0.5rem;
    min-height: 20rem;

    .card.inventory-action {
      padding: 2rem;
      padding-top: 0;
      margin: 0;
    }
  }

  @include screenSizes.tablet {
    .xs-inventory-data {
      display: none;
    }

    .info-search-header {
      .header-row .header-section .section {
        @include fonts.caption2;
        font-size: 0.75rem;

        h1,
        span {
          @include fonts.h7;
        }
      }
    }

    .full h2 {
      @include fonts.h6;
      padding: 0.75rem 0;
    }

    .team-members .sorted-table {
      table {
        thead tr {
          th:first-child {
            width: 70%;
          }

          th:nth-child(2) {
            width: 30%;
            text-align: center;
          }
        }

        tbody tr {
          td:first-child {
            width: 70%;
          }

          td:nth-child(2) {
            width: 30%;

            .user-role {
              text-align: center;
            }
          }
        }
      }
    }

    .desk-inventory-table {
      .account-inventory-search {
        .search-input-wrapper {
          padding: 0 0 0.5rem;
        }
      }
    }

    .deactivate-section-content {
      flex-direction: column;
      gap: 0.5rem;

      .payment-section {
        gap: 0.5rem;
        align-items: center;
        justify-content: space-between;

        .paid-button {
          width: 8.5rem;
        }
      }

      .notification-banner {
        padding: 0;

        .card {
          padding: 0.3rem;

          svg {
            width: 1.1rem;
            height: 1.1rem;
          }
        }

        .account-due-payment {
          p {
            @include fonts.caption1;
          }
        }
      }
    }

    .customer-payment-history {
      .sorted-table table {
        thead tr th {
          @include fonts.caption1-semibold;
          font-size: 0.75rem;
        }

        tbody tr td {
          padding: 1rem 0rem;
          @include fonts.caption1;
          font-size: 0.75rem;
        }
      }
    }

    .animal-ownership {
      .sorted-table table {
        tbody {
          max-height: 208px !important;
        }
      }
    }
  }

  @include screenSizes.mobile {
    .info-search-header {
      .header-row .header-section .section {
        display: flex;
        flex-wrap: wrap;
        @include fonts.caption2;
        font-size: 0.75rem;
        padding: 0.3rem 1rem 0.3rem 0;

        h1,
        span {
          @include fonts.h7;
        }
      }
    }

    .search-bar .account-search {
      input {
        @include fonts.caption2;
        font-size: 0.75rem;
      }
    }

    .deactivate-section-content {
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;

      .section-content {
        gap: 0.5rem;

        .deactivate-button {
          width: 10.5rem;
        }
      }

      .payment-section {
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        justify-content: space-between;

        .paid-button {
          width: 8rem;
        }
      }

      .notification-banner {
        padding: 0;

        .card {
          padding: 0.25rem;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        .account-due-payment {
          p {
            @include fonts.caption1;
            font-size: 0.75rem;
          }
        }
      }
    }

    .dashboard {
      .desk-inventory-table {
        display: none;
      }

      .full h2 {
        @include fonts.body3-semibold;
      }

      .team-members {
        .sorted-table {
          .user-card {
            img {
              width: 2rem;
              height: 2rem;
              margin: 0 1rem 0 0.5rem;
            }
          }

          table {
            thead tr {
              th:first-child {
                width: 80%;
                padding: 0.5rem 0.1rem;
              }

              th:nth-child(2) {
                width: 20%;
                text-align: center;
                padding: 0.5rem;
              }
            }

            tbody tr {
              td:first-child {
                width: 80%;
                padding: 0.5rem 0rem;
              }

              td:nth-child(2) {
                width: 20%;
                padding: 0.5rem;

                .user-role {
                  text-align: center;
                }
              }
            }
          }
        }
      }

      .animal-ownership {
        .sorted-table table {
          tbody {
            max-height: 130px !important;
          }

          tr td {
            padding: 0.5rem;
          }
        }
      }

      .xs-inventory-data {
        width: 100%;
        display: flex;
        flex-direction: column;

        .result-item {
          text-align: center;
          @include fonts.caption2;
        }

        .account-inventory-search {
          .search-input-wrapper {
            width: 100%;
            padding: 0.3rem 0 0.5rem;

            input {
              padding: 0.75rem 1.3rem 0.75rem 0.75rem;
            }
          }
        }

        .card {
          padding: 0;
          margin: 0.4rem 0 0.8rem 0;

          .inventory-item-card {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .inventory-card-content {
              width: 85%;
              border-right: 1px solid colors.$text-200;

              .inventory-info {
                display: flex;
                padding: 0.7rem 0;
                border-bottom: 1px solid colors.$text-200;

                .inventory-content {
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  align-items: center;
                  padding: 0 0.5rem;
                  text-align: center;
                  border-right: 1px solid colors.$text-200;

                  &:last-child {
                    border-right: none;
                  }

                  label {
                    @include fonts.body3;
                    font-size: 0.75rem;
                  }
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }

            .inventory-action-button {
              width: 15%;
              text-align: center;

              .action-tooltip {
                &-text {
                  width: 4.2rem;
                }
              }
            }
          }
        }

        .load-more-button {
          align-self: center;
          padding: 0.25rem;
        }
      }
    }
  }
}