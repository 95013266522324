@use '../../scss/elevations' as elevations;
@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.animal-search {
  position: relative;

  .search-bar {
    padding: 0.25rem 1rem;
    background: colors.$surface-400;
    border-radius: 10rem;
    display: flex;
    align-items: center;

    input {
      height: 2rem;
      background: colors.$surface-400;
    }
  }

  .animal-search-dropdown {
    @include fonts.body3;
    @include elevations.elevation-1;
    min-width: 17.75rem;
    width: 100%;
    position: absolute;
    border: solid 1px colors.$surface-500;
    background-color: colors.$surface-100;
    border-radius: 0.5rem; //8px

    .animal-search-item {
      height: 3rem;
      display: flex;
      align-items: center;
      padding: 0.5rem 0 0.5rem 1rem;
      border-top: solid 1px colors.$surface-500;
      border-bottom: solid 1px colors.$surface-500;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      &:hover {
        @include elevations.elevation-2;
      }

      &:active {
        @include elevations.elevation-1;
      }
    }

    .animal-search-item-image {
      width: 1.875rem; //30px
      height: 1.875rem; //30px
      margin-right: 1.25rem; //20px
      background-color: colors.$surface-300;
      border-radius: 10rem;
    }
  }

  .results {
    min-height: 0rem;
    max-height: 14.65rem;
    overflow-y: auto;
  }

  @include screenSizes.mobile {
    .results {
      max-height: 12.65rem;
    }
  }
}