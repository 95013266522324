@use '../../scss/colors' as colors;
@use '../../scss/elevations' as elevations;

.location-management {
  padding: 1rem;
  .canister-contents-label {
    display: flex;
    align-items: center;
  }
}
