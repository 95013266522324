@use '../../scss/colors' as colors;

.account-search {
  .results {
    min-height: 0;
    max-height: 14.25rem;

    .result-item-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      height: 5rem;
      border-top: solid 1px colors.$surface-500;
    }
  }
}
