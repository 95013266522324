@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/elevations' as elevations;
@use '../../../scss/screenSizes' as screenSizes;

.payment-history-container {
  .xs-payment-history {
    display: none;
  }

  .sorted-table {
    table {
      th {
        @include fonts.caption1;
        font-weight: 600;
      }

      td {
        @include fonts.caption1;
        text-align: center;
      }

      .tooltip {
        svg {
          margin-left: 0.25rem;
        }

        .tooltip-text {
          width: 12rem;
          left: 2rem;
          @include fonts.caption1;
        }
      }
    }
  }

  @include screenSizes.tablet {
    .xs-payment-history {
      display: none;
    }

    .sorted-table {
      table {
        th {
          @include fonts.caption2;
          font-weight: 600;
        }

        td {
          @include fonts.caption2;
          text-align: center;
        }

        .tooltip .tooltip-text {
          width: 8rem;
          left: 1.5rem;
        }
      }
    }
  }

  @include screenSizes.mobile {
    .sorted-table {
      display: none;
    }

    .xs-payment-history {
      width: 100%;
      display: flex;
      flex-direction: column;

      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0;
        margin-bottom: 1rem;
        @include elevations.elevation-1;
      }

      .card .payment-history-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.7rem 0;
        border-bottom: 1px solid colors.$text-200;

        .payment-history-content {
          display: flex;
          flex-direction: column;
          text-align: center;
          flex: 1;
          padding: 0 0.4rem;
          border-right: 1px solid colors.$text-200;

          &:last-child {
            border-right: none;
          }

          label {
            @include fonts.caption2;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .result-item {
        text-align: center;
        @include fonts.caption1;
      }

      .tooltip svg {
        margin-left: 0.25rem;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}