@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.upload-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.25rem;

  .drop-area {
    display: flex;
    flex-direction: column;
    color: colors.$text-500;
    justify-content: center;
    align-items: center;
    border: 1px dashed colors.$surface-500;
    background-color: colors.$surface-200;
    border-radius: 8px;
    text-align: center;
    height: 15.125rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .drag-n-drop-text {
      @include fonts.body1;
    }

    .file-types-text {
      @include fonts.caption1;
      color: colors.$text-300;
    }

    .upload-icon {
      width: 3.125rem;
      height: 3.125rem;
      color: colors.$icon-200;
    }
  }

  .mini-preview-url-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-top: 1rem;

    .existing-attachment,
    .mini-preview-url,
    img {
      position: relative;
      width: 6.875rem;
      height: 6.875rem;
      aspect-ratio: 1;
      border-radius: 0.5rem;
      object-fit: cover;
      background-color: colors.$surface-500;

      .delete-attachment-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0;
        top: 0.25rem;
        right: 0.25rem;
        z-index: 2;
        width: 1.625rem;
        height: 1.625rem;
        background-color: colors.$surface-100;
        border-radius: 50%;
        cursor: pointer;

        .trash-can-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          color: colors.$secondary-red-300;

          svg {
            width: 0.625rem;
            height: 0.75rem;
          }
        }
      }
    }
  }

  .file-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    input[type='file'] {
      display: none;
    }

    .upload-label {
      cursor: pointer;
      text-align: center;
      width: 8.75rem;
      margin: 0 1rem;
    }
  }

  @include screenSizes.mobile {
    padding: 1.75rem;

    h2 {
      @include fonts.h5;
    }

    .drop-area {
      height: 12.125rem;

      .drag-n-drop-text {
        @include fonts.caption2;
        font-size: 0.75rem;
      }

      .file-types-text {
        @include fonts.caption2;
      }

      .upload-icon {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    .mini-preview-url-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0.5rem;

      .existing-attachment,
      .mini-preview-url,
      img {
        width: 4.5rem;
        height: 4.5rem;

        .delete-attachment-button {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .file-select {
      flex-direction: column;

      .button {
        padding: 0.25rem;
        margin-bottom: 1rem;
        width: 75%;
      }

      .upload-label {
        cursor: pointer;
        text-align: center;
        margin: 0;
      }
    }
  }
}