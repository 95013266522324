@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.manage-team {
  background-color: colors.$surface-200;
  padding: 2rem;

  .half {
    width: 50%;
  }

  .full {
    width: 100%;
  }

  .mr-1 {
    margin-right: 1rem;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .back-button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .button {

      &.disabled,
      &[disabled] {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }

  .add-user-modal {
    display: flex;
    flex-direction: column;
    background-color: colors.$surface-100;
    padding: 2rem;

    h4 {
      @include fonts.h4;
    }

    p,
    label {
      @include fonts.body2;
    }

    .name-inputs-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .input {
        width: 48%;
      }
    }

    .input-group {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .footer {
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }

    .account-found {
      .account-found-text {
        @include fonts.body3;
        color: colors.$primary-green-500;
      }

      h3 {
        @include fonts.body1;
        color: colors.$text-600;
        margin-top: 1.5rem;
      }

      p {
        @include fonts.body2;
        color: colors.$text-300;
      }
    }

    .account-not-found {
      display: flex;
      flex-direction: row;

      .account-not-found-text {
        margin-left: 0.5rem;
        @include fonts.body3;
        color: colors.$secondary-red-300;
      }
    }
  }

  .user-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .email-address {
      color: colors.$text-200;
    }

    .user-profile-image {
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      aspect-ratio: 1;
      background-color: colors.$surface-400;
      margin: 0rem 0.5rem 0rem 0.5rem;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;

    button {
      width: 6rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      &.disabled,
      &[disabled] {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }

  .role-container {
    text-align: center;
  }

  .xs-buttons-container {
    display: none;
  }

  .manage-team-modal {
    display: flex;
    flex-direction: column;
    background-color: colors.$surface-100;

    h4 {
      text-align: left;
      padding: 1.25rem;
      @include fonts.h4;
      border-bottom: 1.5px solid colors.$surface-500;
    }

    p {
      text-align: center;
      padding: 3rem;
      @include fonts.body1;
      color: colors.$text-600;
    }

    .footer {
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      background-color: colors.$surface-500;
    }
  }

  @include screenSizes.tablet {
    .header {
      .back-button {
        margin: 1rem 1rem 1rem 0;
      }

      .outlined {
        margin-bottom: -8rem;
      }
    }

    .searchable-table {
      .search-bar {
        width: calc(100% - 10rem);
        border-radius: 9rem;

        input::placeholder {
          @include fonts.caption1;
        }
      }

      .scrollable {
        margin-top: 2rem;

        table {
          border-top-left-radius: 0.75rem;
          border-top-right-radius: 0.75rem;

          thead {
            tr th {
              @include fonts.caption1-semibold;
              font-size: 0.75rem;
              padding: 1rem 1rem 0.5rem 1rem;
            }
          }
        }
      }
    }

    .user-card {
      gap: 0.5rem;

      p {
        @include fonts.caption1;
        font-size: 0.75rem;
      }
    }

    .role-container {
      @include fonts.caption1;
      font-size: 0.75rem;
    }

    .desk-buttons-container .buttons-container {
      .button {
        @include fonts.caption2-semibold;
        font-size: 0.75rem;
      }
    }
  }

  @include screenSizes.mobile {
    padding: 1.5rem;

    .header {
      .back-button {
        margin: 1rem 1rem 1rem 0;
      }

      .button {
        @include fonts.caption1;
        font-size: 0.75rem;
      }

      .outlined {
        margin-bottom: -7rem;
      }
    }

    .add-user-modal {
      padding: 0;

      h4 {
        text-align: center;
        padding: 1rem 0;
        border-bottom: 1px solid colors.$text-200;
      }

      p {
        @include fonts.caption1;
        margin: 2rem 2rem 0;
      }

      form {
        margin: 1rem 2rem;

        .input-group .filter-dropdown {
          padding: 0;
          width: 100%;

          .form-field {
            padding: 0.85rem;

            p {
              @include fonts.caption2;
              margin: 0;
            }
          }

          .options {
            p {
              @include fonts.caption2;
              margin: 0;
            }
          }
        }

        .account-found {
          .account-found-text {
            @include fonts.caption2;
            font-size: 0.75rem;
            margin-left: 0;
          }

          h3 {
            color: colors.$text-600;
            @include fonts.caption1;
          }

          p {
            @include fonts.caption1;
            color: colors.$text-500;
            padding: 0.5rem 0 0;
            margin: 0;
          }
        }

        label {
          @include fonts.caption1;
        }

        .account-not-found {
          width: 100%;
          justify-content: flex-start;
          gap: 0.5rem;

          svg {
            padding: 0;
          }

          .account-not-found-text {
            @include fonts.caption2;
            margin: 0;
          }
        }

        .name-inputs-container {
          flex-direction: column;
          gap: 1rem;

          .input {
            width: 100%;
          }
        }
      }
    }

    .searchable-table {
      .search-bar {
        width: calc(100% - 8rem);
        height: 2.2rem;
        border-radius: 9rem;

        &::before {
          width: 2rem;
          height: 70%;
        }

        input {
          @include fonts.caption1;
          font-size: 0.75rem;
        }
      }

      .scrollable {
        margin-top: 2rem;

        table {
          border-top-left-radius: 0.625rem;
          border-top-right-radius: 0.625rem;

          thead {
            tr th {
              @include fonts.caption2-semibold;
              font-weight: 600;
              padding: 1rem 1rem 0.5rem 1rem;
            }
          }
        }
      }
    }

    .user-card {
      .user-profile-image {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0rem 0.5rem 0rem 0.5rem;
      }

      p {
        @include fonts.caption2;
      }
    }

    .role-container {
      @include fonts.caption2;
    }

    .desk-buttons-container {
      display: none;
    }

    .xs-buttons-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .manage-team-modal {
      h4 {
        text-align: center;
      }

      p {
        @include fonts.body2;
        padding: 2rem 3rem 4rem 3rem;
      }

      .footer {
        justify-content: center;
        background-color: inherit;
        gap: 0.5rem;

        .button {
          width: 10rem;
        }
      }
    }
  }
}

.user-profile {
  .edit-user-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .select-container {
      display: flex;
      flex-direction: column;
      min-width: 20rem;
    }

    h3 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    button {
      margin-top: 10rem;
      margin-bottom: 10rem;
    }
  }

  @include screenSizes.tablet {
    .edit-user-container {
      h3 {
        @include fonts.body3;
      }

      .select-container {
        label {
          @include fonts.caption2;
          font-size: 0.75rem;
          padding-bottom: 0.3rem;
        }

        .filter-dropdown {
          @include fonts.caption2;
        }
      }
    }
  }

  @include screenSizes.mobile {
    display: flex;
    flex-direction: column;

    .edit-user-container {
      width: 100%;
      display: flex;

      h3 {
        @include fonts.caption2;
        font-size: 0.75rem;
      }

      .select-container {
        display: flex;
        width: 78%;
        min-width: fit-content;

        label {
          @include fonts.caption2-semibold;
          padding-bottom: 0.3rem;
        }
      }

      .button {
        width: 10rem;
        padding: 0.7rem;
      }
    }
  }
}