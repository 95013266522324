@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/elevations' as elevations;
@use '../../../scss/screenSizes' as screenSizes;

.address-input-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.625rem;
  @include elevations.elevation-1;

  h2 {
    @include fonts.h4;
    padding-bottom: 1rem;
  }

  .address-type-select {
    border-radius: 8px;
    border: 1px solid colors.$surface-500;

    select {
      border-radius: 8px;
      padding: 0.875rem;
      border-right: 16px solid transparent;
      border-left: none;
      border-top: none;
      border-bottom: none;

      width: 100%;
    }
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5625rem;

    .input {
      width: calc(50% - 0.6875rem);
    }
  }

  .filter-dropdown {
    margin-bottom: 1rem;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    margin-top: 2rem;

    .button {
      width: 11rem;
    }
  }

  @include screenSizes.mobile {
    .address-type-label {
      @include fonts.caption2;
      font-size: 0.75rem;
    }

    .filter-dropdown {
      padding: 1rem;
    }

    .footer {
      justify-content: center;
      gap: 3rem;

      .button {
        width: 10rem;
      }
    }
  }
}