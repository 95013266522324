@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.quick-view-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0.5rem;
  padding: 0.75rem;
  gap: 1rem;

  .notes {
    padding-bottom: 1rem;

    p {
      padding-bottom: 0.5rem;
    }
  }

  #close-qv-button {
    width: 100%;
    height: 3rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .l-column {
      width: fit-content;
      flex: 1;
      text-align: left;
    }

    .r-column {
      width: 33%;
      text-align: left;
    }
  }

  #qv-title {
    padding-top: 1rem;
  }

  @include screenSizes.mobile {
    gap: 0.5rem;

    .notes {
      padding-bottom: 1rem;
      @include fonts.body3;
    }

    #close-qv-button {
      width: 8rem;
      height: 2.5rem;
      align-self: center;
    }

    .row {

      .l-column,
      .r-column {
        @include fonts.body3;
      }
    }

    #qv-title {
      padding: 0.5rem;
      text-align: center;
      @include fonts.h4;
    }
  }
}