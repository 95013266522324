@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;

.dashboard {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  padding: 1rem;
  gap: 1rem;

  .xs-notification-banner {
    display: none;
  }

  .card {
    margin: 0;
  }

  .full {
    width: 100%;
  }

  .recently-acquired-inventory,
  .pending-transactions,
  .animal-ownership,
  .catalog-sell {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .inventory-header,
    .pending-transactions-header,
    .animal-ownership-header,
    .catalog-sell-header {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    .inventory-table,
    .pending-transactions-table,
    .catalog-sell-table {
      .sorted-table {
        border-radius: 1.5rem;
        border: none;
      }

      .no-inventory-available {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem;
        margin: 3rem;
      }

      th {
        padding: 1rem;
        padding-top: 2rem;
        border: none;
      }
    }

    .catalog-sell-table table tbody {
      max-height: 20rem;
    }

    .animal-ownership-table {
      .sorted-table {
        border-radius: 1.5rem;
        border: none;
      }
    }
  }

  .recently-acquired-inventory {
    td {
      padding: 1rem;
    }
  }

  .inventory-table {
    td {
      background-color: colors.$surface-100;
    }
  }

  .see-transactions {
    padding: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  .max-width {
    width: calc(50% - 2.5rem);
  }

  @media (max-width: 1400px) {
    .max-width {
      width: 100%;
    }
  }

  .mobile-view {
    display: none;
  }

  @include screenSizes.mobile {
    .xs-notification-banner {
      display: flex;

      .notification-banner {
        width: 100%;

        .card {
          width: 100%;
          padding: 0.5rem;

          .notification-banner-title .payment-details-not-found-notification {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            p {
              @include fonts.caption1;
              font-size: 0.75rem;
            }

            .payment-details-link {
              color: colors.$secondary-red-300;
            }
          }

          .pay-now-notification {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            flex-direction: row;

            p {
              font-size: 0.625rem;
              width: 75%;
            }

            .full-width-pay-now-notification {
              width: 100%;
            }

            .pay-now-button {
              font-size: 0.625rem;
              line-height: 1rem;
              width: 25%;
            }
          }
        }
      }
    }

    .max-width .full {
      width: 90%;
    }

    .mobile-view {
      display: block;
    }

    .desktop-view {
      display: none;
    }

    .recently-acquired-inventory,
    .pending-transactions,
    .animal-ownership,
    .catalog-sell {

      .pending-transactions-header,
      .inventory-header,
      .animal-ownership-header,
      .catalog-sell-header {
        display: inline;
        @include fonts.h7;
        margin: 0 0 1rem;
      }

      .inventory-table,
      .pending-transactions-table,
      .animal-ownership-table,
      .catalog-sell-table {
        th {
          padding-top: 1rem;
          padding: 0.5rem;
        }

        tr th {
          &::before {
            border-left: none;
          }
        }

        .no-inventory-available {
          @include fonts.caption1;
          font-size: 0.75rem;
          font-weight: 700;
          text-align: center;
          margin: 2rem;
        }
      }

      .catalog-sell-table table tbody {
        max-height: 13rem;
      }
    }

    .animal-ownership {
      .sorted-table table {
        tbody {
          max-height: 130px !important;
        }
      }
    }

    .see-transactions {
      padding: 0.5rem;
      width: 10rem;
    }
  }

  @include screenSizes.tablet {
    gap: 0.5rem;
    padding: 0.5rem;

    .xs-notification-banner {
      display: none;
    }

    .card {
      padding: 0.5rem;
    }

    .max-width {
      flex-grow: 1;

      &:first-child {
        width: 32%;
      }

      &:nth-child(2) {
        width: 63%;
      }
    }

    .recently-acquired-inventory,
    .pending-transactions,
    .animal-ownership,
    .catalog-sell {
      padding: 0.5rem;
      margin-top: 0.7rem;

      .pending-transactions-table,
      .inventory-table,
      .animal-ownership-table,
      .catalog-sell-table {
        th {
          @include fonts.caption2;
          font-weight: 600;
          padding-top: 1rem;

          &::before {
            border-left: none;
          }
        }

        td {
          @include fonts.caption2;
          padding: 1.3rem 0;
        }

        .no-inventory-available {
          @include fonts.body3;
        }
      }

      .catalog-sell-table table tbody {
        max-height: 17rem;
      }
    }

    .pending-transactions,
    .recently-acquired-inventory,
    .animal-ownership,
    .catalog-sell {

      .pending-transactions-header,
      .inventory-header,
      .animal-ownership-header,
      .catalog-sell-header {
        @include fonts.button3;
        margin: 0.8rem 0;
      }
    }

    .animal-ownership-table {
      .sorted-table table {
        tbody {
          max-height: 230px !important;
        }
      }
    }

    .see-transactions {
      padding: 0.5rem;
      margin-top: 1rem;
    }
  }
}