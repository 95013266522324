@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/elevations' as elevations;

.user-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  text-align: left;

  .email-address {
    color: colors.$text-200;
  }

  .user-profile-image {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    margin: 0rem 1rem 0rem 1rem;
  }
}
