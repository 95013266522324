@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.transaction-history {
    .xs-transaction-history {
        display: none;
    }

    @include screenSizes.tablet {
        .sorted-table {
            thead {
                tr th {
                    @include fonts.caption2-semibold;
                    padding: 1rem 0.25rem;

                    svg {
                        width: 0.7rem;
                        height: 0.7rem;
                    }
                }
            }

            tbody {
                tr td {
                    padding: 1rem 0.25rem;
                    @include fonts.caption2;
                }
            }
        }
    }

    @include screenSizes.mobile {
        .desk-transaction-history {
            display: none;
        }

        .xs-transaction-history {
            display: block;

            .result-item {
                text-align: center;
                @include fonts.caption1;
            }

            .card {
                padding: 0rem;

                &.max-width {
                    margin: 1rem 0rem 0rem 0rem;
                }
            }

            .transaction-history-card {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .transaction-history-content {
                    width: 85%;
                    border-right: 1px solid colors.$text-200;

                    .transaction-info {
                        border-bottom: 1px solid colors.$text-200;
                        display: flex;
                        align-items: center;
                        padding: 0.5rem;
                        justify-content: space-between;

                        .transaction-content {
                            border-right: 1px solid colors.$text-200;
                            padding: 0 0.5rem;
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;

                            &:last-child {
                                border-right: none;
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }

                .action-button {
                    width: 15%;
                    text-align: center;

                    button {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }

                label {
                    @include fonts.caption2;
                }
            }
        }
    }
}