@use '../../scss/colors' as colors;
@use '../../scss/elevations' as elevations;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.tablet-nav-bar {
  display: none;

  @include screenSizes.tablet {
    display: block;
    min-width: 6rem;
    background-color: colors.$surface-100;
    z-index: 99;
    text-align: center;

    button {
      margin: 1.5rem 0rem;
    }

    .nav-link {
      color: colors.$icon-300;

      &:active {
        @include elevations.elevation-1;
      }

      svg {
        width: 1.5rem;
        min-width: 1.5rem;
      }

      &.active {
        color: colors.$primary-blue-400;

        svg path {
          fill: colors.$primary-blue-400;
        }

        svg.stroke path {
          fill: none;
          stroke: colors.$primary-blue-400;
        }
      }
    }

    .profile-image {
      border-radius: 50%;
      width: 3.5rem;
      height: 3.5rem;
      background-color: colors.$surface-400;
      margin: 1.5rem 0rem;
    }

    .tab-grid-nav-bar {
      display: grid;

      a {
        margin: 1.5rem 0rem;
      }
    }

    hr {
      border: 1px solid;
      margin-left: -2px;
      border-color: colors.$surface-500;
    }

    .tab-setting-nav-bar {
      margin: 1.5rem 0rem;
    }

    .tab-sign-out {
      margin-top: 3rem;

      label {
        display: none;
      }
    }
  }
}

.nav-bar {
  min-width: 20rem;
  background-color: colors.$surface-100;
  z-index: 99;

  .tab-menu-button {
    display: none;
  }

  .account-nav {
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transition-delay: 0.275s;
    max-height: 70rem;
    overflow: hidden;
  }

  .hide {
    transition-delay: 0s;
    max-height: 0;
    pointer-events: none;
  }

  .show-hide-button {
    display: none;
  }

  button {
    width: 100%;
    position: relative;
  }

  .nav-link,
  .nav-sub-link {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 1rem;
    @include fonts.body1-semibold;
    color: colors.$icon-300;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      @include elevations.elevation-2;
    }

    &:active {
      @include elevations.elevation-1;
    }

    svg {
      width: 1.25rem;
      min-width: 1.25rem;
      margin: 0 0.5rem;
    }

    &.active {
      color: colors.$primary-blue-400;

      svg path {
        fill: colors.$primary-blue-400;
      }

      svg.stroke path {
        fill: none;
        stroke: colors.$primary-blue-400;
      }
    }

    label {
      @include fonts.body1-semibold;
      color: colors.$icon-300;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .nav-sub-link {
    padding: 0.5rem 1rem 0.5rem 4rem;
  }

  hr {
    border: 1px solid;
    margin-left: -2px;
    border-color: colors.$surface-500;
  }

  @include screenSizes.tablet {
    &.hide {
      display: none;
    }

    .tab-menu-button {
      display: block;
      text-align: end;
      padding-right: 1.5rem;
    }
  }

  @include screenSizes.mobile {
    position: absolute;
    transition-property: left;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    transition-delay: 0;
    max-width: 20rem;

    left: 0;

    &.hide {
      left: -25rem;
    }

    .show-hide-button {
      display: block;
      z-index: inherit;
      position: absolute;
      top: 0;
      background-color: colors.$white;
      right: -1.5rem;
      border: 1px colors.$surface-500 solid;
      width: 3rem;
      height: 3rem;
    }
  }
}
