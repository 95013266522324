@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.tooltip {
  position: relative;
  top: 0.125rem;
  display: inline-block;

  .tooltip-text {
    visibility: hidden;
    padding: 0.5rem 0.5rem 0.25rem 0.5rem;
    border-radius: 5px;
    @include elevations.elevation-1;
    background-color: colors.$surface-200;

    position: absolute;
    top: -0.5rem;
    left: 3rem;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 0;
      margin-left: -1rem;
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent colors.$surface-200 transparent transparent;
    }

    h2 {
      margin: 0;
      @include fonts.body3-semibold;
      white-space: nowrap;
    }

    ul {
      @include fonts.caption2;
      margin: 0;
      padding: 0.25rem 0 0 1rem;
    }

    li {
      white-space: nowrap;
    }

    hr {
      border: 1px solid colors.$black;
      border-bottom: none;
      margin: 0;
    }

    p {
      margin: 0;
      @include fonts.caption2;
    }

    &.left {
      left: auto;
      right: calc(100% + 0.5rem);
      top: 0;

      &::after {
        left: auto;
        right: 0;
        margin-left: inherit;
        margin-right: -1rem;
        border-color: transparent transparent transparent colors.$surface-200;
      }
    }
  }

  .tooltip-text-right {
    @extend .tooltip-text;

    position: absolute;
    top: -0.5rem;
    left: 5rem;
    z-index: 1;
    pointer-events: none;
  }

  &:hover .tooltip-text,
  &:focus .tooltip-text {
    visibility: visible;
  }

  @include screenSizes.mobile {
    .tooltip-text {
      position: fixed;
      top: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;
      &::after {
        display: none;
      }
    }
  }
}
