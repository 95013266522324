@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.new-data-point-modal {
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .validated-input-message-error {
    font-size: 0.75rem;
  }

  .row {
    display: flex;
    gap: 1.25rem;
  }

  h2 {
    text-decoration: underline;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    padding: 1rem;

    button[aria-disabled='true'] {
      opacity: 0.5;
    }
  }

  label[aria-disabled='true'] {
    opacity: 0.5;
  }

  @include screenSizes.mobile {
    h2 {
      text-align: center;
      padding-bottom: 2rem;
      border-bottom: 1px solid colors.$text-200;
      text-decoration: none;
    }
  }
}
