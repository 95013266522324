@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.inventory-graphics {
  .header {
    display: flex;
    align-items: center;
    h2 {
      @include fonts.body2-semibold;
    }
    p {
      @include fonts.caption1;
      padding: 0.25rem 0.5rem;
      &:last-child {
        border-left: 1px solid colors.$black;
      }
    }
  }
  .charts {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0 0.5rem 0;

    .chart-container {
      min-width: 9.5rem;
      border-radius: 0.3125rem; //5px
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background-color: colors.$surface-200;
      @include elevations.elevation-1;

      h3 {
        padding: 0.3rem;
        background-color: colors.$surface-500;
        text-align: center;
        @include fonts.caption1-semibold;
      }
      .chart {
        padding: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .mobile-view {
    display: none;
  }

  .tablet-view {
    display: none;
  }

  @include screenSizes.mobile {
    .header {
      align-items: center;
      justify-content: start;

      h2 {
        @include fonts.body3-semibold;
      }
      p {
        @include fonts.caption2;
        padding: 0.25rem;
      }
    }

    .charts-container {
      display: flex;
      flex-direction: column;

      .charts {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 0;

        .chart-container {
          min-width: 62%;
          margin-left: 0;
        }
        .active-slide {
          display: block;
        }

        .slide {
          display: none;
        }

        .right-arrow {
          width: 20%;
        }
      }

      .dots-container {
        display: flex;
        justify-content: center;
        margin-top: 0.6rem;

        .dot {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          margin: 0.3rem;
          background-color: colors.$text-150;
        }

        .active {
          background-color: colors.$primary-green-500;
        }
      }
    }

    .desktop-view {
      display: none;
    }

    .tablet-view {
      display: none;
    }
  }

  @include screenSizes.tablet {
    .header {
      h2 {
        @include fonts.caption1;
      }
      p {
        @include fonts.caption2;
        padding: 0.15rem 0.35rem;
      }
    }

    .charts {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .chart-container {
        min-width: 7.3rem;

        h3 {
          @include fonts.caption2-semibold;
        }

        .chart {
          padding: 0.5rem;
        }
      }
    }

    .desktop-view {
      display: none;
    }

    .mobile-view {
      display: none;
    }
  }
}
