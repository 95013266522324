@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.account-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;

  .header {
    text-align: left;
    margin-bottom: 1rem;
  }

  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.78125rem; // 12.5px
    margin-bottom: 1rem;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 45%;
    height: 18.75rem; // 300px
    border-radius: 0.625rem; // 10px
    text-align: left;

    input {
      height: 0;
    }
  }

  .row {
    height: 2rem;
  }

  .divider {
    width: 100%;
    margin-top: 2rem;
    border: none;
    border-top: 0.125rem solid colors.$text-600; // 2px
  }

  .section-header {
    text-align: left;
    margin-top: 1.25rem; // 20px
  }

  .section-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1.25rem; // 20px
  }

  .deactivate {
    margin-right: 1.25rem; // 20px
  }

  .deactivate-button {
    color: colors.$text-600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;

    &.disabled {
      color: colors.$text-200;
    }

    svg {
      height: 100%;
      margin-left: 0.75rem;
    }
  }

  @include screenSizes.tablet {
    padding: 2rem 1.5rem;

    .header {
      h3 {
        @include fonts.h6;
      }
    }

    .column {
      input {
        padding: 1.25rem;
      }
    }

    .row {
      height: 2rem;
      display: flex;
      align-items: center;
      @include fonts.body3;
    }

    .divider {
      margin-top: 1rem;
      border-top: 1px solid colors.$text-600;
    }

    .section-header {
      h3 {
        @include fonts.h6;
      }
    }

    .section-content {
      @include fonts.body3;
    }

    .deactivate-button {
      font-size: 0.875rem;
    }
  }

  @include screenSizes.mobile {
    padding: 2rem 1rem;

    .header {
      h3 {
        @include fonts.h6;
      }

      margin-bottom: 0.5rem;
    }

    .column {
      height: 18.75rem;

      input {
        height: 0;
        padding: 1rem;
      }
    }

    .row {
      height: 2rem;
      display: flex;
      align-items: center;
      @include fonts.body3;
    }

    .divider {
      margin-top: 1rem;
      border-top: 1px solid colors.$text-600;
    }

    .section-header {
      h3 {
        @include fonts.h6;
      }
    }

    .section-content {
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
      @include fonts.body3;
    }

    .deactivate {
      margin-right: 0rem;
    }
  }
}