@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.disable-account-modal {
  display: flex;
  flex-direction: column;

  .header {
    padding: 1rem;
    border-bottom: 1px solid colors.$text-200;
    background-color: colors.$surface-100;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    h4 {
      @include fonts.h4;
    }
  }

  .body {
    @include fonts.body1;
    padding: 1rem;
    background-color: colors.$surface-100;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    padding: 1rem;
    background-color: colors.$surface-500;
  }

  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.5rem 0;
    max-width: 44rem;
  }

  @include screenSizes.tablet {
    .form-row.expanded-text-area textarea {
      width: calc(100% - 31rem);
      min-width: calc(10rem - 2px);
    }

    .form-row {
      &.expanded-text-area {
        max-width: 44rem;
        flex-wrap: wrap;
      }
    }
  }

  @include screenSizes.mobile {
    .form-row {
      .date-input {
        @include fonts.caption1;
      }

      &.expanded-text-area {
        max-width: 44rem;
        flex-wrap: wrap;

        .text-area {
          max-width: calc(10.5rem - 2px);
        }
      }
    }

    .body {
      padding: 1rem 2rem;
    }
  }
}