@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.notification-banner {
  display: flex;
  height: 100%;
  padding: 0;

  .card {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 0.5rem;
    color: colors.$secondary-red-300;

    .notification-banner-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
