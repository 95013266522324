$mobile: 767px;
$tablet: 1024px;

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: calc($mobile + 1px)) and (max-width: $tablet) {
    @content;
  }
}