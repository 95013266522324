@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.title-bar {
  background-color: colors.$surface-100;
  display: flex;
  $padding: 1.4rem;
  padding: 1.2rem 1.4rem;
  width: calc(100% - 2 * $padding);
  justify-content: space-between;
  align-items: center;

  .logo {
    @include fonts.subheader1-medium;
    color: colors.$primary-green-500;
  }

  .sign-out-link {
    .nav-link {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 100000000;
      padding: 0.25rem;

      &:hover {
        @include elevations.elevation-2;
      }

      &:active {
        @include elevations.elevation-1;
      }
    }

    .modal {
      z-index: 100000000;
    }
  }

  .payment-details-not-found-notification {
    display: flex;
    flex-wrap: wrap;

    p {
      @include fonts.body3;
    }

    .payment-details-link {
      color: colors.$secondary-red-300;
    }
  }

  .pay-now-notification {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;

    p {
      @include fonts.body3;
      width: 85%;
    }

    .full-width-pay-now-notification {
      width: 100%;
    }

    .pay-now-button {
      @include fonts.body3-semibold;
      width: 15%;
    }
  }

  .notification-cart-container {
    max-width: calc(100% - 18.5rem);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.3rem;

    .notification-banner {
      width: 97%;
      height: 100%;
    }

    .button .cart-item {
      position: absolute;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: colors.$secondary-red-300;
      top: 1.7rem;
      right: 1.9rem;
    }
  }

  @include screenSizes.tablet {
    .payment-details-not-found-notification {
      p {
        @include fonts.caption1;
      }
    }

    .pay-now-notification {
      p {
        font-size: 0.75rem;
        width: 80%;
      }

      .full-width-pay-now-notification {
        width: 100%;
      }

      .pay-now-button {
        font-size: 0.75rem;
        width: 20%;
      }
    }

    .notification-cart-container {
      max-width: calc(100% - 16rem);

      .button {
        padding: 0.3rem;

        .cart-item {
          width: 0.78rem;
          height: 0.78rem;
          top: 1.8rem;
          right: 1.8rem;
        }
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }

      .notification-banner {
        .card {
          .notification-banner-title {
            svg {
              width: 1.1rem;
              height: 1.1rem;
            }
          }
        }
      }
    }
  }

  @include screenSizes.mobile {
    .logo {
      @include fonts.subheader2-medium;
      color: colors.$primary-green-500;
    }

    .notification-cart-container {
      min-width: 22%;

      .button {
        padding: 0.3rem;

        .cart-item {
          width: 0.74rem;
          height: 0.74rem;
          top: 1.4rem;
          right: 1.8rem;
        }
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      .notification-banner {
        display: none;
      }
    }
  }
}
