@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;

.transaction-view {
  .form-row {
    margin-bottom: 1rem;

    .form-label-container {
      width: 100%;
    }

    .form-label {
      @include fonts.body3;
      color: colors.$text-400;

      &.extended-transaction-notes {
        white-space: pre-wrap;
      }
    }
  }

  .specimens-table {
    margin: 1rem 0rem;
  }

  .edit-transaction-buttons {
    margin-top: 2.5rem;
  }

  @include screenSizes.tablet {
    .form-row {
      &.expanded-text-area {
        label {
          max-width: 17.5rem;
        }
      }

      .input-container {
        max-width: 16.5rem;
      }
    }
  }

  @include screenSizes.mobile {
    .form-row {
      margin-bottom: 0rem;
      max-width: none;

      &.expanded-text-area {
        max-width: none;
      }

      .form-label-container {
        max-width: 60%;
        width: auto;
      }

      .input-container {
        max-width: none;
        width: auto;
      }

      .form-label {
        font-size: 0.75rem;
      }
    }

    .edit-transaction-buttons {
      margin-top: 2.5rem;
    }
  }
}