@use '../../scss/colors' as colors;
@use '../../scss/elevations' as elevations;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.action-tooltip {
  position: relative;

  &.hide-overflow {
    overflow: hidden;
  }

  &.transaction-tooltip {
    padding: 0;

    &.button.icon {
      &.hovering {
        background-color: colors.$surface-600;
      }
    }

    span.action-tooltip-text {
      margin: 0;
      padding: 0;
      background-color: colors.$surface-100;
      position: absolute;
      z-index: 999;
    }
  }

  &-text {
    width: 5.4rem;
    padding: 0.5rem 0.5rem 0.25rem 0.5rem;
    border-radius: 5px;
    @include elevations.elevation-1;
    background-color: colors.$surface-200;
    position: absolute;
    top: 2rem;
    left: -0.4rem;
    z-index: 1;
    opacity: 1;

    &.fading {
      opacity: 0;
    }

    h2 {
      margin: 0;
      @include fonts.body3-semibold;
      white-space: nowrap;
    }

    ul {
      @include fonts.caption2;
      margin: 0;
      padding: 0.25rem 0 0 1rem;
    }

    li {
      white-space: nowrap;
    }

    hr {
      border: 1px solid colors.$black;
      border-bottom: none;
      margin: 0;
    }

    p {
      margin: 0;
      @include fonts.caption2;
    }

    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      z-index: 10000;
      top: -0.5rem;
      left: 2rem;
      margin-left: -1rem;
      border-width: 0.5rem;
      border-style: solid;
      border-color: colors.$surface-100 transparent transparent colors.$surface-100;
      transform: rotate(45deg);
    }

    &.top {
      &::after {
        top: auto;
        bottom: -0.5rem;
        border-color: colors.$surface-100 colors.$surface-100 transparent transparent;
        transform: rotate(135deg);
      }
    }

    &.left {
      top: -0.3rem;
      left: -5.5rem;

      &::after {
        z-index: -1;
        left: 5.79rem;
        top: 0.74rem;
        transform: rotate(135deg);
      }
    }

    .action-button {
      min-width: max-content;
      margin: 0.25rem 0;
      padding: 0.5rem;

      &:hover {
        background-color: colors.$surface-300;
        transition: background-color 0.3s ease;
      }
    }
  }

  @include screenSizes.tablet {
    &.transaction-tooltip {
      &.button.icon {
        width: 1.75rem;
        height: 1.75rem;
      }

      span.action-tooltip-text {
        @include fonts.caption2;
      }
    }

    &-text {
      width: 4.2rem;
      left: -2rem;

      &::after {
        left: 3.2rem;
        top: -0.4rem;
      }

      &.left {
        top: -0.5rem;
        left: -4.5rem;

        &::after {
          left: 4.6rem;
          top: 0.82rem;
          transform: rotate(135deg);
        }
      }

      .action-button {
        padding: 0.4rem;
      }
    }
  }

  @include screenSizes.mobile {
    &.transaction-tooltip {
      &.button.icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      span.action-tooltip-text {
        @include fonts.caption2;
      }
    }

    &-text {
      width: 4rem;
      left: -2rem;

      &::after {
        left: 3.2rem;
        top: -0.4rem;
      }

      &.left {
        top: -0.5rem;
        left: -4.5rem;

        &::after {
          left: 4.4rem;
          top: 0.78rem;
        }
      }
    }
  }
}