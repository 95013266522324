@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;

.response-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .response-container {
        h1 {
            @include fonts.h1;
            text-align: center;
            font-size: 10rem;
            color: colors.$surface-400;
            line-height: 10rem;
        }

        .response-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            h3 {
                @include fonts.h4;
                margin-bottom: 0.5rem;
            }

            p {
                @include fonts.body2;
                padding: 0.25rem;
                margin-bottom: 0.5rem;
            }
        }
    }

    @include screenSizes.tablet {

        .response-container {
            h1 {
                font-size: 7rem;
                line-height: 7rem;
            }

            .response-info {
                h3 {
                    @include fonts.h5;
                }

                p {
                    @include fonts.body3;
                }
            }
        }
    }

    @include screenSizes.mobile {
        .response-container {
            h1 {
                font-size: 4rem;
                line-height: 4rem;
            }

            .response-info {
                h3 {
                    @include fonts.h6;
                }

                p {
                    @include fonts.body3;
                    font-size: 0.75rem;
                }

                .button-group {
                    button {
                        font-size: 0.75rem;
                        width: 10rem;
                    }
                }
            }
        }
    }
}