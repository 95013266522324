@use '../../../scss/fonts' as fonts;
@use '../../../scss/colors' as colors;
@use '../../../scss/screenSizes' as screenSizes;

.add-inventory-modal {
  display: flex;
  flex-direction: column;
  background-color: colors.$surface-100;
  padding: 1.5rem;

  .radio-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h4 {
    @include fonts.h4;
    color: colors.$text-600;
  }

  p {
    @include fonts.body2;
    color: colors.$text-600;
  }

  h5 {
    @include fonts.body2-semibold;
    color: colors.$text-600;
    margin-top: 1rem;
  }

  .sell-code-input {
    margin-top: 0.75rem;
    width: 40%;
  }

  .footer {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 1rem;
    }
  }

  hr {
    margin-top: 0;
    width: 100%;
  }

  .sell-code-found {
    .sell-code-found-text {
      @include fonts.body3;
      color: colors.$primary-green-500;
    }

    h3 {
      @include fonts.body1;
      color: colors.$text-600;
      margin-top: 1.5rem;
    }

    p {
      @include fonts.body2;
      color: colors.$text-300;
    }
  }

  .sell-code-not-found {
    display: flex;
    flex-direction: row;

    .sell-code-not-found-text {
      margin-left: 0.5rem;
      @include fonts.body3;
      color: colors.$secondary-red-300;
    }
  }

  @include screenSizes.mobile {
    padding: 0;

    .radio-row {
      padding: 0 1.5rem;
    }

    h4 {
      @include fonts.h4;
      text-align: center;
      margin: 1.5rem;
    }

    p {
      @include fonts.body2;
      padding: 1.5rem 1.5rem 0;
    }

    form {
      padding: 0 1.5rem;

      p {
        @include fonts.caption1;
        font-size: 0.75rem;
        padding: 0;
      }

      .footer {
        display: flex;
        justify-content: center;
        margin: 3rem 0 2rem;
        gap: 1rem;

        button {
          padding: 0.6rem 0rem;
          width: 9rem;
        }
      }
    }

    h5 {
      @include fonts.button2;
    }

    .sell-code-input {
      margin-top: 0.75rem;
      width: 40%;
    }

    hr {
      margin-top: 0;
      border-color: colors.$text-200;
    }

    label {
      @include fonts.caption1;
      font-size: 0.75rem;
    }
  }
}
