@use "../../../scss/" as *;

.add-user-to-account-modal {
    display: flex;
    flex-direction: column;

    .add-user-to-account-modal-btn {
        width: 100%;
        height: 3em;
        border: none;
        background-color: $primary-blue-500;
        border-radius: 6px;
    }
}