@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.add-location-modal,
.add-account-ownership-modal {
    display: flex;
    flex-direction: column;

    .header {
        background-color: colors.$surface-100;
        border-bottom: 1px solid colors.$text-200;
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        padding: 1rem;

        h4 {
            @include fonts.h4;
        }
    }

    .body {
        @include fonts.body1;
        background-color: colors.$surface-100;
        padding: 3rem 6rem;

        .filter-dropdown {
            .options {
                max-height: 9rem;
            }
        }

        .account-details {
            margin-top: 2rem;

            p {
                @include fonts.body2;
                color: colors.$text-300;
                padding: 0.25rem 0;
            }
        }
    }

    .footer {
        background-color: colors.$surface-500;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        display: flex;
        gap: 1.25rem;
        justify-content: flex-end;
        padding: 1rem;

        .button {
            &.red {
                background-color: colors.$secondary-red-300;
            }
        }
    }

    @include screenSizes.tablet {
        .header {
            h4 {
                @include fonts.h5;
            }
        }

        .body {
            @include fonts.body2;
            padding: 2rem 4rem;
        }
    }

    @include screenSizes.mobile {
        .header {
            padding: 1.5rem;
            text-align: center;

            h4 {
                @include fonts.h5;
            }
        }

        .body {
            @include fonts.body2;
            padding: 2rem;

            .filter-dropdown {
                .options {
                    max-height: 7.25rem;
                }
            }

            .account-details {
                p {
                    @include fonts.caption1;
                }
            }
        }

        .footer {
            gap: 0rem;
            justify-content: space-evenly;
            padding: 1rem;
        }
    }
}