$font-family: "Rubik";

@mixin display1 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 3.75rem; //60px
  line-height: 5.75rem; //92px
}

@mixin h1 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 2.5rem; //40px
  line-height: 3.75rem; //60px
}

@mixin h2 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 1.875rem; //30px
  line-height: 2.75rem; //44px
}

@mixin h3 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 1.75rem; //28px
  line-height: 2.5rem; //40px
}

@mixin h4 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem; //24px
  line-height: 2.25rem; //36px
}

@mixin h5 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 1.25rem; //20px
  line-height: 1.75rem; //28px
}

@mixin h6 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem; //16px
  line-height: 1.5rem; //24px
}

@mixin h7 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 0.875rem; //14px
  line-height: 1rem; //16px
}

@mixin subheader1 {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem; //24px
  line-height: 2.25rem; //36px
}

@mixin subheader1-medium {
  @include subheader1;
  font-weight: 500;
}

@mixin subheader2 {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem; //20px
  line-height: 1.75rem; //28px
}

@mixin subheader2-medium {
  @include subheader2;
  font-weight: 500;
  line-height: 1.5rem; //24px
}

@mixin body1 {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 1.125rem; //18px
  line-height: 1.75rem; //28px
}

@mixin body1-semibold {
  @include body1;
  font-weight: 600;
}

@mixin body1-underline {
  @include body1;
  font-weight: 400;
  line-height: 1.5rem; //24px
  text-decoration: underline;
}

@mixin body2 {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem; //16px
  line-height: 1.5rem; //24px
}

@mixin body2-semibold {
  @include body2;
  font-weight: 700;
}

@mixin body2-underline {
  @include body2;
  text-decoration: underline;
}

@mixin body3 {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem; //14px
  line-height: 1.25rem; //20px
}

@mixin body3-semibold {
  @include body3;
  font-weight: 600;
}

@mixin body3-underline {
  @include body3;
  text-decoration: underline;
}

@mixin caption1 {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem; //12px
  line-height: 1rem; //16px
}

@mixin caption1-semibold {
  @include caption1;
  font-weight: 600;
}

@mixin caption1-underline {
  @include caption1;
  text-decoration: underline;
}

@mixin caption2 {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 0.625rem; //10px
  line-height: 1rem; //16px
}

@mixin caption2-semibold {
  @include caption2;
  font-weight: 500;
  line-height: 0.75rem; //12px
}

@mixin caption2-underline {
  @include caption2;
  text-decoration: underline;
}

@mixin button1 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 1.125rem; //18px
  line-height: 1.75rem; //28px
}

@mixin button2 {
  font-family: $font-family;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem; //16px
  line-height: 1.5rem; //24px
}

@mixin button3 {
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  font-size: 0.875rem; //14px
  line-height: 1.25rem; //20px
}