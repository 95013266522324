@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;

.linked-transaction,
.associated-transaction,
.linked-storage-movements {

  .xs-linked-transaction,
  .xs-associated-transaction,
  .xs-storage-movements {
    display: none;
  }

  .no-linked-transaction,
  .no-associated-transaction,
  .no-linked-movements {
    text-align: center;
  }

  @include screenSizes.mobile {

    .desk-linked-transaction,
    .desk-associated-transaction,
    .desk-storage-movements {
      display: none;
    }

    .xs-linked-transaction,
    .xs-associated-transaction,
    .xs-storage-movements {
      display: block;

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0rem 0rem 0rem;
        }
      }

      .linked-transaction-content,
      .associated-transaction-content,
      .storage-movements-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .transaction-info {
          border-bottom: 1px solid colors.$text-200;
          display: flex;
          align-items: center;
          padding: 0.5rem;
          justify-content: space-between;

          .transaction-content {
            border-right: 1px solid colors.$text-200;
            padding: 0 0.5rem;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &:last-child {
              border-right: none;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      label {
        @include fonts.caption2;
      }
    }

    .no-linked-transaction,
    .no-associated-transaction,
    .no-linked-movements {
      @include fonts.h7;
      font-size: 0.75rem;
      text-align: center;
    }
  }
}