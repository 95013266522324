@use '../../../../scss/colors' as colors;
@use '../../../../scss/fonts' as fonts;
@use '../../../../scss/elevations' as elevations;
@use '../../../../scss/screenSizes' as screenSizes;

.storage-plan-card {
  display: flex;
  flex-direction: column;
  border-right: 1px solid colors.$black;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .storage-plan-header {
    @include fonts.h4;
    color: colors.$primary-blue-500;
    text-align: center;
  }

  .cost-amount {
    @include fonts.h5;
    color: colors.$text-600;
  }

  &:last-child {
    border-right: none;
  }

  @include screenSizes.tablet {
    .storage-plan-header {
      @include fonts.h6;
    }

    .cost-amount {
      @include fonts.h7;
    }

    p {
      @include fonts.body3;
    }
  }

  @include screenSizes.mobile {
    border-right: none;

    .storage-plan-header {
      @include fonts.h6;
    }

    .cost-amount {
      @include fonts.h7;
    }

    p {
      @include fonts.caption2;
      font-size: 0.75rem;
    }
  }
}