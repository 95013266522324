@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.edit-animal {
  margin: 2rem;

  .card {
    padding: 5rem;
  }

  .row {
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 1rem;
  }

  .divider {
    border-top: 0.0625rem solid colors.$text-600; //1px
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .animal-photos {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      .animal-main-photo {
        position: relative;
        background-color: colors.$surface-500;
        border-radius: 0.5rem;

        .no-animal-image {
          width: 25.125rem;
          height: 22.5rem;
          padding-top: 13rem;
          background-color: colors.$surface-500;
          background-image: url('../../../../public/images/rsg-horizontal-full-color.png');
          background-repeat: no-repeat;
          background-position: 50% 45%;
          text-align: center;
          border-radius: 0.5rem;
        }

        img {
          height: 22.5rem;
          width: 25.125rem;
          object-fit: contain;
          border-radius: 0.5rem;
          z-index: 1;
        }

        .upload-image-button {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          z-index: 2;
          cursor: pointer;
          background-color: white;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;

          &:hover {
            scale: 1.1;
            transition: all 0.2s ease-in-out;

            .upload-image-icon {
              width: 1.25rem;
              height: 1.25rem;
              transition: all 0.2s ease-in-out;
              filter: brightness(1.25);
            }
          }

          .upload-image-icon {
            color: colors.$primary-blue-200;
          }
        }
      }

      .animal-photos-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin: 0;
        gap: 1rem;

        img {
          width: 5rem;
          height: 5rem;
          aspect-ratio: 1;
          border-radius: 0.5rem;
          object-fit: cover;
          background-color: colors.$surface-500;
        }
      }
    }
  }

  .animal-info-form {
    margin-left: 1rem;
    flex-grow: 1;
    flex: 1;
  }

  @include screenSizes.tablet {
    margin: 0rem;

    .card {
      padding: 1.5rem;
    }

    .row {
      h3 {
        @include fonts.h6;
        font-size: 1.125rem;
      }
    }

    .divider {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .wrapper {
      .animal-photos {
        max-width: 50%;

        .animal-main-photo {
          .no-animal-image {
            width: 15.5rem;
            height: 17.5rem;
            padding-top: 9rem;
          }

          img {
            height: 18.5rem;
            width: 100%;
          }
        }

        .animal-photos-row {
          flex-wrap: wrap;
        }
      }
    }

    .animal-info-form {
      .row {
        margin-bottom: 0.5rem;
      }

      textarea {
        min-height: 4rem;
      }
    }
  }

  @include screenSizes.mobile {
    margin: 0rem;

    .card {
      padding: 1rem;
    }

    .row {
      h3 {
        @include fonts.h6;
        font-size: 1.125rem;
      }
    }

    .buttons {
      margin-top: 2rem;
      justify-content: space-evenly;
    }

    .divider {
      margin: 1.25rem 0;
    }

    .wrapper {
      margin-top: 0rem;
      flex-direction: column;
      gap: 1rem;

      .animal-photos {
        max-width: 100%;
        min-width: 28%;

        .animal-main-photo {
          max-width: 100%;
          width: 100%;

          .no-animal-image {
            width: 100%;
            height: 14.5rem;
            padding-top: 8rem;
            background-size: 5.75rem;
            background-position: 50% 50%;
          }

          img {
            width: 100%;
          }
        }

        .animal-photos-row {
          flex-wrap: wrap;
        }
      }
    }

    .animal-info-form {
      margin-left: 0rem;
      min-width: 100%;

      .row {
        flex-direction: row;

        .date-input {
          .react-datepicker-wrapper {
            &::after {
              background-size: 0.75rem;
              width: 2rem;
            }
          }

          .react-datepicker__input-container {
            button {
              right: 3rem;
              width: 0.75rem;
              height: 0.75rem;
              top: calc(50% - 0.35rem);
            }
          }
        }
      }

      textarea {
        min-height: 5rem;
      }
    }
  }
}