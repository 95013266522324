@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;

.auth {
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;

  $min-content-width: 39.25rem;
  &::before {
    content: '';
    z-index: -1;
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    width: 44vw;
    max-width: calc(100% - $min-content-width);
    height: 100vh;
    background-size: contain;
    background-image: url('../../../public/images/rsg-login.png'),
      linear-gradient(85.39deg, #ffffff 5.69%, rgba(185, 214, 170, 0.5) 95.83%);
    background-repeat: no-repeat;
    background-position: center;
  }
  .content-container {
    min-width: $min-content-width;
    max-width: 56vw;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .content {
    width: 100%;
    max-width: $min-content-width;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: colors.$white;
  }

  hr {
    width: calc(100% - 2rem);
  }

  .welcome {
    @include fonts.display1;
    line-height: 4.2rem;
    color: colors.$text-600;
  }

  h1 {
    @include fonts.h1;
    color: colors.$black;
  }

  .subheader {
    @include fonts.body2;
    color: colors.$text-300;
    margin-bottom: 1rem;
  }

  .password-subheader {
    @include fonts.body2;
    color: colors.$text-400;
  }

  .input {
    min-height: 6rem;

    label {
      display: block;
    }
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .input {
      width: calc(50% - 0.6875rem);
    }
  }

  .form {
    padding: 1rem;
    width: calc(100% - 2rem);
    max-width: 30rem; //480px
  }

  .forgot-password {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 0.25rem;
    a {
      color: colors.$primary-blue-200;
    }
  }

  .remember {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    label {
      padding-left: 0.25rem;
    }
  }

  .button {
    width: 100%;
    height: 3rem;
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    $border-width: 1px;
    $padding-left: 1rem;
    $padding-right: 0.5rem;
    width: calc(100% - $padding-left - $padding-right - 2 * $border-width);
    height: 2rem;
    padding: 0.5rem $padding-right 0.5rem $padding-left;
    border-radius: 0.5rem; //8px
    border-width: $border-width;
  }

  .bottom-text {
    @include fonts.body1;
    padding: 1rem 1.5rem;
    color: colors.$text-600;
    a {
      @include fonts.body1-semibold;
      color: colors.$text-600;
    }
  }

  .return-link {
    padding: 1rem 0;
    a {
      @include fonts.body3;
      color: colors.$primary-blue-200;
    }
  }

  .terms-and-conditions {
    @include fonts.body3;
    a {
      @include fonts.body3-semibold;
      color: colors.$text-600;
    }
  }

  @include screenSizes.mobile {
    justify-content: center;
    align-items: center;

    .welcome {
      @include fonts.h2;
    }

    .subheader {
      @include fonts.body3-semibold;
    }

    &::before {
      width: 100vw;
      max-width: 100%;
    }

    .content-container {
      min-width: 0;
      width: 100%;
      max-width: 26rem;
    }

    .content {
      margin: 1rem;
      height: fit-content;
      border-radius: 0.625rem;
    }

    .input-row {
      flex-direction: column;

      .input {
        width: 100%;
      }
    }

    .forgot-password {
      padding-top: 0.5rem;
      @include fonts.caption1-semibold;
      font-size: 0.75rem;
    }

    h1 {
      @include fonts.h2;
    }

    .password-subheader {
      @include fonts.body3;
      margin-top: 0.5rem;
    }

    .return-link {
      a {
        @include fonts.caption1-semibold;
        font-size: 0.75rem;
      }
    }

    .button {
      @include fonts.button2;
      border-radius: 0.25rem;
      color: colors.$white;
    }

    .bottom-text {
      @include fonts.caption2;
      font-size: 0.75rem;

      a {
        @include fonts.h7;
        font-size: 0.75rem;
      }
    }
  }

  @include screenSizes.tablet {
    $min-content-width: 36rem;
    &::before {
      content: '';
      z-index: inherit;
      max-width: calc(100% - $min-content-width);
    }
    .content-container {
      min-width: $min-content-width;
    }

    .content {
      max-width: $min-content-width;
    }

    hr {
      width: calc(100% - 6rem);
    }

    .welcome {
      @include fonts.h1;
    }

    .subheader {
      @include fonts.caption1;
      margin-top: 0.5rem;
    }

    .input {
      label {
        @include fonts.caption1;
      }
    }

    .forgot-password {
      padding-top: 0.3rem;
      @include fonts.caption1-semibold;
    }

    .bottom-text {
      @include fonts.caption1;

      a {
        @include fonts.h7;
      }
    }

    .terms-and-conditions {
      @include fonts.body3;

      a {
        @include fonts.body3-semibold;
        color: colors.$text-600;
      }
    }

    .password-subheader {
      @include fonts.caption1;
      margin-top: 0.5rem;
    }

    .return-link {
      a {
        @include fonts.caption1-semibold;
      }
    }
  }
}
