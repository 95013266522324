@use '../../scss/colors' as colors;
.profile-image {
  &.loading {
    animation: gradient 2s linear infinite;
    background: linear-gradient(225deg, colors.$primary-green-500, colors.$icon-200);
    background-size: 200% 200%;
    opacity: 0.75;
  }
}

@keyframes gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
