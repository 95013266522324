@use '../../../../scss/colors' as colors;
@use '../../../../scss/fonts' as fonts;
@use '../../../../scss/screenSizes' as screenSizes;

.confirm-address-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .modal-header {
    margin: 1rem;
  }

  hr {
    color: colors.$surface-500;
  }

  .modal-body {
    text-align: center;
    padding: 3rem;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    background-color: colors.$surface-500;
    height: 5rem; // 80 px

    button {
      margin: 1rem;
    }
  }

  @include screenSizes.tablet {
    .modal-header {
      @include fonts.h5;
    }

    .modal-body {
      @include fonts.body3;
    }
  }

  @include screenSizes.mobile {
    .modal-header {
      margin: 1.5rem;
      text-align: center;
      @include fonts.h5;
    }

    .modal-body {
      padding: 2rem;
      @include fonts.body2;
    }

    .modal-footer {
      justify-content: space-evenly;
      background-color: colors.$surface-500;
      height: 5rem; // 80 px

      button {
        margin: 1rem;
      }
    }
  }
}