@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;

.inventory {
  padding: 2rem;

  .inventory-overview {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid colors.$surface-500;
    border-bottom: 1px solid colors.$surface-500;
    padding: 0.5rem 0;

    h1 {
      @include fonts.h5;
      border-right: 1px solid colors.$surface-500;
      padding-right: 1.5rem;
    }

    div {
      display: flex;
      align-items: center;
    }

    p {
      display: flex;
      align-items: center;
      color: colors.$text-300;

      span {
        color: colors.$black;
        @include fonts.subheader2-medium;
        padding: 0 0.5rem 0 1rem;
      }
    }

    button {
      gap: 0.5rem;
    }

    .button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.2rem;

      p {
        @include fonts.body2;
        color: colors.$black;
        padding-right: 0.5rem;
      }

      &.disabled,
      &[disabled] {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }

  .inventory-search {
    margin: 1rem 0;
    max-width: 20rem;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 0.875rem;
    border: 1px solid colors.$surface-500;

    .filter-dropdown {
      border-radius: 0;
      background: transparent;
      padding: 0.1rem 1rem;

      .options {
        top: 1.5rem;
      }
    }

    label {
      @include fonts.body3;
      color: colors.$text-600;
    }

    .filter-option {
      width: 100%;
    }
  }

  .gallery {
    .gallery-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        @include fonts.body2-semibold;
        margin: 0.5rem 0;
      }

      p {
        @include fonts.body2-semibold;
        color: colors.$text-400;
      }
    }

    .result-item {
      text-align: center;
      padding: 2rem 0;
    }

    .items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1.875rem; //30px
    }

    &>button {
      margin: 2rem auto;
    }
  }

  .mobile-view {
    display: none;
  }

  @include screenSizes.tablet {
    .inventory-overview {
      h1 {
        @include fonts.h7;
        padding-right: 1rem;
      }

      p {
        @include fonts.caption1;
        font-size: 0.75rem;

        span {
          @include fonts.h7;
        }
      }

      .button {
        p {
          @include fonts.caption1;
        }
      }
    }

    .filters {
      label {
        @include fonts.caption1;
        font-size: 0.75rem;
      }
    }

    .gallery {
      .items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(10.5rem, 1fr));
        grid-gap: 1.875rem;

        .animal-card {
          width: inherit;

          .animal-image-placeholder {
            @include fonts.caption1-semibold;
            font-size: 0.75rem;
          }

          .animal-info .button {
            width: 9rem;
          }
        }
      }
    }
  }

  @include screenSizes.mobile {
    padding: 1.4rem;

    .inventory-overview {
      h1 {
        @include fonts.h7;
        padding-right: 0.5rem;
      }

      p {
        @include fonts.caption1;
        font-size: 0.75rem;

        span {
          @include fonts.h7;
          padding-left: 1rem;
        }
      }

      .button {
        p {
          @include fonts.caption1;
          font-size: 0.75rem;
        }
      }
    }

    .inventory-search {
      display: flex;
      align-items: center;
      max-width: 100%;
      gap: 1.8rem;

      svg {
        width: 3rem;
        height: 2rem;
        cursor: pointer;
      }

      .search-input-wrapper {
        display: flex;

        input {
          width: 100%;
          padding-right: 3rem;
        }
      }
    }

    .desktop-view {
      display: none;
    }

    .mobile-view {
      display: contents;
    }

    .gallery {
      .gallery-heading {

        h2,
        p {
          @include fonts.button2;
        }
      }

      .items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9.2rem, 1fr));
        grid-gap: 1.3rem;

        .animal-card {
          width: inherit;

          .animal-image-placeholder {
            @include fonts.caption1-semibold;
            font-size: 0.75rem;
          }

          .animal-info .button {
            width: 7.5rem;
          }
        }
      }
    }
  }
}

.inventory-item {

  .button {

    &.disabled,
    &[disabled] {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .inventory-items {
    padding: 1rem;

    h1 {
      @include fonts.h4;
      color: colors.$text-600;
    }

    .mobile-view {
      display: none;
    }

    .desktop-view {
      display: block;
    }
  }

  @include screenSizes.tablet {
    width: 100%;
    display: flex;
    flex-direction: column;

    .quantity {
      h3 {
        @include fonts.caption1-semibold;
      }

      span {
        @include fonts.body3;
      }
    }

    .action-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .button {
        @include fonts.h7;
        font-size: 0.75rem;
        padding: 0.5rem;
        width: 45%;
        min-width: 9rem;

        &.large {
          min-width: 11rem;
        }
      }
    }

    .inventory-items {
      h1 {
        @include fonts.h7;
        padding-bottom: 1rem;
      }
    }
  }

  @include screenSizes.mobile {
    width: 100%;
    display: flex;
    flex-direction: column;

    .quantity {
      h3 {
        @include fonts.caption1-semibold;
      }

      span {
        @include fonts.caption2;
        font-size: 0.75rem;
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: column;

      .button {
        width: 100%;
        padding: 0.7rem;
      }
    }

    .inventory-items {
      .desktop-view {
        display: none;
      }

      .mobile-view {
        display: block;
      }

      h1 {
        @include fonts.button3;
        padding-bottom: 1rem;
      }
    }
  }
}

.inventory-sell .inventory-action {
  .sell-submit-section {
    flex-direction: column;
    align-items: flex-end;

    .sell-submit-guide {
      @include fonts.body2;
      margin: 1rem 0rem;
    }
  }

  @include screenSizes.tablet {
    .sell-submit-guide {
      @include fonts.body3;
    }
  }

  @include screenSizes.mobile {
    .sell-submit-guide {
      @include fonts.caption2;
    }
  }
}

.inventory-transfer,
.inventory-use {
  h2 {
    margin: 0.5rem 0 0 0;
  }

  hr {
    margin-top: 0;
  }

  .form-row {
    max-width: 48rem;

    .input-container,
    .validated-input,
    .radio-row,
    .date-input {
      max-width: 20.5rem;
    }

    &.expanded-text-area {
      max-width: 62rem;

      label {
        max-width: 45%
      }

      textarea,
      pre {
        width: calc(100% - 30rem);
        min-width: calc(18.5rem - 2px);
      }
    }
  }

  .inventory-action .use-inventory-title {
    margin-bottom: 1.5rem;
  }

  .use-inventory-guide {
    @include fonts.body2;
  }

  .use-submit-section {
    flex-direction: column;
    align-items: flex-end;

    .use-submit-guide {
      margin: 1rem 0rem;
    }
  }

  @include screenSizes.tablet {

    .use-inventory-guide,
    .use-submit-guide {
      @include fonts.body3;
    }

    .form-row {
      max-width: 37rem;

      .input-container,
      .validated-input,
      .radio-row,
      .date-input {
        max-width: 18.5rem;
      }

      input[type=radio] {
        width: 1.25rem;
        height: 1.25rem;
      }

      &.expanded-text-area {
        max-width: 42rem;

        label {
          max-width: 45%
        }

        textarea,
        pre {
          width: calc(100% - 20rem);
          min-width: calc(16.5rem - 2px);
        }
      }
    }
  }

  @include screenSizes.mobile {

    .use-inventory-guide,
    .use-submit-guide {
      @include fonts.caption2;
    }

    .form-row {
      max-width: 30rem;

      .input-container,
      .validated-input,
      .radio-row,
      .date-input {
        max-width: 12.5rem;
      }

      input[type=radio] {
        width: 1rem;
        height: 1rem;
      }

      &.expanded-text-area {
        max-width: 30rem;

        label {
          max-width: 45%
        }

        textarea,
        pre {
          width: calc(100% - 20rem);
          min-width: calc(10.5rem - 2px);
        }
      }
    }
  }
}

.inventory-withdraw .inventory-action {
  .form-row {
    align-items: center;
  }

  .withdraw-inventory-title {
    margin-bottom: 1.5rem;
  }

  .withdraw-inventory-guide {
    @include fonts.body2;
  }

  .withdraw-submit-section {
    flex-direction: column;
    align-items: flex-end;

    .withdraw-submit-guide {
      margin: 1rem 0rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.3rem;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 0.75rem;
    }
  }

  .radio-row-label {
    max-width: 45%;
  }

  @include screenSizes.tablet {

    .input-container .filter-dropdown,
    .date-input {

      p,
      input {
        @include fonts.body3;
        font-size: 0.75rem;
      }
    }

    p {
      @include fonts.body2;
    }

    .withdraw-inventory-guide,
    .withdraw-submit-guide {
      @include fonts.body3;
    }

    input[type=radio] {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  @include screenSizes.mobile {

    .input-container .filter-dropdown,
    .date-input {

      p,
      input {
        @include fonts.caption2;
      }
    }

    p {
      @include fonts.caption1;
      font-size: 0.75rem;
    }

    .withdraw-submit-section {
      .buttons {
        align-self: center;
      }
    }

    .withdraw-inventory-guide,
    .withdraw-submit-guide {
      @include fonts.caption2;
    }

    .radio-row-label {
      max-width: 100%;
    }

    input[type=radio] {
      width: 1rem;
      height: 1rem;
    }
  }
}

.inventory-discard {
  .inventory-action {
    p {
      padding-bottom: 0.3rem;
    }

    .discard-submit-guide {
      @include fonts.body2;
      margin: 1rem 0rem;
    }

    @include screenSizes.tablet {
      form {
        p {
          @include fonts.body3;
        }
      }

      .discard-submit-guide {
        @include fonts.body3;
      }
    }

    @include screenSizes.mobile {
      form {
        p {
          @include fonts.caption2;
          padding: 0;
        }
      }

      .discard-submit-guide {
        @include fonts.caption2;
      }
    }
  }
}