@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.custom-data-points-inventory {
  .desk-custom-data-point-table {
    .new-data-point-button {
      color: colors.$surface-100;
      background-color: colors.$primary-blue-500;
      width: 3.5rem;
      height: 1.5rem;
      font-weight: bold;
      font-size: 1.25rem;
      border-radius: 0.15rem;
      cursor: pointer;

      &:hover {
        background-color: colors.$primary-blue-400;
      }

      &:active {
        background-color: colors.$primary-blue-300;
      }
    }

  }

  .xs-custom-data-point-table {
    display: none;
  }

  @include screenSizes.mobile {
    .desk-custom-data-point-table {
      display: none;
    }

    .xs-custom-data-point-table {
      display: block;

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0.5rem 0rem 0rem;
        }

        .custom-data-point {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .custom-data-point-content {
            flex: 3;
            border-right: 1px solid colors.$text-200;

            .card-content-section {
              display: flex;
              border-bottom: 1px solid colors.$text-200;
              padding: 0.5rem;

              .left {
                border-right: 1px solid colors.$text-200;
                padding-right: 0.5rem;
                flex: 1;
              }

              .right {
                padding-left: 0.5rem;
                flex: 1;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }

          .action-button {
            flex: 1;
            text-align: center;
          }

          label {
            @include fonts.caption1;
            font-size: 0.625rem;
          }
        }
      }

      .add-data-point {
        padding-top: 1rem;
        display: flex;
        align-items: center;
        @include fonts.caption1;
        font-size: 0.625rem;
      }
    }
  }
}