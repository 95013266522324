@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.transaction-management {
  .create-transaction {
    height: 3.25rem;
  }

  .xs-transaction-management {
    display: none;
  }

  .account-warning {
    color: colors.$secondary-red-300;
  }

  @include screenSizes.tablet {
    .create-transaction {
      height: 2.5rem;
    }

    .account-warning {
      font-size: 0.625rem;
    }

    .sorted-table {
      thead {
        tr th {
          @include fonts.caption2-semibold;
          padding: 1rem 0.25rem;

          svg {
            width: 0.7rem;
            height: 0.7rem;
          }
        }
      }

      tbody {
        tr td {
          padding: 1rem 0.25rem;
          @include fonts.caption2;
        }
      }
    }
  }

  @include screenSizes.mobile {
    .desk-transaction-management {
      display: none;
    }

    .xs-transaction-management {
      display: block;

      .result-item {
        text-align: center;
        @include fonts.caption1;
      }

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0rem 0rem 0rem;
        }
      }

      .transaction-management-card {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .transaction-management-content {
          width: 85%;
          border-right: 1px solid colors.$text-200;

          .transaction-info {
            border-bottom: 1px solid colors.$text-200;
            display: flex;
            align-items: center;
            padding: 0.5rem;
            justify-content: space-between;

            .transaction-content {
              border-right: 1px solid colors.$text-200;
              padding: 0 0.5rem;
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;

              &:last-child {
                border-right: none;
              }
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }

        .action-button {
          width: 15%;
          text-align: center;

          button {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        label {
          @include fonts.caption2;
        }
      }
    }
  }
}

.inventory-withdraw,
.inventory-discard {
  .margin-top {
    margin-top: 1rem;
  }

  textarea {
    width: 20vw;
    height: 15vh;
  }

  .select-animal {
    margin: 1rem 0 1.5rem 0;
  }

  .withdraw-buttons {
    gap: 1.3rem;
    margin-top: 2rem;
  }

  .xs-item-storage-locations {
    display: none;
  }

  .desk-item-storage-locations {
    .specimen-table {
      .total-selected-quantity {
        display: inline-block;
        position: relative;
        width: fit-content;

        &::before {
          content: 'Total Quantity Selected:';
          font-weight: bolder;
          position: absolute;
          right: 100%;
          padding-right: 1rem;
          white-space: nowrap;
        }
      }
    }
  }

  @include screenSizes.tablet {
    h4 {
      @include fonts.h6;
    }

    .form-row {
      align-items: flex-start;

      label {
        @include fonts.caption1;
        line-height: 1.5rem;
      }

      .input-container {
        @include fonts.caption2;
        font-size: 0.75rem;
      }
    }
  }

  @include screenSizes.mobile {
    h4 {
      @include fonts.h7;
    }

    .form-row {
      align-items: flex-start;

      .input-container {
        max-width: none;
        width: auto;
        @include fonts.caption2;
        font-size: 0.75rem;
      }

      .filter-dropdown-mobile {
        max-width: 10.5rem;
        width: 100%;

        .filter-dropdown {
          font-size: 0.75rem;
          padding: 0.75rem 1rem;
        }
      }

      textarea {
        width: 35vw;
        @include fonts.caption2;
        font-size: 0.75rem;
      }
    }

    .desk-item-storage-locations {
      display: none;
    }

    .xs-item-storage-locations {
      display: block;
    }

    .select-animal {
      .specimen-table table {
        thead tr th {
          @include fonts.caption1-semibold;
          font-size: 0.75rem;
        }

        tbody tr td {
          @include fonts.caption2;
        }
      }
    }

    .xs-item-storage-locations {
      display: block;

      .warning {
        background-color: colors.$warning-100;
      }

      .error {
        background-color: colors.$alert-100;
      }

      .invalid-row {
        background-color: rgba(colors.$secondary-red-200, 0.33);
      }

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0rem 0rem 0rem;
        }

        .item-storage-locations-row {
          display: flex;
          width: 100%;

          .input-check {
            align-self: center;
            padding: 0.25rem;

            input {
              min-width: 1rem;
            }
          }

          .item-storage-locations-content {
            display: flex;
            flex-direction: column;
            border-left: 1px solid colors.$text-200;
            border-right: 1px solid colors.$text-200;
            padding: 0.5rem;
            align-items: flex-start;
            justify-content: center;
            gap: 0.5rem;
            width: 65%;
          }

          .number-input-section {
            display: flex;
            flex-direction: column;
            width: 25%;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0.25rem;
            gap: 0.5rem;

            input {
              max-width: 50%;
              text-align: center;
              padding: 0.25rem;
              border-radius: 0rem;
              @include fonts.caption2;
              font-size: 0.75rem;
            }
          }

          label {
            @include fonts.caption1;
            font-size: 0.75rem;
          }
        }
      }

      .disabled {
        label {
          color: colors.$text-200;
        }
      }

      .total-quantity {
        display: flex;
        justify-content: flex-end;
        margin: 1rem;
        @include fonts.body3;

        label {
          @include fonts.body3;
          font-weight: 600;
        }
      }
    }
  }
}

.transaction-adjust {
  .transaction-tooltip {
    margin-left: 1rem;

    .tooltip-text {
      padding: 1rem;
      width: 20rem;
    }
  }

  textarea {
    width: 20vw;
    height: 15vh;
  }

  .radios {
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .form-row {
    padding-bottom: 1rem;
  }

  .submit {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }

  .submit-button {
    margin-top: 2rem;
  }

  .xs-inventory-transactions {
    display: none;
  }

  .select-animal {
    margin: 1.3rem 0;

    .specimen-table table {

      thead tr th,
      tbody tr td {
        word-break: break-all;
      }
    }

    .filter-dropdown {
      margin: auto;
      padding: 0.4rem;
      border-radius: 0.2rem;
      width: 40%;

      .options {
        top: 1.8rem;

        .option {
          padding: 0.3rem;
        }
      }
    }
  }

  @include screenSizes.tablet {
    .transaction-tooltip {
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }

      .tooltip-text {
        width: 30vw;
        left: 2rem;
      }
    }

    .form-row {
      align-items: flex-start;

      textarea {
        min-height: 5rem;
      }

      .radios {
        input[type='radio'] {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }

    .submit-button {
      margin-top: 2.5rem;
    }

    .select-animal {
      .specimen-table table input {
        @include fonts.caption2;
      }

      .filter-dropdown {
        width: 70%;

        .form-field {
          gap: 0.4rem;

          p {
            @include fonts.caption2;
            font-size: 0.75rem;
            word-break: break-all;
          }
        }

        .options .option {
          @include fonts.caption2;
          font-size: 0.75rem;
        }
      }
    }
  }

  @include screenSizes.mobile {
    .transaction-tooltip {
      top: 0.25rem;

      svg {
        width: 1rem;
        height: 1rem;
      }

      .tooltip-text {
        width: auto;
      }
    }

    .form-row {
      align-items: flex-start;

      .input-container {
        max-width: none;
        width: auto;
        @include fonts.caption2;
        font-size: 0.75rem;
      }

      textarea {
        width: 35vw;
        @include fonts.caption2;
        font-size: 0.75rem;
        min-height: 5rem;
      }

      .radios {
        input[type='radio'] {
          width: 1rem;
          height: 1rem;
        }
      }

      .validated-input {
        max-width: 20vw;
      }
    }

    .submit-button {
      margin-top: 2.5rem;
    }

    .desk-inventory-transactions {
      display: none;
    }

    .specimens-title {
      @include fonts.h7;
    }

    .xs-inventory-transactions {
      display: block;

      .card {
        padding: 0rem;

        &.max-width {
          margin: 1rem 0rem 0rem 0rem;
        }

        .inventory-transactions-row {
          display: flex;
          width: 100%;
          padding: 0.5rem 0.3rem;

          .inventory-transactions-content {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            align-items: flex-start;
            justify-content: center;
            gap: 0.5rem;
            width: 35%;
          }

          .input-increase-decrease {
            display: flex;
            flex-direction: column;
            width: 35%;
            align-items: center;
            justify-content: center;
            border-left: 1px solid colors.$text-200;
            border-right: 1px solid colors.$text-200;

            .filter-dropdown {
              width: 70%;

              .form-field {
                gap: 0.4rem;

                p {
                  @include fonts.caption1;
                  font-size: 0.75rem;
                  word-break: break-all;
                }
              }

              .options .option {
                @include fonts.caption1;
                font-size: 0.75rem;
              }
            }
          }

          .number-input-section {
            display: flex;
            flex-direction: column;
            width: 30%;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0.25rem;
            gap: 0.5rem;

            input {
              max-width: 50%;
              text-align: center;
              padding: 0.25rem;
              border-radius: 0rem;
              @include fonts.caption2;
              font-size: 0.75rem;
            }
          }

          label {
            @include fonts.caption1;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.create-client-transaction {
  .flex-right {
    gap: 1.3rem;
    margin-top: 1.5rem;
  }
}