@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.admin-settings-page {
  .admin-settings.card {
    margin: 2.5rem 2rem;
    min-height: 80vh;

    .admin-settings__header {
      padding-top: 1rem;
      padding-left: 2rem;
      border-bottom: 1px solid colors.$text-600;
      background-color: colors.$surface-100;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;

      h2 {
        margin-bottom: 1rem;
      }
    }

    .admin-settings__body {
      padding: 1.5rem;

      .tab-header {
        @include fonts.subheader2-medium;
        font-size: 1.125rem;
        padding: 1.5rem 0rem;
      }

      .tab-guide {
        @include fonts.body2;
      }

      .divider {
        border: none;
        border-bottom: 1px solid colors.$surface-500;
        margin: 0.75rem 0rem;
      }

      .input {
        padding: 1.5rem 0rem;
        width: calc(100% - 2rem);
        max-width: 30rem; //480px
      }

      .user-details-section {
        label {
          font-weight: 400;
        }

        .user-details-header {
          margin: 0.5rem 0;
        }

        .user-details-body {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          .reset-password-link {
            word-break: break-word;
          }
        }
      }

      .billing-account-info {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        .divider {
          border-top: 1px #BFBFBF;
          margin-top: 0.05rem;
        }

        hr {
          border-top: 1px solid colors.$text-300;
        }
      }
    }

    .admin-settings__tabs {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 1rem;
      position: relative;
    }

    .admin-settings__tabs li {
      cursor: pointer;
      margin-right: 2rem;
      padding: 0.5rem;
      position: relative;
      line-height: 28px;

      &.active {
        font-weight: 500;
        line-height: 24px;
      }
    }

    .admin-settings__tabs li.active::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 0.25rem;
      background-color: colors.$primary-green-500;
    }
  }

  @include screenSizes.tablet {
    .admin-settings.card {
      margin: 2.5rem 1.5rem;
      min-height: 70vh;

      .admin-settings__header {
        padding-left: 1.5rem;

        h2 {
          @include fonts.h5;
          margin-bottom: 0.5rem;
        }
      }

      .admin-settings__tabs li {
        @include fonts.body3;
      }

      .admin-settings__body {
        .card {
          @include fonts.body3;
        }

        .divider {
          margin: 0.5rem 0rem;
        }
      }

      .admin-settings__body .input {
        max-width: 22rem;
      }
    }
  }

  @include screenSizes.mobile {  
    .admin-settings.card {
      margin: 2rem 1rem;

      .admin-settings__header {
        padding-left: 1rem;

        h2 {
          @include fonts.h6;
          margin-bottom: 0.5rem;
        }
      }

      .admin-settings__body {
        .tab-header {
          @include fonts.h6;
        }

        .input {
          width: 100%;
        }

        .submit-button {
          display: flex;
          justify-content: center;
        }

        .reset-password-link {
          @include fonts.caption1;
        }

        .billing-container {
          display: flex;
          flex-direction: column;
          @include fonts.caption1;
        }

        .input-container {
          padding-left: 0;
        }
      }

      .admin-settings__tabs {
        padding-top: 0.5rem;
      }

      .admin-settings__tabs li {
        margin-right: 1rem;
        @include fonts.body3;
      }
    }
  }
}