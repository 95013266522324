@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.specimen-ownership-breakdown {
  .label-percent {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      color: colors.$text-200;
    }
  }

  .account-due-payment label {
    font-size: 1rem;
    color: colors.$secondary-red-300;
  }

  .no-owners-error {
    display: flex;
    padding: 2rem;
    gap: 0.25rem;

    p {
      color: colors.$secondary-red-300;
    }
  }

  @include screenSizes.mobile {
    .account-due-payment label {
      font-size: 0.75rem;
    }
  }
}
