@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.account-info {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .image {
      padding-right: 1rem;

      .profile-image {
        border-radius: 50%;
        width: 4.375rem;
        height: 4.375rem;
      }
    }

    .name-stack {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color: colors.$text-300;
      padding-right: 1rem;

      .name {
        @include fonts.body2-semibold;
      }
    }

    .account-stack {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      margin-left: auto;
      align-items: flex-end;

      h3 {
        word-break: break-word;
        text-align: end;
      }

      .account-id {
        color: colors.$text-300;
      }
    }
  }

  .body {
    margin-top: 1.25rem;

    hr {
      border-top: 1px solid colors.$text-300;
    }

    .labeled-value-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        color: colors.$text-300;
        margin-right: 0.5rem;
      }

      span {
        word-break: break-word;
      }
    }
  }

  @include screenSizes.mobile {
    .header {
      .name-stack {
        .name {
          @include fonts.caption2-semibold;
        }

        .role {
          @include fonts.caption2;
        }
      }

      .account-stack {
        .farm-name {
          @include fonts.caption2;
          font-weight: 600;
        }

        .account-id {
          @include fonts.caption2;
        }
      }
    }

    .body {
      label,
      span {
        @include fonts.caption2;
      }
    }
  }

  @include screenSizes.tablet {
    .header {
      .image {
        .profile-image {
          width: 3.375rem;
          height: 3.375rem;
        }
      }

      .name-stack {
        padding-right: 1rem;
        .name {
          @include fonts.h7;
        }

        .role {
          @include fonts.caption2;
        }
      }

      .account-stack {
        .farm-name h3 {
          @include fonts.body3-semibold;
        }

        .account-id {
          @include fonts.caption2;
        }
      }
    }

    .body {
      label,
      span {
        @include fonts.caption2;
      }
    }
  }
}
