@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.user-profile-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.card {
    display: flex;
    padding: 0 0 2rem 0;
  }

  .header {
    width: 100%;
    border-radius: 0.3125rem 0.3125rem 0 0;
    display: flex;
    position: relative;
    height: 10.25rem;
    margin-bottom: 4.6875rem;
    background-color: colors.$surface-500;

    .user-profile-image-container {
      display: flex;
      position: relative;
      margin: auto;
      top: 4.6875rem;

      .user-profile-image {
        width: 9.375rem;
        height: 9.375rem;
        border-radius: 50%;
        cursor: pointer;
        background-size: 200% 200%;
      }

      .user-profile-image__edit-btn {
        display: flex;
        position: absolute;
        z-index: 2;
        bottom: 0.5rem;
        right: 0.5rem;
        width: 2.1875rem;
        height: 2.1875rem;
        background-color: colors.$primary-green-500;
        color: colors.$surface-100;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .reset-password {
    @include fonts.body2-underline;
    color: colors.$primary-blue-500;
    cursor: pointer;
  }

  .user-profile-info {
    display: flex;
    flex-direction: column;
    width: 36.6875rem;
    margin: 0 auto;

    .add-address-button {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      cursor: pointer;
      @include fonts.body2;
      color: colors.$text-600;
      margin-top: 1.5rem;

      &.disabled {
        color: colors.$text-200;
        pointer-events: none;
      }
    }

    .button-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2.5rem;
    }

    .input {
      padding-bottom: 1rem;
    }

    .input-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1rem;

      .input {
        width: calc(50% - 0.6875rem);
      }
    }

    input[type='text'],
    input[type='email'],
    input[type='password'] {
      $border-width: 1px;
      $padding-left: 1rem;
      $padding-right: 0.5rem;
      width: calc(100% - $padding-left - $padding-right - 2 * $border-width);
      height: 2rem;
      padding: 0.5rem $padding-right 0.5rem $padding-left;
      border-radius: 0.5rem; //8px
      border-width: $border-width;
    }

    .user-single-address {
      margin-top: 0.5rem;

      .card {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 1rem 0rem;

        .address-type {
          width: 10%;
          text-align: center;
          padding: 0.5rem;
        }

        .address-details {
          width: 70%;
          display: flex;
          align-items: center;
          padding: 0.5rem;
          gap: 0.7rem;
          border-left: 1px solid colors.$text-600;
          border-right: 1px solid colors.$text-600;
        }

        .edit-trash-svg {
          width: 11%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem;

          svg {
            width: 1.3rem;
            height: 1.3rem;
            cursor: pointer;
          }

          .address-delete-button {
            color: colors.$secondary-red-300;
          }
        }
      }
    }

    .user-addresses {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(9rem, 11rem));
      grid-gap: 1.3rem;

      .card {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        .address-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem;
          border-bottom: 1px solid colors.$text-400;

          h3 {
            @include fonts.body3-semibold;
          }

          .edit-trash-svg {
            display: flex;
            gap: 0.5rem;

            svg {
              width: 1.3rem;
              height: 1.3rem;
              cursor: pointer;
            }

            .address-delete-button {
              color: colors.$secondary-red-300;
            }
          }
        }

        .address-card-body {
          padding: 0.5rem;
          @include fonts.body3;
        }
      }
    }
  }

  @include screenSizes.tablet {
    .user-profile-info .user-single-address .card {
      @include fonts.body3;
    }
  }

  @include screenSizes.mobile {
    .header {
      height: 6.875rem;
      margin-bottom: 2.8125rem;

      .user-profile-image-container {
        top: 2.8125rem;

        .user-profile-image {
          width: 5.625rem;
          height: 5.625rem;
        }

        .user-profile-image__edit-btn {
          bottom: 0.2rem;
          right: 0.2rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .reset-password {
      @include fonts.caption2;
      font-size: 0.75rem;
    }

    .user-profile-info {
      width: calc(100% - 3rem - 2px);

      .add-address-button {
        @include fonts.caption2;
        font-size: 0.75rem;

        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }

      .input {
        width: 100%;
      }

      .input-row {
        flex-direction: column;
        gap: 0rem;

        .input {
          width: 100%;
        }
      }

      input[type='text'],
      input[type='email'],
      input[type='phone'],
      input[type='password'] {
        $border-width: 1px;
        $padding-left: 1rem;
        $padding-right: 0.5rem;
        width: calc(100% - $padding-left - $padding-right - 2 * $border-width);
        height: 1.5rem;
        padding: 0.5rem $padding-right 0.5rem $padding-left;
        border-radius: 0.5rem; //8px
        border-width: $border-width;
      }

      .validated-input-label {
        @include fonts.caption2;
        font-size: 0.75rem;
      }

      .user-single-address {
        .card {
          @include fonts.caption2;

          .address-type {
            width: 13%;
          }

          .address-details {
            width: 75%;
            justify-content: space-between;
            gap: 0.3rem;
            flex-wrap: wrap;
          }

          .edit-trash-svg {
            width: 10%;
            flex-wrap: wrap;
            gap: 0.6rem;

            svg {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }

      .user-addresses {
        grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
        gap: 1.5rem;

        .card {
          .address-card-header {
            padding: 0.3rem;

            h3 {
              @include fonts.caption2-semibold;
            }

            .edit-trash-svg {
              gap: 1rem;

              svg {
                width: 1rem;
                height: 1rem;
              }
            }
          }

          .address-card-body {
            padding: 0.3rem;
            @include fonts.caption2;
          }
        }
      }
    }
  }
}
