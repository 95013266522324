@use '../../scss/fonts' as fonts;
@use '../../scss/colors' as colors;
@use '../../scss/elevations' as elevations;
@use '../../scss/screenSizes' as screenSizes;

.sorted-table {
  overflow: hidden;
  border-radius: 0.5rem;
  @include elevations.elevation-1;

  table {
    width: 100%;
    border-collapse: collapse;
    border: none;
    table-layout: fixed;
    background-color: colors.$surface-300;

    thead,
    tbody tr,
    tfoot tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    thead {
      z-index: 1;
      width: calc(100% - 0.5rem);

      tr {
        background-color: colors.$surface-300;

        th {
          @include fonts.body2-semibold;
          color: colors.$text-600;
          position: sticky;
          top: 0;

          &.sortable {
            cursor: pointer;
          }

          .sort-arrow {
            padding: 0.25rem;

            svg path {
              fill: colors.$icon-200;
            }

            &.sorting {
              svg path {
                fill: colors.$icon-300;
              }
            }

            &.reverse {
              svg {
                transform: scaleY(-1);
              }
            }
          }
        }
      }
    }

    tbody {
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-gutter: stable;

      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background: colors.$surface-300;
      }

      &::-webkit-scrollbar-thumb {
        background: colors.$text-300;
        border-radius: 0.25rem;
      }
    }

    tfoot {
      tr {
        td {
          position: sticky;
          bottom: 0;
          border: none;

          &::before {
            border-left: none;
          }
        }
      }
    }

    tr {
      background-color: colors.$surface-200;

      &:nth-child(2n) {
        background-color: colors.$surface-100;
      }

      th,
      td {
        position: relative;
        padding: 1rem 0;
        text-align: center;
        overflow-wrap: break-word;

        &:first-child::before {
          display: none;
        }

        &::before {
          position: absolute;
          display: block;
          content: '';
          border-left: 1px solid colors.$text-600;
          background: black;
          top: 0.5rem;
          bottom: 0.5rem;
          left: -0.5px;
        }
      }

      thead {
        width: calc(100% - 0.5rem);
        padding: 0 0.5rem;
      }
    }
  }

  @include screenSizes.mobile {
    table {
      thead {
        tr th {
          @include fonts.caption1-semibold;
          font-size: 0.75rem;
          padding: 0.5rem;
        }
      }

      tbody {
        tr td {
          @include fonts.caption2;
          padding: 0.5rem;
        }
      }
    }
  }

  @include screenSizes.tablet {
    table {
      thead {
        tr th {
          @include fonts.caption1-semibold;
          font-size: 0.75rem;
          padding: 1rem 0.5rem;
        }
      }

      tbody {
        tr td {
          @include fonts.caption1;
          padding: 1rem 0.5rem;
          font-size: 0.75rem;

          svg {
            width: 0.75rem;
            height: 0.75rem;
          }
        }
      }
    }
  }
}