@use '../../../scss/colors' as colors;
@use '../../../scss/fonts' as fonts;
@use '../../../scss/screenSizes' as screenSizes;

.deactivate-profile {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    margin-bottom: 3rem;
    gap: 1rem;

    .deactivate-label {
        color: colors.$primary-blue-400;
        cursor: pointer;
        text-decoration: underline;
    }

    @include screenSizes.mobile {
        margin: 3rem 0rem;
        width: calc(100% - 3rem - 2px);
        @include fonts.caption2;
        font-size: 0.625rem;
        gap: 0.2rem;
        justify-content: center;
    }
}