@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.gauge-chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  $thickness: 1.25rem;

  .outer-edge {
    position: relative;
    width: calc(100% - 2.25rem);
    height: calc(100% - 2.25rem);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: conic-gradient(
      from 180deg at 50% 50%,
      rgba(145, 188, 123, 0) 0deg,
      rgba(145, 188, 123, 0.104167) 0.04deg,
      rgba(145, 188, 123, 0.258064) 0.07deg,
      #91bc7b 360deg
    );
    &::before {
      content: '';
      position: absolute;
      background-color: colors.$surface-200;
      width: 60%;
      height: 60%;
      transform: rotate(45deg);
      top: 60%;
    }

    .needle {
      &::after {
        content: '';
        position: absolute;
        border-bottom: $thickness/2 solid colors.$text-600;
        border-left: 0.15rem solid transparent;
        border-right: 0.15rem solid transparent;
        top: -$thickness;
        left: -0.15rem;
      }
      position: absolute;
      height: calc(100% - $thickness - $thickness);
      width: 0;
      top: $thickness;
      left: 50%;
    }

    .divider {
      &::after {
        content: '';
        position: absolute;
        top: -$thickness;
        left: -1px;
        width: 2px;
        height: $thickness;
        background-color: colors.$black;
      }
      position: absolute;
      height: calc(100% - $thickness - $thickness);
      width: 0;
      top: $thickness;
      left: 50%;
    }

    .inner-edge {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      width: calc(100% - $thickness);
      height: calc(100% - $thickness);
      border-radius: 50%;
      background-color: colors.$surface-200;
      z-index: 1;
      .value {
        @include fonts.body1;
        line-height: 1rem;
      }
      .available-value {
        @include fonts.caption2;
      }
    }
  }
  .gauge-label-container {
    position: absolute;
    @include fonts.caption2;
    font-size: 0.5rem;
    width: 0;
    height: 0;
    .gauge-label {
      position: absolute;
      width: 100rem;
      height: 100rem;
      left: -50rem;
      top: -50rem;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  @include screenSizes.mobile {
    .outer-edge {
      margin-top: 1rem;

      .inner-edge {
        .value {
          @include fonts.body3;
        }
        .available-value {
          @include fonts.caption1;
        }
      }
    }

    .gauge-label-container {
      @include fonts.caption2;
    }
  }

  @include screenSizes.tablet {
    .outer-edge .inner-edge {
      .value {
        @include fonts.caption1;
        font-size: 0.75rem;
      }

      .available-value {
        @include fonts.caption2;
      }
    }
  }
}
