@use '../../../../scss/colors' as colors;
@use '../../../../scss/fonts' as fonts;
@use '../../../../scss/screenSizes' as screenSizes;

.deactivate-profile-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .modal-header {
    padding: 1rem;
    padding-left: 2rem;
    border-bottom: 1px solid colors.$surface-500;
  }

  .modal-body {
    @include fonts.body1;
    padding: 1.5rem 4rem;
    background-color: colors.$surface-100;
    text-align: initial;

    ul {
      margin-block: 0.25rem;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    background-color: colors.$surface-500;
    height: 5rem; // 80 px
    padding-right: 1rem;

    button {
      margin: 1rem;
    }
  }

  @include screenSizes.mobile {
    .modal-header {
      @include fonts.h4;
      text-align: center;
      padding-left: 0rem
    }

    .modal-body {
      @include fonts.body2;
      padding: 1.5rem 3rem;
    }

    .modal-body-confirm {
      padding: 2rem;
    }

    .modal-footer {
      button {
        width: 8rem;
      }
    }

    .modal-footer-confirm {
      justify-content: center;
    }
  }
}