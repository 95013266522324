@use '../../scss/colors' as c;

.select-inventory {
  .container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem; // 12 px
  }

  .column {
    display: flex;
    align-items: center;
    max-width: 16.5625rem; // 265px
  }

  .text {
    flex-basis: 45%;
  }

  .input {
    flex-basis: 45%;
  }
}
