@use 'app.scss';
@use 'pages/auth/auth.scss';
@use 'components/tooltip/tooltip.scss';
@use 'components/animal/animalBanner.scss';
@use 'components/animal/animalCard.scss';
@use 'components/animal/animalInfo.scss';
@use 'components/nav-bar/navBar.scss';
@use 'components/title-bar/titleBar.scss';
@use 'components/custom-input/validatedInput.scss';
@use 'components/custom-input/dateInput.scss';
@use 'components/back-button/backButton.scss';
@use 'components/sorted-table/sortedTable.scss';
@use 'components/custom-input/filterDropdown.scss';
@use 'components/custom-input/animalSearch.scss';
@use 'components/specimen-tables/specimenTable.scss';
@use 'components/searchable-table/searchableTable.scss';
@use 'components/transaction/transactionTooltip.scss';
@use 'pages/dashboard/dashboard.scss';
@use 'pages/inventory/inventory.scss';
@use 'pages/inventory/add-inventory-modal/addInventoryModal.scss';
@use 'pages/animal-management/animalManagement.scss';
@use 'pages/team-management/manageTeam.scss';
@use 'pages/inventory-management/inventoryManagement.scss';
@use 'pages/inventory/filter-inventory-modal/FilterInventoryModal.scss';

body {
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
