@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.transaction-cart-page {
  .transaction-cart {
    margin: 2.5rem 2rem;
    min-height: 80vh;

    .header {
      padding-bottom: 1rem;

      .transaction-cart-header {
        @include fonts.subheader1-medium;
      }
    }

    .header-hr {
      border: 1px solid colors.$surface-400;
      border-bottom: none;
    }

    .inventory-transactions {
      margin-top: 2rem;

      .card {
        margin: 1.2rem 0;

        h2 {
          margin-bottom: 1rem;
          @include fonts.subheader2-medium;
        }
      }
    }

    @include screenSizes.tablet {
      .header .transaction-cart-header {
        @include fonts.subheader2-medium;
      }

      .inventory-transactions {
        .card h2 {
          @include fonts.body1;
          font-weight: 500;
        }
      }
    }

    @include screenSizes.mobile {
      .header .transaction-cart-header {
        @include fonts.h5;
      }

      .inventory-transactions {
        margin-top: 1rem;

        .card h2 {
          @include fonts.caption1-semibold;
        }
      }
    }
  }
}

.transaction-cart-withdraw {
  textarea {
    height: 15vh;
  }

  .withdraw-inventory-guide {
    @include fonts.body2;
  }

  .withdraw-terms-conditions {
    h3 {
      @include fonts.h5;
      line-height: 2rem;
      border-bottom: 1px solid colors.$surface-600;
      margin-top: 1rem;
    }

    ul {
      @include fonts.body2;
    }

    .terms-accept-checkbox {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
    }
  }

  .withdraw-submit-section {
    flex-direction: column;
    align-items: flex-end;

    .withdraw-submit-guide {
      margin: 1rem 0rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.3rem;
    }
  }

  .radio-row-label {
    max-width: 45%;
  }

  .trashIcon.button {
    display: inline-flex;
    width: auto;
    height: auto;
  }

  @include screenSizes.tablet {
    .trashIcon.button {
      svg {
        width: 1rem;
        height: 1.25rem;
      }
    }

    .withdraw-terms-conditions {
      h3 {
        @include fonts.h6;
        margin-top: 0rem;
      }

      ul {
        @include fonts.body3;
      }

      .terms-accept-checkbox label {
        @include fonts.body3;
      }
    }
  }

  @include screenSizes.mobile {
    .form-row {
      margin-bottom: 0rem;
      max-width: none;

      &.expanded-text-area {
        max-width: none;
      }

      .input-container {
        max-width: none;
        width: auto;
      }

      p {
        font-size: 0.75rem;
      }

      input[type='radio'] {
        width: 1rem;
        height: 1rem;
      }
    }

    p {
      font-size: 0.75rem;
    }

    .radio-row-label {
      max-width: 100%;
    }

    .trashIcon.button {
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    .withdraw-inventory-guide,
    .withdraw-submit-guide {
      @include fonts.caption2;
      font-size: 0.75rem;
    }

    .withdraw-terms-conditions {
      h3 {
        @include fonts.h7;
        margin-top: 0rem;
      }

      ul {
        @include fonts.caption2;
      }

      .terms-accept-checkbox {
        input[type=checkbox] {
          width: 0.875rem;
          height: 0.875rem;
        }

        label {
          @include fonts.caption2;
        }
      }
    }

    .xs-select-specimens {
      .result-item {
        text-align: center;
        @include fonts.caption1;
      }

      .card .selected-specimens-row .select-specimens {
        border-left: none;
      }
    }
  }
}