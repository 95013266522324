@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.account-settings-page {
  .account-settings.card {
    margin: 2.5rem 2rem;
    min-height: 80vh;

    .account-settings__header {
      padding-top: 1rem;
      padding-left: 2rem;
      border-bottom: 1px solid colors.$text-600;
      background-color: colors.$surface-100;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;

      h2 {
        margin-bottom: 1rem;
      }

      h4 {
        @include fonts.h4;
      }
    }

    .account-settings__body {
      .address-header {
        @include fonts.subheader2-medium;
        padding: 1.5rem;
      }

      .account-single-address {
        margin: 0.5rem;

        .card {
          display: flex;
          align-items: center;
          margin: 1rem 1.5rem;
          padding: 1rem 0rem;

          .address-type {
            width: 10%;
            text-align: center;
            padding: 0.5rem;
          }

          .address-details {
            width: 78%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            padding: 1rem;
            border-left: 1px solid colors.$text-600;
            border-right: 1px solid colors.$text-600;
          }

          .edit-trash-svg {
            width: 10%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            gap: 1rem;
            padding: 0.8rem;

            svg {
              width: 1.3rem;
              height: 1.3rem;
              cursor: pointer;
            }

            .address-delete-button {
              color: colors.$secondary-red-300;
            }
          }
        }
      }

      .account-addresses {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9rem, 12rem));
        grid-gap: 1.8rem;
        padding: 0 1.5rem 1.5rem 1.5rem;

        .card {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;

          .address-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem;
            border-bottom: 1px solid colors.$text-400;

            h3 {
              @include fonts.body3-semibold;
            }

            .edit-trash-svg {
              display: flex;
              gap: 0.5rem;

              svg {
                width: 1.3rem;
                height: 1.3rem;
                cursor: pointer;
              }

              .address-delete-button {
                color: colors.$secondary-red-300;
              }
            }
          }

          .address-card-body {
            padding: 0.5rem;
            @include fonts.body3;
          }
        }
      }

      .add-address {
        width: inherit;
        padding-left: 1.5rem;
        @include fonts.body2;

        svg {
          width: 1rem;
          height: 1rem;
          padding-right: 0.5rem;
        }
      }

      .address-input-container {
        input {
          margin-bottom: 1rem;
        }
      }
    }

    .account-settings__tabs {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 1rem;
      position: relative;
    }

    .account-settings__tabs li {
      cursor: pointer;
      margin-right: 2rem;
      padding: 0.5rem;
      position: relative;
      line-height: 28px;

      &.active {
        font-weight: 500;
        line-height: 24px;
      }
    }

    .account-settings__tabs li.active::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 0.25rem;
      background-color: colors.$primary-green-500;
    }
  }

  .account-settings__footer {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    justify-content: flex-end;

    button {
      margin: 1rem;
    }
  }

  @include screenSizes.tablet {
    .account-settings.card {
      margin: 2.5rem 1.5rem;
      min-height: 70vh;

      .account-settings__header {
        padding-left: 1.5rem;

        h2 {
          @include fonts.h5;
          margin-bottom: 0.5rem;
        }

        h4 {
          @include fonts.h4;
        }
      }

      .account-settings__tabs li {
        @include fonts.body3;
      }

      .account-settings__body .account-single-address .card {
        @include fonts.body3;
      }
    }
  }

  @include screenSizes.mobile {
    .account-settings.card {
      margin: 2rem 1rem;

      .account-settings__header {
        padding-left: 1rem;

        h2 {
          @include fonts.h6;
          margin-bottom: 0.5rem;
        }

        h4 {
          @include fonts.h4;
        }
      }

      .account-settings__body {
        .address-header {
          @include fonts.h6;
        }

        .account-single-address {
          .card {
            @include fonts.caption2;

            .address-type {
              width: 13%;
            }

            .address-details {
              width: 75%;
              justify-content: space-between;
              gap: 0.3rem;
              flex-wrap: wrap;
            }

            .edit-trash-svg {
              justify-content: space-between;
              gap: 0.6rem;

              svg {
                width: 1rem;
                height: 1rem;
              }
            }
          }
        }

        .account-addresses {
          grid-template-columns: repeat(auto-fit, minmax(7.4rem, 1fr));
          gap: 1.5rem;

          .card {
            .address-card-header {
              padding: 0.3rem;

              h3 {
                @include fonts.caption2-semibold;
              }

              .edit-trash-svg {
                gap: 1rem;

                svg {
                  width: 1rem;
                  height: 1rem;
                }
              }
            }

            .address-card-body {
              padding: 0.3rem;
              @include fonts.caption2;
            }
          }
        }

        .add-address {
          @include fonts.caption2;

          svg {
            width: 0.7rem;
            height: 0.7rem;
            padding-right: 0.3rem;
          }
        }

        .address-input-container {
          label {
            @include fonts.caption2;
            font-size: 0.75rem;
          }
        }
      }

      .account-settings__tabs {
        padding-top: 0.5rem;
      }

      .account-settings__tabs li {
        margin-right: 1rem;
        @include fonts.body3;
      }
    }

    .account-settings__footer {
      justify-content: space-evenly;
    }
  }
}
