@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.payment-information {
  margin: 3rem;

  .top-left-header {
    text-align: left;
    margin-bottom: 1rem;
  }

  .description {
    text-align: left;
    margin-bottom: 1.4rem;
  }

  .divider {
    width: 100%;
    border: none;
    border-top: 1px solid black;
  }

  .row {
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  .vertical-divider {
    width: 1px;
    border: none;
    background-color: gray;
  }

  .quantity-header {
    color: colors.$primary-blue-500;
    margin-bottom: 1rem;
  }

  .cost-header {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .xs-storage-plans-list {
    display: none;
  }

  @include screenSizes.tablet {
    margin: 2rem 1.5rem;

    .top-left-header {
      @include fonts.h6;
    }

    .description {
      @include fonts.caption1;
    }
  }

  @include screenSizes.mobile {
    margin: 2rem 1rem;

    .top-left-header {
      @include fonts.h6;
    }

    .description {
      @include fonts.caption1;
      font-size: 0.75rem;
      margin-bottom: 1rem;
    }

    .divider {
      border: none;
    }

    .row {
      display: none;
    }

    .xs-storage-plans-list {
      display: block;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
      gap: 1rem;

      .storage-plan-row {
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid colors.$black;

          .cell {
            width: 50%;
            text-align: center;
            border-right: 1px solid colors.$black;
            padding: 0.5rem;

            &:last-child {
              border-right: none;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}