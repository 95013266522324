@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.pie-chart {
  position: relative;
  border-radius: 50%;
  background-color: colors.$surface-500;
  color: colors.$text-100;
  .pie {
    transition: opacity 0.5s;

    .slice {
      position: absolute;
      svg {
        path:hover {
          cursor: pointer;
        }
      }
    }
  }

  .message {
    position: absolute;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include fonts.caption2;
    transition: opacity 0.5s;
  }

  .percentage {
    position: absolute;
    color: colors.$text-100;
    z-index: 20;
    .percentage-number {
      position: relative;
      @include fonts.caption2-semibold;
      left: -50%;
      top: -0.375rem; //Half line-height of font
    }
  }

  @include screenSizes.mobile {
    .pie {
      .slice {
        height: 100%;
      }
    }
  }
}
