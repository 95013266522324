@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.mark-as-paid-modal {
  display: flex;
  flex-direction: column;

  .header {
    padding: 1rem;
    border-bottom: 1px solid colors.$text-200;
    background-color: colors.$surface-100;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    h4 {
      @include fonts.h4;
    }
  }

  .body {
    @include fonts.body1;
    padding: 1.5rem 3rem;
    background-color: colors.$surface-100;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 0.5rem 0;

      .l-column {
        width: fit-content;
        flex: 1;
        text-align: left;
      }

      .r-column {
        width: 50%;
        text-align: left;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    padding: 1rem;
    background-color: colors.$surface-500;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  @include screenSizes.tablet {
    .header {
      h4 {
        @include fonts.h5;
      }
    }

    .body {
      @include fonts.body2;
    }
  }

  @include screenSizes.mobile {
    .header {
      padding: 1.5rem;
      text-align: center;

      h4 {
        @include fonts.h5;
      }
    }

    .body {
      @include fonts.body3;
      padding: 1.25rem;
    }

    .footer {
      justify-content: space-evenly;
      gap: 0rem;
      padding: 1rem;
    }
  }
}