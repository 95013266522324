@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.billing-account-info {
  .body {
    hr {
      border-top: 1px solid colors.$text-300;
    }

    .labeled-value-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.1rem;

      label {
        color: colors.$text-300;
        margin-right: 0.5rem;
      }

      span {
        word-break: break-word;
      }
    }
  }

  @include screenSizes.mobile {
    .body {
      label,
      span {
        @include fonts.caption2;
      }
    }
  }

  @include screenSizes.tablet {
    .body {
      label,
      span {
        @include fonts.caption2;
      }
    }
  }
}
