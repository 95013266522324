@use '../../scss/colors' as colors;

.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  .rsg-logo {
    background-image: url('../../../public/images/rsg-horizontal-full-color.png');
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    border: solid 1.5px colors.$surface-200;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    width: 195px;
    height: 195px;
    z-index: 9999;
    position: absolute;
  }

  svg {
    z-index: 9999;
    width: 520px;
    height: 520px;
    display: inline-block;
    position: absolute;
  }

  .spinner-background {
    width: 195px;
    height: 195px;
    border: 22px solid colors.$white;
    border-radius: 50%;
    z-index: 9998;
    position: absolute;
  }
  .spinner-background-custom-border {
    content: '';
    width: 240px;
    height: 240px;
    border-radius: 50%;
    z-index: 9999;
    position: absolute;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

.full-page-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9997;
}

.app-loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9997;
}
