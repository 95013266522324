@use '../../scss/colors' as colors;
@use '../../scss/screenSizes' as screenSizes;

.toggle-switch-container {
    display: flex;
    text-align: center;
    align-items: center;
    gap: 0.5rem;

    .toggle-switch {
        .switch {
            position: relative;
            display: inline-block;
            width: 3rem;
            height: 1.375rem;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: colors.$gray ;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 1rem;
            width: 1rem;
            left: 3px;
            bottom: 3px;
            background-color: colors.$white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked+.slider {
            background-color: colors.$primary-green-500;
        }

        input:focus+.slider {
            box-shadow: 0 0 1px colors.$primary-green-500;
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(1.625rem);
            -ms-transform: translateX(1.625rem);
            transform: translateX(1.625rem);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 2.125rem;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }

    @include screenSizes.tablet {
        label {
            font-size: 0.875rem;
        }

        .toggle-switch {
            .switch {
                width: 2.6rem;
                height: 1.25rem;
            }

            .slider:before {
                height: 0.875rem;
                width: 0.875rem;
            }

            input:checked+.slider:before {
                -webkit-transform: translateX(1.425rem);
                -ms-transform: translateX(1.425rem);
                transform: translateX(1.425rem);
            }
        }
    }

    @include screenSizes.mobile {
        .toggle-switch {
            .switch {
                width: 2.2rem;
                height: 1.1rem;
            }

            .slider:before {
                height: 0.75rem;
                width: 0.75rem;
            }

            input:checked+.slider:before {
                -webkit-transform: translateX(1rem);
                -ms-transform: translateX(1rem);
                transform: translateX(1rem);
            }
        }
    }

}