@use '../../../../scss/colors' as colors;
@use '../../../../scss/fonts' as fonts;
@use '../../../../scss/screenSizes' as screenSizes;

.reset-password-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .modal-content {
    margin: 2rem;

    .modal-header {
      @include fonts.h1;
      color: colors.$text-600;
    }

    .modal-sub-header {
      @include fonts.body2;
      color: colors.$text-300;
    }

    .modal-body {
      margin-top: 1.5rem;

      .input {
        min-height: 6rem;

        label {
          display: block;
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      height: 5rem; // 80 px

      button {
        margin: 1rem;
      }
    }
  }

  @include screenSizes.mobile {

    .modal-content {
      .modal-header {
        @include fonts.h4;
        text-align: center;
      }

      .modal-sub-header {
        @include fonts.body3;
        text-align: center;
      }

      .modal-body {
        margin-top: 1.5rem;

        .input {
          min-height: 5rem;

          label {
            @include fonts.body3;
          }
        }
      }

      .modal-footer {
        button {
          line-height: 1rem;
        }
      }
    }
  }
}