@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.enable-account-modal {
  display: flex;
  flex-direction: column;

  .header {
    padding: 1rem;
    border-bottom: 1px solid colors.$text-200;
    background-color: colors.$surface-100;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    h4 {
      @include fonts.h4;
    }
  }

  .body {
    @include fonts.body1;
    padding: 3rem 6rem;
    background-color: colors.$surface-100;
    flex-direction: row; 
    justify-content: flex-start; 
    flex-wrap: nowrap;

    .enable-billing-message {
      display: block; 
      white-space: normal; 
      word-wrap: break-word; 
      overflow-wrap: break-word;
    }

    .account-name {
      @include fonts.body1-semibold;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    padding: 1rem;
    background-color: colors.$surface-500;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  @include screenSizes.tablet {
    .header {
      h4 {
        @include fonts.h5;
      }
    }

    .body {
      @include fonts.body2;
      padding: 2rem 4rem;
    }
  }

  @include screenSizes.mobile {
    .header {
      padding: 1.5rem;
      text-align: center;

      h4 {
        @include fonts.h5;
      }
    }

    .body {
      @include fonts.body2;
      padding: 2rem;
      text-align: center;
    }

    .footer {
      justify-content: space-evenly;
      gap: 0rem;
      padding: 1rem;
    }
  }
}