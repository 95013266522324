@use '../../scss/colors' as colors;
@use '../../scss/fonts' as fonts;
@use '../../scss/screenSizes' as screenSizes;

.admin-settings-page {
    .xs-billing-management {
        display: none;
    }

        .account-info-text {
            @include fonts.body2;
        }
        
        .tab-header {
            @include fonts.subheader2-medium;
            font-size: 1.125rem;
            padding: 1.5rem 0rem;
          }

        .search-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            padding: 0.5rem 0;
            max-width: 44rem;
            margin-bottom: 2rem;
            gap: 0.5rem;

            label {
                @include fonts.body2;
              }
        }

        .input-container {
            padding-left: 0.5rem;
            width: 100%;
            max-width: 16.5rem;
        }

        .billing-account-information {
            margin-top: 1rem;
            width: 95%;
            margin-left: auto;
            margin-right: auto;
        }

        .billing-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0.75rem;

            label {
                margin-right: 4rem;
            }
        }

        .disabled-accounts-count {
            margin-top: 2rem;
            margin-bottom: 2rem;
            
            label {
                @include fonts.h6;
            }
        }

    @include screenSizes.tablet {
        .admin-settings__body {
            .billing-container {
                @include fonts.caption1;
            }
        }
    }

    @include screenSizes.mobile {
        .desk-billing-management {
            display: none;
        }

        .billing-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 1rem;

            label {
                @include fonts.caption1;
                margin: 0rem 1rem 0.5rem;
            }
        }

        .disabled-accounts-count {            
            label {
                @include fonts.body2;
            }
        }

        .xs-billing-management {
            display: block;

            .result-item {
                text-align: center;
                @include fonts.caption1;
            }

            .card {
                padding: 0rem;

                &.max-width {
                    margin: 1rem 0rem 0rem 0rem;
                }

                .billing-management-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    .billing-management-content {
                        width: 85%;
                        border-right: 1px solid colors.$text-200;
    
                        .billing-info {
                            border-bottom: 1px solid colors.$text-200;
                            display: flex;
                            align-items: center;
                            padding: 0.5rem;
                            justify-content: space-between;
    
                            .billing-content {
                                border-right: 1px solid colors.$text-200;
                                padding: 0 0.5rem;
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
    
                                &:last-child {
                                    border-right: none;
                                }
                            }
    
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
    
                    .action-button {
                        width: 15%;
                        text-align: center;
                        margin: auto;
    
                        .button {
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }
    
                    label {
                        @include fonts.caption2;
                    }
                }
            }

            
        }
    }
}